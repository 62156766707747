import { ListItem } from "@gfg/ui-v2/components/list";
import memoize from "lodash.memoize";
import React, { useCallback, useMemo, useState } from "react";

import { Tag } from "@gfg/ui-v2/components/tag";
import { makeStyles, useTheme } from "@gfg/ui-v2/theming";
import Actions from "./actions";
import useSynonymsEditor, { SynonymInEditMode } from "./use-synonyms-editor";
import useUpdateSynonymsSet from "../../hooks/use-update-synonyms-set";
import synonymsSetSchema from "./validation-schema";
import yupValidateWithoutThrow from "../../../common/utils/form/yup-validate-without-throw";
import UndoButton from "../../../common/components/undo-button";
import InProgressDialogNotification from "../../../common/components/dialogs/in-progress-dialog-notification";

const useStyles = makeStyles()(({ spacing, shadow }) => ({
  tag: {
    width: "fit-content",
    marginLeft: spacing(4),
    marginTop: spacing(10),
    border: "none",
  },
  synonymItem: {
    boxShadow: shadow("md"),
    margin: spacing(20, 1),
    borderRadius: "4px",
    padding: spacing(16, 16),
  },
}));

const getSynonymColor = (newState: SynonymInEditMode["newState"], colors: Record<string, string>) => {
  switch (newState) {
    case "added":
      return colors.success;
    case "deleted":
      return colors.error;
    default:
  }
};

const MemoizedTag = React.memo(Tag);

export interface SynonymsSetProps {
  id: string;
  synonyms: string[];
  onDelete(): void;
}

export default function SynonymsSet({ id, synonyms, onDelete }: SynonymsSetProps) {
  const { classes } = useStyles();
  const [synonymsInputValue, setSynonymsInputValue] = useState("");
  const { updateSynonymsSet, inProgress: updateInProgress } = useUpdateSynonymsSet();
  const { colors } = useTheme().theme;

  const {
    synonymsInEditMode,
    isEditInProgress,
    unsavedChanges,
    synonymsAfterChanges,
    startEditing,
    stopEditing,
    deleteSynonymFromSet,
    addSynonymsToSet,
  } = useSynonymsEditor();

  const validationErrors = useMemo(
    () => yupValidateWithoutThrow(synonymsSetSchema, { synonyms: synonymsAfterChanges }),
    [synonymsAfterChanges],
  );

  const handleEnableEditMode = () => {
    startEditing(synonyms);
  };

  const handleSave = async () => {
    if (!unsavedChanges) {
      stopEditing();
      return;
    }

    const successfulySaved = await updateSynonymsSet(id, synonymsAfterChanges);

    if (successfulySaved) {
      stopEditing();
    }
  };

  const handleAddSynonymsToSet = () => {
    addSynonymsToSet(synonymsInputValue);
    setSynonymsInputValue("");
  };

  const createDeleteHandler = useCallback(
    memoize((synonymInEditMode: SynonymInEditMode) => () => {
      deleteSynonymFromSet(synonymInEditMode);
    }),
    [deleteSynonymFromSet],
  );

  return (
    <>
      <ListItem
        className={classes.synonymItem}
        active={isEditInProgress}
        rightIcon={
          <Actions
            editMode={isEditInProgress}
            unsavedChanges={unsavedChanges}
            onAddSynonyms={handleAddSynonymsToSet}
            onDelete={onDelete}
            onEdit={handleEnableEditMode}
            onSave={handleSave}
            onSynonymsInputChange={setSynonymsInputValue}
            synonymsInput={synonymsInputValue}
            errorMessage={validationErrors[0]}
          />
        }
      >
        {!isEditInProgress
          ? synonyms.map(synonym => <Tag key={synonym} label={synonym} variant="filled" className={classes.tag} />)
          : synonymsInEditMode.map(synonymInEditMode => (
              <MemoizedTag
                key={synonymInEditMode.synonym}
                closable
                closeButton={synonymInEditMode.newState === "deleted" ? UndoButton : undefined}
                label={synonymInEditMode.synonym}
                variant="filled"
                className={classes.tag}
                color={getSynonymColor(synonymInEditMode.newState, colors)}
                onClose={createDeleteHandler(synonymInEditMode)}
              />
            ))}
      </ListItem>
      <InProgressDialogNotification open={updateInProgress} closable={false} text="Updating synonyms" />
    </>
  );
}
