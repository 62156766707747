import _ from "lodash";
import { DynamicFilterInput, FilterInput } from "@coral/typings";
import { Range } from "../../components/form-filters/dynamic-filter-component/filter-components/range-filter";
import { FormData } from "../../../newsletter/components/newsletter-form";
import { FeedFilterType } from "../../components/form-filters/conditional-filters/constants";
import { ConditionalFilterFormValue } from "../../components/form-filters/conditional-filters";

function serializeLegacyFeedFilters(feedFilters: FormData["feedFilters"]) {
  const filters: Record<string, Record<string, string | string[] | object>> = {};

  for (const feedFilter of feedFilters) {
    filters[feedFilter.rule] = filters[feedFilter.rule] || {};

    switch (feedFilter.type) {
      case FeedFilterType.Category: {
        filters[feedFilter.rule][feedFilter.type] = (feedFilter.value as unknown as string[]).map(category => [
          category,
        ]);
        break;
      }
      case FeedFilterType.DiscountRange: {
        const range = feedFilter.value as Range;
        filters[feedFilter.rule][feedFilter.type] = {
          from: Number.parseInt(range.from, 10) / 100,
          to: Number.parseInt(range.to, 10) / 100,
        };
        break;
      }
      case FeedFilterType.PriceRange: {
        const range = feedFilter.value as Range;
        filters[feedFilter.rule][feedFilter.type] = {
          from: Number.parseInt(range.from, 10),
          to: Number.parseInt(range.to, 10),
        };
        break;
      }
      case FeedFilterType.PromotionPriceRange: {
        const range = feedFilter.value as Range;
        filters[feedFilter.rule][feedFilter.type] = {
          from: Number.parseInt(range.from, 10),
          to: Number.parseInt(range.to, 10),
        };
        break;
      }
      default: {
        filters[feedFilter.rule][feedFilter.type] =
          typeof feedFilter.value === "number" ? feedFilter.value.toString() : feedFilter.value;
      }
    }
  }

  return filters;
}

function isFeedFilterWithRange(
  filter: FormData["feedFilters"][0],
): filter is FormData["feedFilters"][0] & { value: Range } {
  const range = filter.value as Range;
  return range.from !== undefined && range.to !== undefined;
}

function serializeDynamicFeedFilter(feedFilters: FormData["feedFilters"]) {
  const dynamicFilters: DynamicFilterInput[] = [];

  for (const feedFilter of feedFilters) {
    const dynamicFilter: DynamicFilterInput = {
      rule_name: feedFilter.rule,
    };

    if (typeof feedFilter.value === "number") {
      dynamicFilter.numericalFilter = { field: feedFilter.type, value: feedFilter.value };
    } else if (typeof feedFilter.value === "string") {
      dynamicFilter.textFilter = { field: feedFilter.type, value: feedFilter.value };
    } else if (isFeedFilterWithRange(feedFilter)) {
      dynamicFilter.rangeFilter = {
        field: feedFilter.type,
        value: { from: Number.parseFloat(feedFilter.value.from), to: Number.parseFloat(feedFilter.value.to) },
      };
    } else if (Array.isArray(feedFilter.value)) {
      dynamicFilter.textArrFilter = { field: feedFilter.type, value: feedFilter.value };
    }

    dynamicFilters.push(dynamicFilter);
  }

  return dynamicFilters;
}

function isLegacyFilter(filter: ConditionalFilterFormValue) {
  return Object.values(FeedFilterType).includes(filter.type);
}

function serializeFeedFilters(feedFilters: FormData["feedFilters"]): FilterInput {
  const [legacyFilters, dynamicFilters] = _.partition(feedFilters, isLegacyFilter);
  const filters = { ...serializeLegacyFeedFilters(legacyFilters), dynamic: serializeDynamicFeedFilter(dynamicFilters) };

  return filters;
}

export default serializeFeedFilters;
