/* eslint-disable unicorn/no-thenable */
import * as yup from "yup";
import feedFilterObjectShape from "../../../common/components/form-filters/conditional-filters/validation-schema";

export default yup.object().shape({
  newsletterName: yup
    .string()
    .max(100, "Name can't be longer than 100 characters")
    .nullable()
    .required("Feed name is required!"),
  feedStatus: yup.string().nullable().required("Feed status is required"),
  feedStrategy: yup
    .array()
    .min(1, "Please select at least one feed Strategy")
    .nullable()
    .required("Please select at least one feed Strategy"),
  feedFilters: yup.array().of(feedFilterObjectShape).nullable(),
  orderStrategy: yup
    .string()
    .nullable()
    .when("feedStrategy", {
      is: (feedStrategy: string[]) => feedStrategy && feedStrategy.length > 1,
      then: yup.string().required("Please select an order strategy"),
      otherwise: yup.string().nullable(),
    }),
  landingPageUrl: yup.string().nullable(),
  htmlTileTemplate: yup.string().nullable(),
});
