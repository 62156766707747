import { SearchOptimization } from "@coral/typings";
import DataTable, { TableState } from "@gfg/ui-v2/components/data-table";
import { useCallback } from "react";
import { SortFunction } from "@gfg/ui-v2/components/data-table/use-table-state";
import { ActionsRow, useTableRowActions } from "~/search-optimizations/hooks/use-table-row-actions";
import TableFooter from "~/common/components/table/table-footer";
import { Column } from "~/search-optimizations/hooks/use-columns";

interface OptimizationsTableProps {
  searchOptimizations: SearchOptimization[] | undefined;
  total: number | undefined;
  loading?: boolean;
  state: TableState<SearchOptimization>;
  onSort: SortFunction<SearchOptimization>;
  columns: Column[];

  onOffsetChange(offset: number): void;
  offset: number;

  onLimitChange(limit: number): void;
  limit: number;

  onEditOptimization(optimization: SearchOptimization): void;
  onRemoveOptimization(optimization: SearchOptimization): void;
  onChangeStatus(optimization: SearchOptimization): void;
}

export default function OptimizationsTable({
  searchOptimizations,
  loading,
  state,
  onSort,
  onOffsetChange,
  onLimitChange,
  offset,
  columns,
  total,
  limit,
  onEditOptimization,
  onChangeStatus,
  onRemoveOptimization,
}: OptimizationsTableProps) {
  const handleAction = useCallback((action: ActionsRow, row: SearchOptimization) => {
    switch (action) {
      case "delete":
        onRemoveOptimization(row);
        break;
      case "edit":
        onEditOptimization(row);
        break;
      case "change-status":
        onChangeStatus(row);
        break;
      default:
    }
  }, []);

  const actions = useTableRowActions(handleAction);

  return (
    <>
      <DataTable
        onSort={onSort}
        actions={actions}
        selectable={false}
        columns={columns}
        state={state}
        data={searchOptimizations || []}
        loading={loading}
      />
      <TableFooter
        onOffsetChange={onOffsetChange}
        offset={offset}
        total={total}
        limit={limit.toString()}
        onLimitChange={onLimitChange}
      />
    </>
  );
}
