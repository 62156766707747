import { Option, Select } from "@gfg/ui-v2/components/select";
import { makeStyles } from "@gfg/ui-v2/theming";
import pagination from "../constants/pagination";

const useStyles = makeStyles()(({ spacing, colors }) => ({
  viewPerPage: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    marginLeft: spacing(24),
    color: colors.gray,
  },
  viewPerPageSelect: {
    width: "89px",
    marginLeft: spacing(20),
  },
}));

interface ViewPerPageProps {
  limit: string;
  setLimit: (perPage: string) => void;
}

const ViewPerPage = ({ setLimit, limit }: ViewPerPageProps) => {
  const { classes } = useStyles();

  return (
    <div className={classes.viewPerPage}>
      View per page
      <Select
        onChange={(value: string) => setLimit(value)}
        value={limit}
        variant="outline"
        className={classes.viewPerPageSelect}
      >
        {pagination.LIMITS.map(value => (
          <Option text={value} value={value} key={value}>
            {value}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default ViewPerPage;
