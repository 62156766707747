import { PreviewItem } from "@coral/typings";

export default function makeItems(item: PreviewItem): Array<{ name: string; value: string }> {
  const arrays: Array<{ name: string; value: string }> = [
    {
      name: "Brand",
      value: item.brand?.name,
    },
    {
      name: "Price",
      value: `${item.price.previous.toString()} - ${item.price.current.toString()} ${item.price.currency}`,
    },
    {
      name: "Stock",
      value: item.stock_count.toString(),
    },
    {
      name: "Version",
      value: item.version || "No version yet",
    },
    {
      name: "Color",
      value: item.colors?.join(", ") || "No colors",
    },
  ];

  if (item.skus?.length > 0) {
    arrays.push({
      name: "SKU",
      value: item.skus[0],
    });
  }

  if (item.ctl_algorithm) {
    arrays.push({
      name: "CTL alg.",
      value: item.ctl_algorithm,
    });
  }

  return arrays;
}
