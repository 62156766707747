import { useCallback } from "react";
import * as csv from "papaparse";

export default function useDownloadCsv() {
  const downloadCsv = useCallback((dataForExport: string[][], exportPrefix: string) => {
    const element = document.createElement("a");
    const exportCsv = csv.unparse(dataForExport);
    const file = new Blob([exportCsv], { type: "text/csv" });

    element.href = URL.createObjectURL(file);
    element.download = `${exportPrefix}-report-${new Date().toISOString()}.csv`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }, []);

  return downloadCsv;
}
