import { CampaignShort } from "@coral/typings";
import { useCallback } from "react";
import { TableRowAction, TableRowActionProps } from "@gfg/ui-v2/components/table";
import { DuplicateIcon, Edit3Icon, Trash2Icon } from "@gfg/ui-v2/icons";

export type ActionsRow = "edit" | "delete" | "duplicate";

export function useTableRowActions(onAction: (action: ActionsRow, row: CampaignShort) => void) {
  return useCallback(
    (_: CampaignShort[], row: CampaignShort): React.ReactElement<TableRowActionProps>[] | null => [
      <TableRowAction
        key={`${row.id}-duplicate`}
        icon={DuplicateIcon}
        value="duplicate"
        onClick={() => onAction("duplicate", row)}
      >
        Duplicate
      </TableRowAction>,
      <TableRowAction key={`${row.id}-edit`} icon={Edit3Icon} value="edit" onClick={() => onAction("edit", row)}>
        Edit
      </TableRowAction>,
      <TableRowAction key={`${row.id}-delete`} icon={Trash2Icon} value="delete" onClick={() => onAction("delete", row)}>
        Delete
      </TableRowAction>,
    ],
    [],
  );
}
