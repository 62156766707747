import produce from "immer";
import { useCallback, useMemo, useState } from "react";
import { convertStringToSynonyms } from "../../utils";

export interface SynonymInEditMode {
  synonym: string;
  newState?: "added" | "deleted";
}

interface EditedSynonymsState {
  [synonymName: string]: SynonymInEditMode;
}

function getEditedSynonyms(synonyms: string[]): EditedSynonymsState {
  return Object.fromEntries(synonyms.map(synonym => [synonym, { synonym, newState: undefined }]));
}

function useSynonymsEditor() {
  const [editedSynonymState, setEditedSynonymState] = useState<EditedSynonymsState>({});
  const [editMode, setEditMode] = useState(false);

  const synonymsInEditMode = useMemo(() => Object.values(editedSynonymState), [editedSynonymState]);
  const unsavedChanges = useMemo(() => synonymsInEditMode.some(es => es.newState !== undefined), [synonymsInEditMode]);

  const handleAddSynonymsToSet = useCallback(
    (synonymsInputValue: string) => {
      const newSynonyms = convertStringToSynonyms(synonymsInputValue);

      setEditedSynonymState(oldState =>
        produce(oldState, draft => {
          for (const synonym of newSynonyms) {
            draft[synonym] = draft[synonym] ?? { synonym, newState: "added" };
          }
        }),
      );
    },
    [convertStringToSynonyms, setEditedSynonymState, produce],
  );

  const handleDeleteSynonymFromSet = useCallback(
    (editedSynonym: SynonymInEditMode) => {
      if (editedSynonym.newState === "added") {
        setEditedSynonymState(oldState =>
          produce(oldState, draft => {
            delete draft[editedSynonym.synonym];
          }),
        );
      } else if (editedSynonym.newState === "deleted") {
        setEditedSynonymState(oldState =>
          produce(oldState, draft => {
            // if synonym was deleted and it is "deleted" again - it will reappear back as not deleted
            draft[editedSynonym.synonym].newState = undefined;
          }),
        );
      } else {
        setEditedSynonymState(oldState =>
          produce(oldState, draft => {
            draft[editedSynonym.synonym].newState = "deleted";
          }),
        );
      }
    },
    [setEditedSynonymState, produce],
  );

  const handleStartEditing = (synonyms: string[]) => {
    setEditedSynonymState(getEditedSynonyms(synonyms));
    setEditMode(true);
  };

  const handleStopEditing = () => {
    setEditedSynonymState({});
    setEditMode(false);
  };

  return {
    synonymsInEditMode,
    synonymsAfterChanges: synonymsInEditMode
      .filter(({ newState }) => newState !== "deleted")
      .map(({ synonym }) => synonym),
    unsavedChanges,
    addSynonymsToSet: handleAddSynonymsToSet,
    deleteSynonymFromSet: handleDeleteSynonymFromSet,
    startEditing: handleStartEditing,
    stopEditing: handleStopEditing,
    isEditInProgress: editMode,
  };
}

export default useSynonymsEditor;
