import { useCallback } from "react";
import { useReactiveVar } from "@apollo/client";
import { useUpdateSearchOptimizationMutation } from "~/generated";
import { FormData } from "../components/optimizations-form";
import reactiveVariables from "~/common/graphql/global-reactive-variables";

function useUpdateSearchOptimization() {
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const [updateSearchOptimization] = useUpdateSearchOptimizationMutation({
    refetchQueries: ["GetSearchOptimizations", "GetSearchOptimization"],
  });

  return useCallback(
    async (id: string, formData: FormData) =>
      await updateSearchOptimization({
        variables: {
          params: {
            id,
            query: formData.query,
            output: formData.output,
            state: formData.state,
            projectId: selectedEnvironmentProjectId,
          },
        },
      }),
    [updateSearchOptimization],
  );
}

export default useUpdateSearchOptimization;
