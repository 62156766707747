import { makeStyles } from "@gfg/ui-v2/theming";
import Title from "../common/components/title";
import SynonymsList from "./components/synonyms-list/synonyms-list";
import SynonymsHeader from "./components/synonyms-header/synonyms-header";

const useStyles = makeStyles()(({ spacing }) => ({
  container: {
    margin: spacing(20, 16),
  },
}));

export default function SynonymsPage() {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Title text="Synonyms" />
      <p>
        Synonyms help datajet to determine which key terms can be considered the same as others while performing a
        search query <br />
        <a target="_blank" href="/docs/search-management/synonyms">
          Read more about the Synonyms
        </a>
      </p>
      <SynonymsHeader />
      <SynonymsList />
    </div>
  );
}
