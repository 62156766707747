import { useCallback, useState } from "react";
import produce from "immer";

import { ApolloError, useReactiveVar } from "@apollo/client";
import extractGraphqlError from "common/utils/apollo/extract-graphql-error";
import { useCreateUnidirectionalSynonymsSetMutation } from "../../generated";
import useAppNotifications from "../../common/hooks/use-app-notifications";
import reactiveVariables from "../../common/graphql/global-reactive-variables";

function useCreateSynonymsSet() {
  const [inProgress, setInProgress] = useState(false);
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);
  const { addSuccessNotification, addErrorNotification } = useAppNotifications();

  const [createSynonymsSetMutation] = useCreateUnidirectionalSynonymsSetMutation({
    updateQueries: {
      GetSynonyms: (prev, { mutationResult: { data } }) =>
        produce(prev, draft => {
          if (data.createUnidirectionalSynonymsSet.__typename === "SuccessfulCreateUnidirectionalSynonymsSetResponse") {
            const { synonymsSet } = data.createUnidirectionalSynonymsSet;
            draft.synonymsSets.unshift(synonymsSet);
          }
        }),
    },
    refetchQueries: ["GetUnidirectionalSynonyms"],
  });

  const createSynonymsSet = useCallback(
    async (inputs: string[], outputs: string[]): Promise<boolean> => {
      try {
        setInProgress(true);
        const response = await createSynonymsSetMutation({
          variables: {
            input: {
              inputs,
              outputs,
              projectId: selectedEnvironmentProjectId,
            },
          },
        });

        if (response.data.createUnidirectionalSynonymsSet.__typename === "InvalidUnidirectionalArgumentResponse") {
          addErrorNotification(
            "Error while creating synonyms",
            response.data.createUnidirectionalSynonymsSet.errorMessage,
          );
          return false;
        }

        addSuccessNotification(`Successfully created unidirectional synonym`);
        return true;
      } catch (error) {
        const message = extractGraphqlError(error as ApolloError);
        addErrorNotification("Unknown error during processing", message);
      } finally {
        setInProgress(false);
      }

      return false;
    },
    [createSynonymsSetMutation],
  );

  return { createSynonymsSet, inProgress };
}

export default useCreateSynonymsSet;
