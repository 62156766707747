import Button from "@gfg/ui-v2/components/button";
import { makeStyles } from "@gfg/ui-v2/theming";
import Spacing from "../../../../../common/components/spacing";
import SkuButton from "~/common/components/sku-button";

const useStyles = makeStyles()(({ spacing, size, colors, palette, typography }) => ({
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: spacing("sm"),
  },
  container: {
    display: "flex",
    flexDirection: "column",
    marginBottom: spacing(10),
    background: colors.background,
    border: "0.5px solid #d0d5dd",
    boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    borderRadius: spacing(4),
    padding: spacing(16),
    width: spacing(300),
  },
  imgContainer: {
    objectFit: "contain",
    height: size(240),
  },
  itemsContainer: {
    flex: 3,
    paddingLeft: spacing(12),
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing(7),
    borderBottom: `1px solid ${palette.tertiary[200]}`,
    gap: spacing(15),
  },
  name: {
    color: colors.primary,
    fontWeight: typography.fontWeights.bold,
  },
  tag: {
    cursor: "pointer",
  },
  skuItem: {
    display: "flex",
    alignItems: "center",
  },
}));

interface SearchPreviewItemProps {
  id: string;
  values: Array<{ name: string; value: string }>;
  imagePath: string;
  explanation?: string;
  onShowExplanation: (explanation?: string) => void;
}

export default function SearchPreviewItem({
  id,
  explanation,
  values,
  imagePath,
  onShowExplanation,
}: SearchPreviewItemProps) {
  const { classes, cx } = useStyles();

  return (
    <div className={classes.container}>
      <img className={classes.imgContainer} src={imagePath} alt="" />
      <Spacing top={20} />
      <div className={classes.itemsContainer}>
        <div className={cx(classes.item, classes.skuItem)}>
          <span className={classes.name}>SKU</span>
          <SkuButton value={id} />
        </div>
        {values.map(item => (
          <div className={classes.item} key={item.name}>
            <span className={classes.name}>{item.name}</span> <span>{item.value}</span>
          </div>
        ))}
        <div className={classes.buttonContainer}>
          {explanation && (
            <Button size="small" onClick={() => onShowExplanation(explanation)}>
              Show Ranking Explanation
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
