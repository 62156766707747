import { InMemoryCache, makeVar } from "@apollo/client";
import { User } from "@coral/typings";

interface SelectedUserVar extends User {
  operation: string;
}

export const selectedUserVar = makeVar<SelectedUserVar | null>(null);
export const previewDrawerState = makeVar<boolean | null>(false);
export const createUserModalOpenStatusVar = makeVar<boolean | null>(false);

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        selectedUser: {
          read(): SelectedUserVar | null {
            return selectedUserVar();
          },
        },
      },
    },
  },
});

export default cache;
