export const FeedFilterType = {
  Campaign: "campaign",
  Category: "category",
  ShopType: "shop_type",
  Segment: "segment",
  Brand: "brand",
  Product: "product",
  PriceRange: "price",
  PromotionPriceRange: "promotion_price",
  Size: "size",
  Color: "color",
  DiscountRange: "discount",
  CatalogType: "catalog_type",
  ProductAge: "product_age",
  Sku: "sku",
  Gender: "gender",
};

export const RuleOption = {
  Include: "include",
  Exclude: "exclude",
};

export const Scenario = {
  Web: "web",
  App: "app",
};
