import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ spacing, colors, shadow, breakpoints, typography, palette }) => ({
  icon: {
    background: palette.secondary[200],
    height: spacing(40),
    width: spacing(40),
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",

    boxShadow: shadow("md"),
    padding: spacing(10, 24, 20),
    margin: spacing(5),
    justifyContent: "space-between",
    gap: spacing(20),
    marginBottom: spacing(25),

    [breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  container: {
    display: "flex",
  },
  subTitle: {
    fontWeight: typography.fontWeights.semiBold,
    color: colors.gray,
    margin: spacing(8, 0),
  },
  item: {
    margin: spacing(10),
  },
}));

export default useStyles;
