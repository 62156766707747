import * as yup from "yup";

function yupValidateWithoutThrow<T>(schema: yup.AnyObjectSchema, value: T) {
  try {
    schema.validateSync(value);
    return [];
  } catch (error) {
    const { errors } = error as yup.ValidationError;
    return errors;
  }
}

export default yupValidateWithoutThrow;
