/* eslint-disable import/no-extraneous-dependencies */
import { Chart } from "@coral/typings";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  SubTitle,
  Tooltip,
  Legend,
  Colors,
  ChartOptions,
  ChartData,
} from "chart.js";
import { Line } from "react-chartjs-2";
import ContentContainer from "../containers/content-container";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, SubTitle, Tooltip, Legend, Colors);

interface ChartProps {
  chart: Chart;
}

ChartJS.defaults.font.family = "Inter";

export default function DynamicChart({ chart: chartData }: ChartProps) {
  const datasetConfig: ChartData<"line"> = {
    labels: chartData.data.labels.values,
    datasets: chartData.data.ranges.map(range => ({
      label: range.name,
      data: range.values.map(v => Number.parseFloat(v)),
      borderColor: range.options?.color,
      backgroundColor: range.options?.color,
    })),
  };

  const chartOptions: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      y: {
        beginAtZero: chartData.options.yAxisBeginAtZero,
        ticks: {
          callback(value) {
            const { yAxisLabelPrefix, yAxisLabelPostfix, yAxisDecimalPlaces } = chartData.options;
            const normalisedValue = Number.parseFloat(value as string).toFixed(yAxisDecimalPlaces || 2);

            return `${yAxisLabelPrefix || ""}${normalisedValue}${yAxisLabelPostfix || ""}`;
          },
        },
        title: { text: chartData.options.yAxisName, display: !!chartData.options.yAxisName },
      },
      x: {
        title: { text: chartData.options.xAxisName, display: !!chartData.options.xAxisName },
      },
    },
    // resizeDelay: 20,
    plugins: {
      legend: {
        position: "right" as const,
        align: "center",
        labels: {
          textAlign: "left",
          // pointStyle: "circle",
          usePointStyle: true,
        },
      },
      colors: {
        enabled: true,
        forceOverride: true,
      },
      title: {
        display: true,
        text: chartData.options.title,
        position: "top",
        align: "start",
        color: "black",
        font: { size: 20, weight: "bold" },
        padding: { top: 10 },
      },
      subtitle: {
        display: true,
        text: chartData.options.subtitle,
        align: "start",
        color: "#344054",
        padding: { top: 5, bottom: 20 },
      },
    },
  };

  return (
    <ContentContainer rounded>
      <Line redraw options={chartOptions} data={datasetConfig} />
    </ContentContainer>
  );
}
