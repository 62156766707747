import CircularProgress from "@gfg/ui-v2/components/circular-progress";
import { useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../auth-provider";

export default function LogoutPage() {
  const navigate = useNavigate();
  const { viewer, logout } = useAuth();
  const [loading, setLoaded] = useReducer(() => false, true);

  // not using RequireAuth here, since we don't want to immediately go to login page when logged off
  // but we still want to automatically redirect to root page when we're navigating here without user
  useEffect(() => {
    if (!viewer) {
      navigate("/", { replace: true });
    }
  }, []);

  useEffect(() => {
    (window as any).google.accounts.id.disableAutoSelect();
    let tmid: ReturnType<typeof setTimeout>;
    logout().then(() => {
      setLoaded();
      tmid = setTimeout(() => navigate("/"), 3000);

      return () => clearTimeout(tmid);
    });
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <h1>You are now logged off!</h1>
      You will be shortly redirected to the home page.
    </>
  );
}
