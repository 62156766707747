import Dialog, { DialogBody, DialogHeader } from "@gfg/ui-v2/components/dialog";
import { makeStyles } from "@gfg/ui-v2/theming";
import { Button } from "@gfg/ui-v2/components/button";
import { Controller, useForm } from "react-hook-form";
import { SearchOptimization, SearchOptimizationState } from "@coral/typings";
import { Option, SelectField } from "@gfg/ui-v2/components/select";
import { useEffect } from "react";
import SearchOptimizationStateMap from "~/common/constants/search-optimization-state";

const useStyles = makeStyles()(({ spacing, typography, colors, palette }) => ({
  dialogHeader: {
    "fontWeight": typography.fontWeights.medium,
    "& button": {
      color: colors.text,
    },
  },
  title: {
    margin: 0,
  },
  dialogBody: {
    textAlign: "center",
  },
  dialogButtons: {
    marginTop: spacing(20),
  },
  closeButton: {
    color: colors.text,
  },
  button: {
    width: "48%",
  },
  cancelBtn: {
    marginRight: "4%",
    color: colors.darkgray,
    border: `1px solid ${palette.tertiary[200]}`,
  },
  select: {
    "& > div": {
      width: "100%",
    },
  },
}));

interface DeleteModalProps {
  optimization: SearchOptimization;
  text: string;
  open: boolean;
  onClose: () => void;
  onSave: (optimization: SearchOptimization, newState: SearchOptimizationState) => void;
}

export default function ChangeStatusModal({ text, open, optimization, onClose, onSave }: DeleteModalProps) {
  const { cx, classes } = useStyles();
  const form = useForm({
    defaultValues: {
      status: optimization?.state,
    },
  });

  useEffect(() => {
    form.reset({
      status: optimization?.state,
    });
  }, [optimization]);

  const handleSave = () => {
    const data = form.getValues();
    onSave(optimization, data.status);
  };

  return (
    <Dialog open={open} size="small" onClose={onClose}>
      <DialogHeader className={classes.dialogHeader}>
        <h4 className={classes.title}>Change status</h4>
      </DialogHeader>
      <DialogBody className={classes.dialogBody}>
        <small>{text}</small>
        <Controller
          name="status"
          control={form.control}
          render={({ field }) => (
            <SelectField
              className={classes.select}
              placeholder="Select status"
              label="Status"
              onChange={field.onChange}
              value={field.value}
              defaultValue={field.value}
            >
              {Object.values(SearchOptimizationStateMap).map(value => (
                <Option key={value} value={value} text={value} />
              ))}
            </SelectField>
          )}
        />
        <div className={classes.dialogButtons}>
          <Button onClick={onClose} className={cx(classes.button, classes.cancelBtn)} variant="outline">
            Cancel
          </Button>
          <Button onClick={handleSave} className={cx(classes.button)} variant="filled">
            Save
          </Button>
        </div>
      </DialogBody>
    </Dialog>
  );
}
