import React, { useMemo, useState } from "react";
import Button from "@gfg/ui-v2/components/button";
import { ArrowDownIcon } from "@gfg/ui-v2/icons";

import { makeStyles } from "@gfg/ui-v2/theming";
import ProductCatalogTable from "./product-catalog-table";
import ProductCatalogTableRow from "./product-catalog-table/row";
import ProductSectionTitle from "./product-section-title";

const useStyles = makeStyles()(({ spacing, colors, palette, typography }) => ({
  sectionWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: spacing(20),
  },
  container: {
    width: "100%",
    border: `1px solid ${palette.tertiary[200]}`,
    marginTop: spacing(32),
    paddingBottom: spacing(32),
  },
  contentContainer: {
    "display": "flex",
    "justifyContent": "space-between",
    "padding": spacing(24),
    "& > div": {
      marginLeft: 0,
    },
  },
  name: {
    color: colors.primary,
    fontWeight: typography.fontWeights.bold,
    paddingRight: 15,
  },
}));

export interface ProductCatalogAttributesProps {
  title: string;
  items: Array<{ value: string; name: string }>;
}

const trimItemsLimit = 8;

export default function ProductCatalogAttributes({ title, items }: ProductCatalogAttributesProps) {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState(items.length <= trimItemsLimit);

  const trimmedItems = useMemo(() => {
    if (!expanded) {
      return items.slice(0, trimItemsLimit);
    }

    return items;
  }, [expanded, items]);

  return (
    <div className={classes.sectionWrapper}>
      <div className={classes.container}>
        <ProductSectionTitle title={title} />
        <div className={classes.contentContainer}>
          <ProductCatalogTable>
            {trimmedItems.map(item => (
              <ProductCatalogTableRow key={item.name}>
                <span className={classes.name}>{item.name}</span>
                <span>{item.value}</span>
              </ProductCatalogTableRow>
            ))}
          </ProductCatalogTable>
        </div>
      </div>
      {!expanded && (
        <Button leftIcon={ArrowDownIcon} variant="outline" onClick={() => setExpanded(!expanded)}>
          Show all attributes
        </Button>
      )}
    </div>
  );
}
