import { useTheme } from "@gfg/ui-v2/theming";

export default function useScreenWidth() {
  const { theme } = useTheme();
  const isMobile = window.innerWidth <= theme.breakpoints.getWidth("md");
  const isTablet = window.innerWidth <= theme.breakpoints.getWidth("lg");
  const isDesktop = window.innerWidth > theme.breakpoints.getWidth("lg");

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
}
