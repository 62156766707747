import { useCallback } from "react";

export default function useCopyClipboard() {
  const copyToClipboard = useCallback(async (value: string): Promise<boolean> => {
    try {
      await navigator.clipboard.writeText(value);
      return true;
    } catch {
      return false;
    }
  }, []);

  return {
    copyToClipboard,
  };
}
