import { makeStyles } from "@gfg/ui-v2/theming";
import ContainerShadow from "./container-shadow";

const useStyles = makeStyles<StylingProps>()((theme, { rounded }) => ({
  container: {
    padding: theme.spacing(15),
    background: theme.colors.background,
    borderRadius: rounded ? theme.spacing(10) : undefined,
  },
}));

interface ContainerShadowProps extends StylingProps {
  children: React.ReactNode;
  className?: string;
}

interface StylingProps {
  rounded?: boolean;
}

export default function ContentContainer({ children, className, ...stylingProps }: ContainerShadowProps) {
  const { classes, cx } = useStyles(stylingProps);

  return <ContainerShadow className={cx(classes.container, className)}>{children}</ContainerShadow>;
}
