import { useReactiveVar } from "@apollo/client";
import PageContainer from "../../../common/components/containers/page-container";
import ChartGroups from "~/common/components/chart-groups";
import reactiveVariables from "~/common/graphql/global-reactive-variables";
import Title from "~/common/components/title";

export default function SearchAnalyticsPage() {
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  return (
    <PageContainer>
      <Title text="Search analytics" />
      <p>
        Search and Catalog KPIs across the region to track the performance over period of time <br />
        {/* <a target="_blank" href="/docs/users">
          Read more about the analytics
        </a> */}
      </p>

      <ChartGroups projectId={selectedEnvironmentProjectId} section="analytics_search_config" />
    </PageContainer>
  );
}
