import { useCallback } from "react";
import { FormGroup } from "@gfg/ui-v2/components/form-group";
import Input, { InputGroup } from "@gfg/ui-v2/components/input";

export interface Range {
  from: string;
  to: string;
}

export interface FilterRangeProps {
  value: { from: string; to: string };
  onChange(value: Range): void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;

  disabled: boolean;
  required?: boolean;
  label?: string;
}

export default function FilterRange({
  value,
  onChange,
  label,
  disabled = false,
  onBlur,
  required = true,
}: FilterRangeProps) {
  const onChangeTo = useCallback(
    (to: string) => {
      onChange({ from: value?.from, to });
    },
    [onChange, value],
  );

  const onChangeFrom = useCallback(
    (from: string) => {
      onChange({ from, to: value?.to });
    },
    [onChange, value],
  );

  return (
    <FormGroup block label={label}>
      <InputGroup block>
        <Input
          disabled={disabled}
          onChange={onChangeFrom}
          onBlur={onBlur}
          value={value?.from}
          placeholder="From"
          variant="outline"
          type="number"
          required={required}
          block
        />
        <Input
          disabled={disabled}
          onChange={onChangeTo}
          onBlur={onBlur}
          value={value?.to}
          placeholder="To"
          variant="outline"
          type="number"
          required={required}
          block
        />
      </InputGroup>
    </FormGroup>
  );
}
