import { SearchOptimizationState } from "@coral/typings";

interface SearchOptimizationStateMapInterface {
  Enabled: SearchOptimizationState;
  Disabled: SearchOptimizationState;
  Pending: SearchOptimizationState;
}

const SearchOptimizationStateMap: SearchOptimizationStateMapInterface = {
  Enabled: "Enabled",
  Disabled: "Disabled",
  Pending: "Pending",
};

export default SearchOptimizationStateMap;
