import { useCallback, useState } from "react";
import produce from "immer";
import { SynonymsSet } from "@coral/typings";

import { ApolloError, useReactiveVar } from "@apollo/client";
import extractGraphqlError from "common/utils/apollo/extract-graphql-error";
import { useUpdateUnidirectionalSynonymsSetMutation } from "../../generated";
import useAppNotifications from "../../common/hooks/use-app-notifications";
import reactiveVariables from "../../common/graphql/global-reactive-variables";

function useUpdateSynonymsSet() {
  const [inProgress, setInProgress] = useState(false);
  const { addSuccessNotification, addErrorNotification } = useAppNotifications();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const [updateSynonymsSetMutation] = useUpdateUnidirectionalSynonymsSetMutation({
    updateQueries: {
      GetSynonyms: (prev, { mutationResult: { data } }) =>
        produce(prev, draft => {
          if (data.updateUnidirectionalSynonymsSet.__typename === "SuccessfulUpdateUnidirectionalSynonymsSetResponse") {
            const { synonymsSet } = data.updateUnidirectionalSynonymsSet;
            draft.synonymsSets = draft.synonymsSets.filter((set: SynonymsSet) => set.id !== synonymsSet.id);
            draft.synonymsSets.unshift(synonymsSet);
          }
        }),
    },
    refetchQueries: ["GetUnidirectionalSynonyms"],
  });

  const updateSynonymsSet = useCallback(
    async (id: string, inputs: string[], outputs: string[]): Promise<boolean> => {
      try {
        setInProgress(true);
        const response = await updateSynonymsSetMutation({
          variables: {
            id,
            input: {
              projectId: selectedEnvironmentProjectId,
              inputs,
              outputs,
            },
          },
        });

        if (response.data.updateUnidirectionalSynonymsSet.__typename === "InvalidUnidirectionalArgumentResponse") {
          addErrorNotification(
            "Error while updating synonyms",
            response.data.updateUnidirectionalSynonymsSet.errorMessage,
          );
          return false;
        }

        addSuccessNotification(`Successfully updated synonyms`);

        return true;
      } catch (error) {
        const message = extractGraphqlError(error as ApolloError);
        addErrorNotification("Unknown error during processing", message);
      } finally {
        setInProgress(false);
      }

      return false;
    },
    [updateSynonymsSetMutation],
  );

  return { updateSynonymsSet, inProgress };
}

export default useUpdateSynonymsSet;
