import { useNavigate } from "react-router-dom";
import GeneralError from "../general-error";

export default function ForbiddenPage() {
  const navigate = useNavigate();

  return (
    <GeneralError
      heading="Permission denied!"
      subheading="403 error"
      // eslint-disable-next-line react/no-unescaped-entities
      text={<>We are sorry but you don't have access to this page. </>}
      buttonLabel="Take me back"
      onButtonClick={() => navigate("/", { replace: true })}
      img="/no-permission.svg"
    />
  );
}
