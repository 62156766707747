import { ChartGroup, ResourceName } from "@coral/typings";

import { useEffect, useMemo, useState } from "react";
import { LinearProgress } from "@gfg/ui-v2/components/linear-progress";
import useChartGroups from "../charts/hooks/use-chart-groups";
import DynamicChartGroup from "./dynamic-chart-group";
import Spacing from "../spacing";
import ChartGroupSelector from "./chart-group-selector";

interface ChartGroupProps {
  section: ResourceName;
  projectId: string;
}

export default function ChartGroups({ projectId, section }: ChartGroupProps) {
  const { data, loading } = useChartGroups({ projectId, section });
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>();

  useEffect(() => {
    if (!selectedGroupId && data?.chartGroups) {
      const firstGroup = data?.chartGroups[0];
      setSelectedGroupId(firstGroup?.id);
    }
  }, [data]);

  const selectedGroup = useMemo(() => data?.chartGroups?.find(g => g.id === selectedGroupId), [data, selectedGroupId]);

  if (loading) {
    return <LinearProgress indeterminate />;
  }

  if (data?.chartGroups?.length) {
    return (
      <Spacing bottom={30}>
        <Spacing bottom={10}>
          <ChartGroupSelector
            chartGroups={data?.chartGroups as ChartGroup[]}
            onSelectGroupId={setSelectedGroupId}
            selectedGroupId={selectedGroupId}
          />
        </Spacing>
        {selectedGroup && <DynamicChartGroup group={selectedGroup as ChartGroup} />}
      </Spacing>
    );
  }

  return null;
}
