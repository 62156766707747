import { SearchConfigType } from "@coral/typings";

interface CampaignTypeMapInterface {
  Campaign: SearchConfigType;
  Search: SearchConfigType;
  Brand: SearchConfigType;
  Category: SearchConfigType;
}

const CampaignTypeMap: CampaignTypeMapInterface = {
  Brand: "Brand",
  Campaign: "Campaign",
  Category: "Category",
  Search: "Search",
};

export default CampaignTypeMap;
