import { useCallback } from "react";
import { useReactiveVar } from "@apollo/client";
import { SearchOptimizationState } from "@coral/typings";
import { useUpdateSearchOptimizationStateMutation } from "~/generated";
import reactiveVariables from "~/common/graphql/global-reactive-variables";

function useUpdateSearchOptimizationState() {
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const [updateSearchOptimizationState] = useUpdateSearchOptimizationStateMutation({
    refetchQueries: ["GetSearchOptimizations"],
  });

  return useCallback(
    async (id: string, state: SearchOptimizationState) =>
      await updateSearchOptimizationState({
        variables: {
          params: {
            id,
            state,
            projectId: selectedEnvironmentProjectId,
          },
        },
      }),
    [updateSearchOptimizationState],
  );
}

export default useUpdateSearchOptimizationState;
