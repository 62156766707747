import { SearchConfigurationVersion } from "@coral/typings";
import { TableRowAction, TableRowActionProps } from "@gfg/ui-v2/components/table";
import { CopyIcon, Edit3Icon, Trash2Icon } from "@gfg/ui-v2/icons";
import { useCallback } from "react";

export type ActionsRow = "duplicate" | "edit" | "delete";

function useTableRowActions(onAction: (action: ActionsRow, row: SearchConfigurationVersion) => void) {
  return useCallback(
    (
      data: SearchConfigurationVersion[],
      row: SearchConfigurationVersion,
    ): React.ReactElement<TableRowActionProps>[] | null => [
      <TableRowAction
        key={`${row.id}-duplicate`}
        icon={CopyIcon}
        value="duplicate"
        onClick={() => onAction("duplicate", row)}
      >
        Duplicate Version
      </TableRowAction>,
      <TableRowAction key={`${row.id}-edit`} icon={Edit3Icon} value="edit" onClick={() => onAction("edit", row)}>
        Edit Version
      </TableRowAction>,
      <TableRowAction
        disabled={row.status === "active"}
        key={`${row.id}-delete`}
        icon={Trash2Icon}
        value="delete"
        onClick={() => onAction("delete", row)}
      >
        Delete Version
      </TableRowAction>,
    ],
    [],
  );
}

export default useTableRowActions;
