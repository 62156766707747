import Dialog, { DialogHeader, DialogBody } from "@gfg/ui-v2/components/dialog";
import CircularProgress from "@gfg/ui-v2/components/circular-progress";
import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ spacing }) => ({
  dialogIconContainer: {
    display: "flex",
    justifyContent: "center",
  },
  circleIcon: {
    height: spacing(50),
    width: spacing(50),
    color: "#039855",
  },
  dialogHeader: {
    borderBottom: `none`,
  },
  dialogBody: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export interface InProgressDialogNotificationProps {
  text?: string;
  closable?: boolean;
  onClose?(): void;
  open: boolean;
}

function InProgressDialogNotification({ text, onClose, closable, open }: InProgressDialogNotificationProps) {
  const { classes } = useStyles();

  return (
    <Dialog open={open} closable={closable} onClose={onClose}>
      <DialogHeader className={classes.dialogHeader}>
        <div className={classes.dialogIconContainer}>
          <CircularProgress className={classes.circleIcon} />
        </div>
      </DialogHeader>
      <DialogBody className={classes.dialogBody}>{text}</DialogBody>
    </Dialog>
  );
}

export default InProgressDialogNotification;
