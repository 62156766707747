import { ApolloClient, createHttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { getToken, renewToken } from "../../auth/token-service";
import cache from "./cache";
import addDatadogError from "../services/datadog/add-datadog-error";

const authLink = setContext(async (_, { headers }) => {
  let token = getToken();
  if (!token) {
    token = await renewToken();
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : undefined,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors)
    for (const graphqlError of graphQLErrors) {
      addDatadogError(graphqlError, { operation });
    }

  if (networkError) {
    addDatadogError(networkError, { operation });
  }
});

const httpLink = createHttpLink({
  uri: "/graphql",
});

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache,
});

export default client;
