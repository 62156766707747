import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ colors }) => ({
  errorMessage: {
    color: colors.error,
    fontSize: 12,
    textAlign: "left",
    height: 12,
  },
}));

interface ErrorMessageProps {
  message?: string;
  className?: string | undefined;
}

export default function ErrorMessage({ message, className = "" }: ErrorMessageProps) {
  const { classes, cx } = useStyles();
  return <small className={cx(classes.errorMessage, className)}>{message}</small>;
}
