import { Column } from "@gfg/ui-v2/components/data-table";
import { SearchConfigurationVersion } from "@coral/typings";
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import date from "../../../common/services/dates";
import useStyles from "./use-styles";
import SuccessStatusTag from "../../../common/components/status-tags/success-tag";
import GrayStatusTag from "../../../common/components/status-tags/gray-tag";

export default function useColumns(): Column<SearchConfigurationVersion>[] {
  const { classes } = useStyles();
  const { region, configId } = useParams();

  return useMemo(
    () => [
      {
        id: "name",
        label: "Version Name",
        renderer: r => (
          <div>
            <p className={classes.name}>
              <Link to={`/${region}/search/search-config/${configId}/versions/edit/${r.id}`}>{r.name}</Link>
            </p>
            <p className={classes.email}>{r.description}</p>
          </div>
        ),
        sticky: true,
      },
      {
        id: "last-modified",
        label: "Last modified",
        renderer: r => (
          <div>
            <p className={classes.modified}>{r.modifiedBy}</p>
            <p className={classes.email}>{date.format(date.fromSecondsSinceEpoch(r.updatedAt || r.createdAt))}</p>
          </div>
        ),
      },
      {
        id: "state",
        label: "State",
        renderer: r => (r.status === "active" ? <SuccessStatusTag label="Active" /> : <GrayStatusTag label="Draft" />),
      },
    ],
    [classes],
  );
}
