import { useEffect, useState } from "react";
import { PaginatedUsers } from "@coral/typings";
import { useGetUsersLazyQuery } from "../../generated";
import pagination from "../../common/constants/pagination";

export default function useGetUsers() {
  const [offset, setOffset] = useState(0);
  const [users, setUsers] = useState<PaginatedUsers>();
  const [limit, setLimit] = useState(+pagination.LIMITS[0]);

  const [searchInput, setSearchInput] = useState("");
  const [getUsers, { data, loading }] = useGetUsersLazyQuery({
    variables: { pagination: { offset, limit } },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    const timerId = setTimeout(async () => {
      const response = await getUsers({
        variables: {
          filters: {
            search: searchInput ?? "",
          },
          pagination: {
            offset,
            limit,
          },
        },
      });
      const { data } = response;
      if (data) {
        setUsers(data.users as PaginatedUsers);
      }
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchInput, offset, limit, data]);

  return {
    users,
    searchInput,
    setSearchInput,
    loading,
    offset,
    setOffset,
    limit,
    setLimit,
  };
}
