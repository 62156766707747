import { SearchInputField } from "@gfg/ui-v2/components/input";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import debounce from "lodash.debounce";
import { useReactiveVar } from "@apollo/client";
import { GetProductQuery, Price, SuccessfulProductFetchResponse, FailedProductFetchResponse } from "@coral/typings";

import { LinearProgress } from "@gfg/ui-v2/components/linear-progress";
import { makeStyles } from "@gfg/ui-v2/theming";
import reactiveVariables from "../../common/graphql/global-reactive-variables";
import Breadcrumbs from "../../common/components/breadcrumbs";
import ProductCatalogHeader from "../components/product-catalog-header";
import ProductCatalogOverview from "../components/product-catalog-overview";
import ProductCatalogAttributes from "../components/product-catalog-attributes";
import { useGetProductLazyQuery } from "../../generated";
import PageContainer from "../../common/components/containers/page-container";
import ProductCatalogDescription from "../components/product-catalog-description";

const useStyles = makeStyles()(({ spacing, breakpoints, colors }) => ({
  search: {
    alignSelf: "center",
    width: spacing(400),
    height: spacing(44),
    marginRight: spacing(16),
    marginTop: spacing(16),
    color: colors.gray,

    [breakpoints.down("lg")]: {
      width: spacing(200),
    },

    [breakpoints.down("xs")]: {
      width: "100%",
      marginRight: 0,
      marginBottom: spacing(10),
    },
  },
}));

function isSuccessfulResponse(
  fetchResponse: GetProductQuery["product"] | undefined,
): fetchResponse is SuccessfulProductFetchResponse {
  return fetchResponse?.__typename === "SuccessfulProductFetchResponse";
}

function isNotFoundErrorResponse(
  fetchResponse: GetProductQuery["product"] | undefined,
): fetchResponse is FailedProductFetchResponse {
  return fetchResponse?.__typename === "FailedProductFetchResponse";
}

export default function ProductCatalog() {
  const { classes } = useStyles();
  const [searchParamsInUrl, setSearchParamsInUrl] = useSearchParams();
  const [searchInput, setSearchInput] = useState(searchParamsInUrl.get("q") ?? "");
  const [getProduct, { data, loading }] = useGetProductLazyQuery();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const fetchProduct = useCallback(
    debounce(sku => getProduct({ variables: { params: { projectId: selectedEnvironmentProjectId, sku } } }), 100),
    [getProduct],
  );

  useEffect(() => {
    if (searchInput) {
      fetchProduct(searchInput);
    }
  }, [searchInput]);

  const stockAttributes = useMemo(() => {
    if (isSuccessfulResponse(data?.product)) {
      return data.product?.product.stockAttributes?.map(a => ({ name: a.name, value: a.value?.join(", ") }));
    }
  }, [data]);

  const searchableAttributes = useMemo(() => {
    if (isSuccessfulResponse(data?.product)) {
      return data.product?.product.searchableAttributes?.map(a => ({ name: a.name, value: a.value?.join(", ") }));
    }
  }, [data]);

  const numericalAttributes = useMemo(() => {
    if (isSuccessfulResponse(data?.product)) {
      return [
        ...data.product.product.bigQueryStats,
        ...data.product.product.stats,
        ...data.product.product.catarankPrediction,
        ...data.product.product.numerics,
      ];
    }
  }, [data]);

  const handleSearchInputChange = (input: string) => {
    setSearchInput(input);
    setSearchParamsInUrl({ q: input });
  };

  return (
    <PageContainer>
      <Breadcrumbs />
      <ProductCatalogHeader />

      <SearchInputField
        onChange={handleSearchInputChange}
        value={searchInput}
        color="lightgray"
        placeholder="Search products by SKU"
        size="medium"
        variant="outline"
        valid={isNotFoundErrorResponse(data?.product) ? false : undefined}
        invalidHint={isNotFoundErrorResponse(data?.product) ? data?.product.reason : undefined}
        className={classes.search}
        autoFocus
        block
      />
      {loading && <LinearProgress indeterminate />}

      {isSuccessfulResponse(data?.product) && (
        <>
          <ProductCatalogOverview
            categories={data.product?.product.categories || []}
            mainCategories={data.product.product.mainCategories || []}
            imageUrl={data.product?.product.imagePath}
            title={data.product?.product.title}
            brandName={data.product?.product.brand.name as string}
            brandId={data.product?.product.brand.id as string}
            skus={data.product?.product.skus as string[]}
            url={data.product?.product.url as string}
            id={data.product?.product.id as string}
            price={data.product?.product.price as Price}
            publishedAt={data.product.product.publishedAt}
            sellerId={data.product.product.seller?.id}
            stockCount={data.product.product.stockCount}
            categoryNamedPath={data.product.product.categoryNamedPath}
            customScore={data.product.product.customScore}
            groupId={data.product.product.groupId}
            soldCount={data.product.product.soldCount}
            availability={data.product.product.availability}
          />
          {Array.isArray(stockAttributes) && stockAttributes.length > 0 && (
            <ProductCatalogAttributes title="Searchable Attributes" items={searchableAttributes} />
          )}
          {data.product.product.description && (
            <ProductCatalogDescription description={data.product.product.description} />
          )}
          {Array.isArray(stockAttributes) && stockAttributes.length > 0 && (
            <ProductCatalogAttributes title="Product Attributes" items={stockAttributes} />
          )}
          {Array.isArray(numericalAttributes) && numericalAttributes.length > 0 && (
            <ProductCatalogAttributes title="Misc numerical values" items={numericalAttributes} />
          )}
        </>
      )}
    </PageContainer>
  );
}
