import React, { useMemo, Fragment } from "react";
import { ChevronRightIcon, HomeIcon } from "@gfg/ui-v2/icons";
import Button, { IconButton } from "@gfg/ui-v2/components/button";
import { Link, useLocation, matchRoutes } from "react-router-dom";
import { makeStyles } from "@gfg/ui-v2/theming";
import capitalize from "../services/capitalzie";
import { routes } from "../../app";

const useStyles = makeStyles()({
  container: {
    display: "flex",
    alignItems: "center",
  },
  homeLink: {
    display: "flex",
  },
});

const isValidRoute = (pathname: string) => {
  const matches = matchRoutes(routes, pathname);
  return matches.some(match => match.pathnameBase === pathname);
};

export default function Breadcrumbs() {
  const { classes } = useStyles();
  const location = useLocation();

  const breadcrumbsList = useMemo(() => {
    const urlSegments = location.pathname.split("/").filter(path => !!path);
    const breadcrumbs: Array<{ url: string; name: string }> = [];

    let tempUrl = "";
    for (const segment of urlSegments) {
      tempUrl += `/${segment}`;
      breadcrumbs.push({ name: segment, url: tempUrl });
    }

    return breadcrumbs.slice(1);
  }, [location]);

  return (
    <div className={classes.container}>
      <Link to="/" className={classes.homeLink}>
        <IconButton variant="link" icon={HomeIcon} color="gray" />
      </Link>
      {breadcrumbsList.map((breadcrumb, index) => (
        <Fragment key={breadcrumb.url}>
          <ChevronRightIcon />
          {isValidRoute(breadcrumb.url) ? (
            <Link to={breadcrumb.url}>
              <Button variant="link" color={index !== breadcrumbsList.length - 1 ? "darkgray" : "primary"}>
                {capitalize(breadcrumb.name).replace("-", " ")}
              </Button>
            </Link>
          ) : (
            <Button disabled variant="link" color="gray">
              {capitalize(breadcrumb.name).replace("-", " ")}
            </Button>
          )}
        </Fragment>
      ))}
    </div>
  );
}
