import CustomFeedStateMap from "common/constants/custom-feed-state";
import SearchOptimizationStateMap from "~/common/constants/search-optimization-state";
import { Scenario } from "../components/form-filters/conditional-filters/constants";
import FeedStrategy from "../constants/feed-strategy";
import OrderStrategy from "../constants/order-strategy";
import ScreenName from "../constants/screen-names";

export const FeedStrategyTranslations = {
  [FeedStrategy.YouMightLike]: "You Might Like",
  [FeedStrategy.YouMightLikePersonalised]: "You Might Like Personalized",
  [FeedStrategy.MoreLikeThis]: "More Like This",
  [FeedStrategy.MoreLikeThisPersonalised]: "More Like This Personalized",
  [FeedStrategy.NextBuy]: "Next Buy",
  [FeedStrategy.NextBuyPersonalised]: "Next Buy Personalized",
  [FeedStrategy.BestDeals]: "Best Deals",
  [FeedStrategy.BestDealsPersonalised]: "Best Deals Personalized",
  [FeedStrategy.Bestsellers]: "Best Sellers",
  [FeedStrategy.BestsellersPersonalised]: "Best Sellers Personalized",
  [FeedStrategy.SimilarItems]: "Similar Items",
  [FeedStrategy.SimilarItemsPersonalised]: "Similar Items Personalized",
  [FeedStrategy.PopularInCategory]: "Popular In Category",
  [FeedStrategy.PopularInCategoryPersonalised]: "Popular In Category Personalized",
  [FeedStrategy.ViewedMost]: "Most Viewed",
  [FeedStrategy.ViewedMostPersonalised]: "Most Viewed Personalized",
  [FeedStrategy.PurchasedRecently]: "Purchased Recently",
  [FeedStrategy.ViewedRecently]: "Viewed Recently",
  [FeedStrategy.SpecialDeals]: "Special Deals",
  [FeedStrategy.SpecialDealsPersonalised]: "Special Deals Personalized",
  [FeedStrategy.TrendingProducts]: "Trending Products",
  [FeedStrategy.TrendingProductsPersonalised]: "Trending Products Personalized",
  [FeedStrategy.UserPreferences]: "User Preferences",
  [FeedStrategy.CompleteTheLook]: "Complete The Look (Beta)",
  [FeedStrategy.ViewedTogether]: "Viewed Together",
  [FeedStrategy.NewArrivals]: "New Arrivals",
  [FeedStrategy.CrossSelling]: "Cross-Selling",
};

export const OrderStrategyTranslations = {
  [OrderStrategy.EndToEnd]: "End To End",
  [OrderStrategy.Shuffle]: "Shuffle",
  [OrderStrategy.TransposeEndToEnd]: "Transpose End To End",
};

export const CustomFeedStateTranslations = {
  [CustomFeedStateMap.Active]: "Active",
  [CustomFeedStateMap.Draft]: "Draft",
  [CustomFeedStateMap.Testing]: "Testing",
};

export const SearchOptimizationStateTranslations = {
  [SearchOptimizationStateMap.Enabled]: "Enabled",
  [SearchOptimizationStateMap.Disabled]: "Disabled",
  [SearchOptimizationStateMap.Pending]: "Pending",
};

export const ScenarioTranslations = {
  [Scenario.Web]: "Web",
  [Scenario.App]: "App",
};

export const ScreenNameTranslations = {
  [ScreenName.CartPage]: "Cart Page",
  [ScreenName.ErrorPage]: "Error Page",
  [ScreenName.HomePage]: "Home Page",
  [ScreenName.ProductDetailPage]: "Product Detail Page",
  [ScreenName.Wishlist]: "Wishlist",
};
