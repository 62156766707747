import { useState } from "react";
import { List } from "@gfg/ui-v2/components/list";
import { LinearProgress } from "@gfg/ui-v2/components/linear-progress";
import useGetSynonyms from "../../hooks/use-get-synonyms";
import SynonymsSet from "../synonyms-set";
import DeleteModal from "../../../common/components/delete-modal";
import useDeleteSynonymsSet from "../../hooks/use-delete-synonyms-set";

export default function SynonymsList() {
  const { synonyms, loading } = useGetSynonyms();
  const [synonymsSetIdToBeDeleted, setSynonymsSetIdToBeDeleted] = useState<string | undefined>();
  const deleteSynonymsSet = useDeleteSynonymsSet();

  const handleDeleteSet = async () => {
    await deleteSynonymsSet(synonymsSetIdToBeDeleted);
  };

  const areSynonymsReady = !(!synonyms || loading);

  return (
    <>
      {areSynonymsReady ? (
        <List>
          {synonyms.map(({ id, synonyms }) => (
            <SynonymsSet key={id} id={id} synonyms={synonyms} onDelete={() => setSynonymsSetIdToBeDeleted(id)} />
          ))}
        </List>
      ) : (
        <LinearProgress indeterminate />
      )}
      <DeleteModal
        open={synonymsSetIdToBeDeleted !== undefined}
        title="Delete Synonyms"
        text="Are you sure that you want to delete the synonyms set?"
        onToggle={() => setSynonymsSetIdToBeDeleted(undefined)}
        onDelete={handleDeleteSet}
      />
    </>
  );
}
