import { useState } from "react";

export default function useCachedState(localStorageKey: string, enabled: boolean) {
  return useState(() => {
    if (enabled) {
      return localStorage.getItem(localStorageKey);
    }

    return "";
  });
}
