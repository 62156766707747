import { useCallback } from "react";
import produce from "immer";
import { SynonymsSet } from "@coral/typings";
import { ApolloError, useReactiveVar } from "@apollo/client";
import extractGraphqlError from "common/utils/apollo/extract-graphql-error";
import { useDeleteSynonymsSetMutation } from "../../generated";
import useAppNotifications from "../../common/hooks/use-app-notifications";
import reactiveVariables from "../../common/graphql/global-reactive-variables";

function useDeleteSynonymsSet() {
  const { addSuccessNotification, addInProgressNotification, addErrorNotification, removeNotification } =
    useAppNotifications();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);
  const [deleteSynonym] = useDeleteSynonymsSetMutation({
    updateQueries: {
      GetSynonyms: (prev, { mutationResult: { data } }) =>
        produce(prev, draft => {
          draft.synonymsSets = draft.synonymsSets.filter((set: SynonymsSet) => set.id !== data.deleteSynonymsSet.id);
        }),
    },
  });

  return useCallback(
    async (id: string) => {
      let deleteInProgressNotificationId;

      try {
        deleteInProgressNotificationId = addInProgressNotification("Deleting synonym set");

        await deleteSynonym({
          variables: {
            input: {
              id,
              projectId: selectedEnvironmentProjectId,
            },
          },
        });

        addSuccessNotification("Synonym set was deleted successfully");
      } catch (error) {
        const message = extractGraphqlError(error as ApolloError);
        addErrorNotification("Error while deleting synonym set", message);
      } finally {
        if (deleteInProgressNotificationId) {
          removeNotification(deleteInProgressNotificationId);
        }
      }
    },
    [deleteSynonym],
  );
}

export default useDeleteSynonymsSet;
