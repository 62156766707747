import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApolloError, useReactiveVar } from "@apollo/client";

import { Campaign } from "@coral/typings";
import extractGraphqlError from "common/utils/apollo/extract-graphql-error";
import { useUpdateCampaignMutation } from "../../../generated";
import { FormData } from "../../components/campaign-form";
import useAppNotifications from "../../../common/hooks/use-app-notifications";
import reactiveVariables from "../../../common/graphql/global-reactive-variables";
import serializeAttributeFilterWithRule from "../../../common/utils/search-config/serialize-attribute-filter-with-rule";
import serializeConditionalFilter from "~/common/utils/search-config/serialize-conditional-filter";

function useSubmit() {
  const { addSuccessNotification, addErrorNotification } = useAppNotifications();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const navigate = useNavigate();
  const { region } = useParams();

  const [updateCampaign] = useUpdateCampaignMutation({
    refetchQueries: ["GetCampaigns"],
  });

  return useCallback(
    async (campaignId: string, formData: FormData) => {
      try {
        const { data } = await updateCampaign({
          variables: {
            params: {
              campaignId,
              campaignName: formData.campaignName,
              value: formData.value,
              hardBoostedSkus: formData.boostedSkus,
              projectId: selectedEnvironmentProjectId,
              rankingAttributes: formData.rankAttributes.map(serializeAttributeFilterWithRule),
              filterAttributes: formData.conditionFilters.map(serializeConditionalFilter),
              type: formData.type,
            },
          },
        });

        const campaign = data?.updateCampaign as Campaign;

        addSuccessNotification("Merchandising Rule was updated successfully");
        navigate(`/${region}/merchandising/edit/${campaign.id}`);
      } catch (error) {
        const message = extractGraphqlError(error as ApolloError);
        addErrorNotification("Error while updating Merchandising Rule", message);
      }
    },
    [navigate, updateCampaign],
  );
}

export default useSubmit;
