import { InputField } from "@gfg/ui-v2/components/input";
import { KeyboardEvent, useCallback, useMemo, useState } from "react";
import Tag from "@gfg/ui-v2/components/tag";
import { makeStyles, useTheme } from "@gfg/ui-v2/theming";
import { SynonymInEditMode } from "~/unidirectional-synonyms/components/synonyms-set/use-synonyms-editor";
import UndoButton from "~/common/components/undo-button";
import yupValidateWithoutThrow from "~/common/utils/form/yup-validate-without-throw";
import validationSchema from "~/unidirectional-synonyms/components/synonyms-set/validation-schema";

const useStyles = makeStyles()(theme => ({
  input: {
    "marginBottom": theme.spacing("xs"),
    "& label": {
      border: "none",
      boxShadow: theme.shadow("sm"),
    },
    "& [class^='box']": {
      flexDirection: "row",
      border: "none",
      flexWrap: "wrap",
      boxShadow: theme.shadow("sm"),
    },

    "& input": {
      flexGrow: 1,
      width: "100%",
      maxWidth: "127px",
      fontStyle: "italic",
    },
  },
  clear: {
    "marginRight": 0,
    "marginLeft": "auto",

    "& button": {
      marginLeft: theme.spacing(12),
    },
  },
  tags: {
    flexWrap: "wrap",
  },
  tag: {
    marginLeft: theme.spacing("xs"),
  },
}));

const getSynonymColor = (newState: SynonymInEditMode["newState"], colors: Record<string, string>) => {
  switch (newState) {
    case "added":
      return colors.success;
    case "deleted":
      return colors.error;
    default:
  }
};

interface InputFieldWithTagsProps {
  onRemove: (synonym: SynonymInEditMode) => void;
  onAdd: (synonym: string) => void;
  selectedValues: SynonymInEditMode[];
}

export default function InputFieldWithTags({ onRemove, onAdd, selectedValues }: InputFieldWithTagsProps) {
  const [value, setValue] = useState("");

  const { classes } = useStyles();
  const { colors } = useTheme().theme;

  const validationErrors = useMemo(
    () =>
      yupValidateWithoutThrow(validationSchema, {
        synonyms: selectedValues.filter(item => item.newState !== "deleted").map(item => item.synonym),
      }),
    [selectedValues],
  );

  const handleToggleSuggestion = useCallback(() => {
    const normalizedValue = value.trim();

    if (normalizedValue.length > 0) {
      onAdd(normalizedValue);
      setValue("");
    }
  }, [value, onAdd, setValue]);

  return (
    <InputField
      className={classes.input}
      placeholder="Add synonym"
      onChange={(value: string) => setValue(value)}
      prepend={
        selectedValues?.length > 0 ? (
          <div className={classes.tags}>
            {selectedValues?.map(selectedValue => (
              <Tag
                className={classes.tag}
                key={selectedValue.synonym}
                closable
                label={selectedValue.synonym}
                size="small"
                variant="faded"
                closeButton={selectedValue.newState === "deleted" ? UndoButton : undefined}
                color={getSynonymColor(selectedValue.newState, colors)}
                onClose={e => {
                  e.preventDefault();
                  onRemove(selectedValue);
                }}
              />
            ))}
          </div>
        ) : undefined
      }
      invalidHint={validationErrors[0]}
      valid={validationErrors?.length ? false : undefined}
      value={value}
      onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
          handleToggleSuggestion();
        }
      }}
    />
  );
}
