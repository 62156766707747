import Dialog, { DialogBody, DialogHeader } from "@gfg/ui-v2/components/dialog";
import JsonView from "react18-json-view";
import "react18-json-view/src/style.css";
import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ colors, shadow }) => ({
  dialogHeader: {
    "& button": {
      boxShadow: `${shadow("md")}`,
      color: colors.text,
    },
  },
  dialogBody: {
    "position": "relative",
    "maxWidth": "100%",
    "overflowY": "auto",
    "& label": {
      color: colors.text,
    },
  },
  title: {
    margin: 0,
  },
}));

interface ExplanationDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  content: string;
}

export default function ExplanationDialog({ open, setOpen, content }: ExplanationDialogProps) {
  const { classes } = useStyles();
  return (
    <Dialog size="medium" open={open} onClose={() => setOpen(false)}>
      <DialogHeader className={classes.dialogHeader}>
        <h4 className={classes.title}>Explanation</h4>
      </DialogHeader>
      <DialogBody className={classes.dialogBody}>
        {content && <JsonView editable={false} src={JSON.parse(content)} />}
      </DialogBody>
    </Dialog>
  );
}
