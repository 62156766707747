import { useReactiveVar } from "@apollo/client";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Campaign, GetCampaignQuery } from "@coral/typings";
import Breadcrumbs from "../../../common/components/breadcrumbs";
import { useGetCampaignQuery } from "../../../generated";
import CampaignForm, { FormData } from "../../components/campaign-form";
import reactiveVariables from "../../../common/graphql/global-reactive-variables";
import useSubmit from "./use-submit";
import PageContainer from "../../../common/components/containers/page-container";
import deserializeAttributeFilterWithRule from "../../../common/utils/search-config/deserialize-attribute-filter-with-rule";
import deserializeConditionalFilter from "~/common/utils/search-config/deserialize-conditional-filter";

export function isSuccessfulResponse(
  fetchResponse: GetCampaignQuery["campaign"] | undefined,
): fetchResponse is Campaign {
  return fetchResponse?.__typename === "Campaign";
}

export default function EditCampaignPage() {
  const navigate = useNavigate();
  const submit = useSubmit();

  const { campaignId, region } = useParams();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const { data } = useGetCampaignQuery({
    variables: {
      params: {
        campaignId,
        projectId: selectedEnvironmentProjectId,
      },
    },
  });

  const handleSubmit = async (formData: FormData) => {
    await submit(campaignId, formData);
  };

  const defaultValues = useMemo<FormData>(() => {
    if (isSuccessfulResponse(data?.campaign)) {
      return {
        boostedSkus: data?.campaign.hardBoostedSkus,
        type: data?.campaign.type,
        value: data?.campaign.value,
        campaignName: data?.campaign.name,
        conditionFilters: data.campaign?.filterAttributes?.map(deserializeConditionalFilter),
        rankAttributes: data?.campaign.rankAttributes.map(deserializeAttributeFilterWithRule),
      };
    }
  }, [data]);

  return (
    <PageContainer>
      <Breadcrumbs />
      {isSuccessfulResponse(data?.campaign) && defaultValues && (
        <CampaignForm
          onCancel={() => navigate(`/${region}/merchandising`)}
          onPreview={() => navigate(`/${region}/merchandising/preview/${campaignId}`)}
          formTitle="Edit Merchandising Rule"
          onSubmit={handleSubmit}
          defaultValues={defaultValues}
        />
      )}
    </PageContainer>
  );
}
