import Backdrop from "@gfg/ui-v2/components/backdrop";
import { useCallback } from "react";
import { makeStyles } from "@gfg/ui-v2/theming";
import SidebarMenu from "./sidebar-menu";
import ProductLogo from "../components/product-logo";
import useScreenWidth from "../hooks/use-screen-width";

const useStyles = makeStyles<{ open: boolean }>()(({ breakpoints, palette, spacing }, { open }) => ({
  sidebar: {
    position: "fixed",
    top: 0,
    display: "grid",
    gridColumn: "sidebar-start / sidebar-end",
    gridRow: "content-start / content-end",
    gridTemplateRows: `
      [sidebar-start sidebar-top-start] 0fr
      [sidebar-top-end sidebar-middle-start] 1fr
      [sidebar-middle-end sidebar-bottom-start] 0fr [sidebar-bottom-end sidebar-end]`,
    height: "100vh",
    padding: spacing("sm", 0),
    borderRight: `1px solid ${palette.gray[200]}`,
    overflowY: "auto",
    wordBreak: "break-word",

    [breakpoints.up("sm")]: {
      paddingTop: spacing(70),
      width: open ? spacing(312) : "auto",
    },
    [breakpoints.down("xs")]: {
      borderRight: "none",
      bottom: 0,
      zIndex: 4,
      width: "70%",
      position: "absolute",
      left: open ? 0 : "-100%",
      transition: ".5s",
      backgroundColor: "inherit",
      padding: 0,
    },
  },
  backdrop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
  },
  logoContainer: {
    height: spacing(56),
    width: "100%",
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: `1px solid ${palette.tertiary[200]}`,
    marginBottom: spacing(20),

    [breakpoints.up("sm")]: {
      display: "none",
    },
  },
  productLogo: {
    height: spacing(32),
  },
}));

interface SidebarProps {
  open: boolean;
  onToggle(): void;
}

export default function Sidebar({ open, onToggle }: SidebarProps) {
  const { classes } = useStyles({ open });
  const { isMobile } = useScreenWidth();

  const toggleOnMobile = useCallback(() => {
    if (!isMobile) {
      return;
    }
    onToggle();
  }, [open]);

  return (
    <div className={classes.sidebar}>
      <div className={classes.logoContainer}>
        <ProductLogo className={classes.productLogo} />
      </div>
      <SidebarMenu open={open} onToggle={toggleOnMobile} />
      {open && isMobile && <Backdrop className={classes.backdrop} transparent={false} onClick={toggleOnMobile} />}
    </div>
  );
}
