import { Button } from "@gfg/ui-v2/components/button";
import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ colors, breakpoints, palette }) => ({
  container: {
    display: "flex",
    justifyContent: "end",
  },
  button: {
    width: "48%",

    [breakpoints.up("md")]: {
      width: 160,
    },
  },
  cancelBtn: {
    marginRight: "4%",
    color: colors.darkgray,
    border: `1px solid ${palette.tertiary[200]}`,
  },
}));

interface ModalActionsProps {
  submitBtnName: string;
  closeModal: () => void;
}

export default function ModalActions({ closeModal, submitBtnName }: ModalActionsProps) {
  const { classes, cx } = useStyles();

  return (
    <div className={classes.container}>
      <Button onClick={closeModal} className={cx(classes.button, classes.cancelBtn)} color="primary" variant="link">
        Cancel
      </Button>
      <Button type="submit" className={classes.button} variant="filled">
        {submitBtnName}
      </Button>
    </div>
  );
}
