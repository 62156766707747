import { makeStyles } from "@gfg/ui-v2/theming";

interface ProductCatalogTableProps {
  children: React.ReactNode;
}

const useStyles = makeStyles()(({ spacing, palette }) => ({
  container: {
    flex: 3,
    marginLeft: spacing(40),
    border: `1px solid ${palette.tertiary[200]}`,
    padding: spacing(24),
  },
}));

export default function ProductCatalogTable({ children }: ProductCatalogTableProps) {
  const { classes } = useStyles();

  return <div className={classes.container}>{children}</div>;
}
