import { useReactiveVar } from "@apollo/client";

import { useCallback } from "react";
import reactiveVariables from "../../../../../common/graphql/global-reactive-variables";
import ConditionalFilter from "../../../../../common/components/form-filters/conditional-filters";
import {
  useGetSearchConfigAttributesQuery,
  useGetSearchConfigAttributeValuesLazyQuery,
} from "../../../../../generated";
import { FilterAutocompleteMultiselectProps } from "../../../../../common/components/form-filters/dynamic-filter-component/filter-components/autocomplete-multiselect-filter";
import getAttributeName from "~/search-config/utils/get-attribute-name";

interface FeedFilterProps {
  disabled?: boolean;
}

interface FeedFilterProps {
  onAddFeedFilter?(): void;
  fieldName: string;
}

export default function SearchConfigConditionalFilter({
  onAddFeedFilter,
  fieldName,
  disabled = false,
}: FeedFilterProps) {
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const { data, loading } = useGetSearchConfigAttributesQuery({
    variables: { params: { attributeType: "ConditionFiltering", projectId: selectedEnvironmentProjectId } },
  });

  const [getSearchConfigAttributeValues] = useGetSearchConfigAttributeValuesLazyQuery();

  const getFacetValues: FilterAutocompleteMultiselectProps["getValues"] = useCallback(
    async ({ attributeId, limit, query }) => {
      const valuesResponse = await getSearchConfigAttributeValues({
        variables: {
          params: {
            attributeName: getAttributeName(attributeId),
            projectId: selectedEnvironmentProjectId,
            limit,
            query,
          },
        },
      });

      return { data: valuesResponse?.data?.searchConfigAttributeValues };
    },
    [getSearchConfigAttributeValues, selectedEnvironmentProjectId],
  );

  return (
    <ConditionalFilter
      allowDuplicates
      disabled={disabled}
      fieldName={fieldName}
      loading={loading}
      onAddFeedFilter={onAddFeedFilter}
      attributeOptions={data?.searchConfigAttributes || []}
      getFacetValues={getFacetValues}
    />
  );
}
