const date = {
  format(date: Date | string) {
    return new Date(date).toLocaleDateString("en-us", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
  },
  fromSecondsSinceEpoch(seconds: string | number) {
    return new Date(Number.parseInt(seconds as string, 10) * 1000);
  },
};

export default date;
