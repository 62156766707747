import { useCallback } from "react";
import { useReactiveVar } from "@apollo/client";
import { useDeleteSearchOptimizationMutation } from "~/generated";
import reactiveVariables from "~/common/graphql/global-reactive-variables";

function useDeleteSearchOptimization() {
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const [deleteSearchOptimization] = useDeleteSearchOptimizationMutation({
    refetchQueries: ["GetSearchOptimizations"],
  });

  return useCallback(
    async (id: string, query: string) =>
      await deleteSearchOptimization({
        variables: {
          input: {
            id,
            query,
            projectId: selectedEnvironmentProjectId,
          },
        },
      }),
    [deleteSearchOptimization],
  );
}

export default useDeleteSearchOptimization;
