import Header from "@gfg/ui-v2/components/header";
import { useReactiveVar } from "@apollo/client";
import { makeStyles } from "@gfg/ui-v2/theming";
import UserDropdown from "./user-dropdown";
import EnvironmentsDropdown from "./environments-dropdown";
import ProductLogo from "../components/product-logo";
import reactiveVariables from "../graphql/global-reactive-variables";

export const TEST_QA_ID = "c125627d-8633-41c0-bb75-23ca29899047";

const useStyles = makeStyles()(({ spacing, breakpoints, colors, palette }) => ({
  header: {
    position: "sticky",
    top: 0,
    zIndex: 2,
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "center",
    verticalAlign: "center",
    alignSelf: "center",
    color: colors.darkgray,

    [breakpoints.down("sm")]: {
      display: "none",
    },
  },
  buttonContainer: {
    borderRadius: "none",
    alignSelf: "center",
  },
  button: {
    "color": colors.gray,
    "&:focus": {
      boxShadow: "none!important",
      color: palette.gray[200],
    },
  },
  logoContainer: {
    width: spacing(194),
    lineHeight: 1,

    [breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  brandLogo: {
    textAlign: "center",
  },
  productLogo: {
    height: spacing(32),

    [breakpoints.down("md")]: {
      display: "none",
    },
  },
  productLogoMobile: {
    [breakpoints.up("lg")]: {
      display: "none",
    },
  },
}));

export interface HeaderPanelProps {
  onToggle(): void;
}

export default function CustomisedHeader({ onToggle }: HeaderPanelProps) {
  const { classes, cx } = useStyles();

  const isDev = process.env.NODE_ENV !== "production";
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const isTestQaEnvSelected = selectedEnvironmentProjectId === TEST_QA_ID;

  return (
    <Header
      logo={
        <div className={cx(classes.logoContainer, classes.brandLogo)}>
          <img src="/gfg-logo.svg" alt="logo" className={classes.productLogo} />
          <ProductLogo className={classes.productLogoMobile} />
        </div>
      }
      onToggle={onToggle}
      className={classes.header}
    >
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <div className={cx(classes.logoContainer)}>
          <ProductLogo className={classes.productLogo} />
        </div>
        {isDev && (
          <span style={{ color: "white", fontSize: 24, background: isTestQaEnvSelected ? "green" : "red" }}>
            {isTestQaEnvSelected ? "DEVELOPMENT build" : "DEVELOPMENT build -- prod env"}
          </span>
        )}
      </div>

      <div className={classes.actionsContainer}>
        <EnvironmentsDropdown />
        <UserDropdown />
      </div>
    </Header>
  );
}
