import { useReactiveVar } from "@apollo/client";
import { useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetSearchOptimizationQuery, SearchOptimization } from "@coral/typings";
import Breadcrumbs from "../../../common/components/breadcrumbs";
import { useGetSearchOptimizationQuery } from "../../../generated";
import SearchOptimizationForm, { FormData } from "../../components/optimizations-form";
import reactiveVariables from "../../../common/graphql/global-reactive-variables";
import useSubmit from "./use-submit";
import PageContainer from "../../../common/components/containers/page-container";

export function isSuccessfulResponse(
  fetchResponse: GetSearchOptimizationQuery["searchOptimization"] | undefined,
): fetchResponse is SearchOptimization {
  return fetchResponse?.__typename === "SearchOptimization";
}

export default function EditSearchOptimizationPage() {
  const navigate = useNavigate();
  const submit = useSubmit();

  const { optimizationId, region } = useParams();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const { data } = useGetSearchOptimizationQuery({
    variables: {
      params: {
        id: optimizationId,
        projectId: selectedEnvironmentProjectId,
      },
    },
  });

  const handleSubmit = useCallback(
    async (formData: FormData) => {
      await submit(optimizationId, formData);
    },
    [optimizationId, submit],
  );

  const defaultValues = useMemo<FormData>(() => {
    if (isSuccessfulResponse(data?.searchOptimization)) {
      return {
        output: data?.searchOptimization.output,
        query: data?.searchOptimization.query,
        state: data?.searchOptimization.state,
        createdAt: data?.searchOptimization.createdAt,
      };
    }
  }, [data]);

  return (
    <PageContainer>
      <Breadcrumbs />
      {isSuccessfulResponse(data?.searchOptimization) && defaultValues && (
        <SearchOptimizationForm
          onCancel={() => navigate(`/${region}/search/optimizations`)}
          formTitle="Update Search Optimization"
          onSubmit={handleSubmit}
          defaultValues={defaultValues}
          queryFieldDisabled
        />
      )}
    </PageContainer>
  );
}
