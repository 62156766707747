import { TableCell } from "@gfg/ui-v2/components/table";
import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ spacing, breakpoints }) => ({
  cell: {
    "textAlign": "right",
    "& div": {
      justifyContent: "end",
    },
    "& svg": {
      [breakpoints.down("md")]: {
        marginRight: spacing(5),
        marginLeft: spacing(5),
      },
    },
  },

  cellContent: {
    "display": "flex",
    "justifyContent": "flex-end",
    "& button": {
      marginRight: spacing(10),
    },
  },
}));

interface ActionCellWithIconsProps {
  children: React.ReactNode;
}

export default function ActionCellWithIcons({ children }: ActionCellWithIconsProps) {
  const { classes } = useStyles();

  return (
    <TableCell className={classes.cell}>
      <div className={classes.cellContent}>{children}</div>
    </TableCell>
  );
}
