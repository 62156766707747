import { CustomFeedState } from "@coral/typings";

interface CustomFeedStateMapInterface {
  Active: CustomFeedState;
  Testing: CustomFeedState;
  Draft: CustomFeedState;
}

const CustomFeedStateMap: CustomFeedStateMapInterface = {
  Active: "Active",
  Testing: "Testing",
  Draft: "Draft",
};

export default CustomFeedStateMap;
