import { KeyboardEvent } from "react";
import { IconButton } from "@gfg/ui-v2/components/button";
import { InputField } from "@gfg/ui-v2/components/input";
import { Edit3Icon, Trash2Icon, CheckIcon, PlusIcon } from "@gfg/ui-v2/icons";
import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ spacing, palette }) => ({
  container: {
    "display": "flex",
    "justifyContent": "flex-end",
    "& button": {
      marginRight: spacing(10),
    },
    "marginLeft": spacing(15),
  },
  icon: {
    background: palette.secondary[100],
  },
  synonymsInput: {
    width: "100%",
  },
}));

export interface ActionsProps {
  onEdit(): void;
  editMode: boolean;
  unsavedChanges: boolean;
  onDelete(): void;
  onSynonymsInputChange(synonyms: string): void;
  synonymsInput: string;
  onAddSynonyms(): void;
  onSave(): void;
  errorMessage?: string;
}

export default function Actions({
  onDelete,
  onEdit,
  onSave,
  editMode,
  synonymsInput,
  onSynonymsInputChange: onSynonymsChange,
  onAddSynonyms,
  unsavedChanges,
  errorMessage,
}: ActionsProps) {
  const { classes } = useStyles();

  const SaveButton = (
    <IconButton
      onClick={onSave}
      disabled={errorMessage !== undefined}
      color={unsavedChanges ? "success" : "primary"}
      size="small"
      icon={<CheckIcon color="white" />}
    />
  );

  const AddButton = (
    <IconButton onClick={onAddSynonyms} color="primary" size="small" icon={<PlusIcon color="white" />} />
  );

  return (
    <div className={classes.container}>
      {!editMode && (
        <>
          <IconButton onClick={onEdit} icon={Edit3Icon} round variant="light" className={classes.icon} />
          <IconButton onClick={onDelete} icon={Trash2Icon} round variant="light" className={classes.icon} />
        </>
      )}
      {editMode && (
        <InputField
          autoFocus
          value={synonymsInput}
          onChange={onSynonymsChange}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
            if (e.key === "Enter") {
              onAddSynonyms();
            }
          }}
          length={50}
          valid={errorMessage !== undefined ? false : undefined}
          invalidHint={errorMessage}
          className={classes.synonymsInput}
          append={synonymsInput ? AddButton : SaveButton}
          placeholder="New synonyms"
        />
      )}
    </div>
  );
}
