import { useCallback } from "react";
import { useLoginMutation } from "../../generated";

export default function useLogin() {
  const [doLogin] = useLoginMutation();

  return useCallback(
    async (token: string) => {
      const { data } = await doLogin({ variables: { token } });

      return data?.login;
    },
    [doLogin],
  );
}
