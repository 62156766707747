/* eslint-disable unicorn/no-thenable */
import { Role } from "@coral/typings";
import * as yup from "yup";

export default yup.object().shape({
  email: yup.string().email("Please insert a valid email").nullable().required("Email is required"),
  // .test("yourTestCondition", "email must belong to GFG group", value => value?.includes("@global-fashion-group.com") ?? false),
  role: yup.string().nullable().required("Please select a Role for the User"),
  resources: yup
    .array()
    .min(1, "Please select at least one resource for the User")
    .required("Please select at least one resource for the User"),
  environments: yup.array().when("role", {
    is: (role: Role) => role !== "master",
    then: yup
      .array()
      .min(1, "Please select at least one environment for the User")
      .required("Please select at least one environment for the User"),
    otherwise: yup.array().nullable(),
  }),
});
