import { IconButton } from "@gfg/ui-v2/components/button";
import { SelectField, Option } from "@gfg/ui-v2/components/select";
import { Trash2Icon } from "@gfg/ui-v2/icons";
import { InputField } from "@gfg/ui-v2/components/input";
import { useMemo } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

import { DataInputType, AvailableProductAttribute } from "@coral/typings";
import { AttributeFilterWithRuleFormValue } from "..";
import useStyles from "./use-styles";
import DynamicFilterComponent from "../../dynamic-filter-component";
import { FilterAutocompleteMultiselectProps } from "../../dynamic-filter-component/filter-components/autocomplete-multiselect-filter";

interface AttributeFilterWithRulesFieldProps {
  fieldName: string;
  index: number;
  disabled: boolean;
  onRemoveFilter(index: number): void;

  attributeOptions: AvailableProductAttribute[];
  getFacetValues: FilterAutocompleteMultiselectProps["getValues"];
}

const DEFAULT_ATTRIBUTE_TYPE: DataInputType = "text";

export default function AttributeFilterWithRulesField({
  fieldName,
  index,
  onRemoveFilter,
  attributeOptions,
  disabled,
  getFacetValues,
}: AttributeFilterWithRulesFieldProps) {
  const { classes } = useStyles();
  const { control, setValue, getFieldState } = useFormContext();

  const fieldData = useWatch({
    control,
    name: `${fieldName}.${index}`,
  }) as AttributeFilterWithRuleFormValue;

  const createTypeChangeHandler = (onChange: (type: string) => void) => (type: string) => {
    onChange(type);
    setValue(`${fieldName}.${index}.value`, "");
    setValue(`${fieldName}.${index}.rule`, "");
  };

  const createRuleChangeHandler = (onChange: (rule: string) => void) => (rule: string) => {
    onChange(rule);
    if (rule === "") {
      setValue(`${fieldName}.${index}.value`, "");
    }
  };

  const selectedAttribute = useMemo(
    () => attributeOptions.find(a => a.value === fieldData.type),
    [attributeOptions, fieldData.type],
  );
  const selectedRule = useMemo(
    () => selectedAttribute?.rules.find(r => r.value === fieldData.rule),
    [selectedAttribute, fieldData.rule],
  );

  const currentComponentType = selectedRule?.type ?? DEFAULT_ATTRIBUTE_TYPE;

  const ruleOptions = selectedAttribute?.rules ?? [];

  const typeError = getFieldState(`${fieldName}.${index}.type`).error;
  const weightError = getFieldState(`${fieldName}.${index}.weight`).error;
  const ruleError = getFieldState(`${fieldName}.${index}.rule`).error;
  const valueError = getFieldState(`${fieldName}.${index}.value`).error;

  return (
    <div className={classes.wrapper}>
      <div>
        <div className={classes.container}>
          <div className={classes.item}>
            <Controller
              name={`${fieldName}.${index}.type`}
              control={control}
              render={({ field }) => (
                <SelectField
                  {...field}
                  onChange={createTypeChangeHandler(field.onChange)}
                  onBlur={field.onBlur}
                  disabled={disabled}
                  placeholder="Select attribute"
                  label="Type"
                  valid={typeError ? false : undefined}
                  invalidHint={typeError?.message?.toString()}
                  required
                >
                  {attributeOptions.map(option => (
                    <Option
                      key={option.value}
                      text={option.label || option.value}
                      value={option.value}
                      disabled={disabled}
                    >
                      {option.label || option.value}
                    </Option>
                  ))}
                </SelectField>
              )}
            />
          </div>
          <div className={classes.item}>
            <Controller
              name={`${fieldName}.${index}.weight`}
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  type="number"
                  min="0.01"
                  step={0.01}
                  autoFocus={false}
                  disabled={disabled}
                  label="Weight"
                  valid={weightError ? false : undefined}
                  invalidHint={weightError?.message?.toString()}
                  required
                />
              )}
            />
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.item}>
            <Controller
              name={`${fieldName}.${index}.rule`}
              control={control}
              render={({ field }) => (
                <SelectField
                  {...field}
                  onChange={createRuleChangeHandler(field.onChange)}
                  disabled={disabled || !fieldData.type}
                  label="Rule"
                  placeholder="Select rule"
                  valid={ruleError ? false : undefined}
                  invalidHint={ruleError?.message?.toString()}
                >
                  <Option text="Empty" value="" disabled={disabled}>
                    --empty--
                  </Option>
                  {ruleOptions.map(option => (
                    <Option key={option.value} text={option.label} value={option.value} disabled={disabled}>
                      {option.label}
                    </Option>
                  ))}
                </SelectField>
              )}
            />
          </div>
          <div className={classes.item}>
            <Controller
              name={`${fieldName}.${index}.value`}
              control={control}
              render={({ field: { onChange, value, onBlur } }) => (
                <DynamicFilterComponent
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  component={currentComponentType}
                  disabled={!fieldData.type || !fieldData.rule || disabled}
                  label="Values"
                  required={false}
                  valid={valueError ? false : undefined}
                  invalidHint={valueError?.message?.toString()}
                  getValues={getFacetValues}
                  attributeId={selectedAttribute?.id}
                />
              )}
            />
          </div>
        </div>
      </div>
      <IconButton
        disabled={disabled}
        type="button"
        icon={Trash2Icon}
        round
        variant="light"
        className={classes.icon}
        onClick={() => onRemoveFilter(index)}
      />
    </div>
  );
}
