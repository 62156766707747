import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(theme => ({
  autocomplete: {
    "& [class^='box']": {
      flexDirection: "row",
      flexWrap: "wrap",
    },

    "& input": {
      flexGrow: 1,
      width: "initial",
    },
  },
  clear: {
    "marginRight": 0,
    "marginLeft": "auto",

    "& button": {
      marginLeft: theme.spacing(12),
    },
  },
  tags: {
    flexWrap: "wrap",
    gridGap: theme.spacing("xs"),
  },
}));

export default useStyles;
