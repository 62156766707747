import { WeightFilterInput } from "@coral/typings";
import { AttributeFilterFormValue } from "../../components/form-filters/attribute-filters";

function serializeAttributeFilter(attribute: AttributeFilterFormValue): WeightFilterInput {
  return {
    id: attribute.id,
    fieldName: attribute.type,
    weight: attribute.weight,
  };
}

export default serializeAttributeFilter;
