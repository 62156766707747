import React, { useCallback } from "react";
import { TableBody, TableCell, TableRow } from "@gfg/ui-v2/components/table";
import { CustomFeed } from "@coral/typings";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IconButton } from "@gfg/ui-v2/components/button";
import Tooltip from "@gfg/ui-v2/components/tooltip";
import { ApolloError, useReactiveVar } from "@apollo/client";

import SuccessStatusTag from "common/components/status-tags/success-tag";
import GrayStatusTag from "common/components/status-tags/gray-tag";
import { CustomFeedStateTranslations } from "common/translations";
import extractGraphqlError from "common/utils/apollo/extract-graphql-error";
import { makeStyles } from "@gfg/ui-v2/theming";
import ActionCell from "./cells/action-cell";
import TitleSubtitleCell from "../../../common/components/table/cells/title-subtitle-cell";
import date from "../../../common/services/dates";
import useCopyClipboard from "../../../common/hooks/use-copy-clipboard";
import { useDeleteNewsletterMutation } from "../../../generated";
import useAppNotifications from "../../../common/hooks/use-app-notifications";
import useEventTracking from "../../../common/hooks/use-tracking-event";
import reactiveVariables from "../../../common/graphql/global-reactive-variables";

interface AdminNewsletterListProps {
  newsletters: CustomFeed[] | null;
}

const useStyles = makeStyles()(({ colors, spacing, palette, breakpoints }) => ({
  rowGrey: {
    background: palette.gray[100],
  },
  rowLight: {
    background: colors.background,
  },
  limitedCell: {
    maxWidth: 240,
    paddingRight: spacing(38),

    [breakpoints.down("md")]: {
      maxWidth: "initial",
      paddingRight: 0,
    },
  },
  iconButton: {
    height: 0,
    paddingLeft: 0,
  },
}));

export default function NewsletterList({ newsletters }: AdminNewsletterListProps) {
  const { classes } = useStyles();
  const { addSuccessNotification, addErrorNotification, addInProgressNotification, removeNotification } =
    useAppNotifications();
  const { copyToClipboard } = useCopyClipboard();
  const sendDuplicateButtonAnalyticsEvent = useEventTracking({ eventName: "recos-nl-duplicate" });
  const sendCopyButtonAnalyticsEvent = useEventTracking({ eventName: "recos-nl-copy-url" });
  const sendCopyIdButtonAnalyticsEvent = useEventTracking({ eventName: "recos-nl-copy-id" });
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const [deleteNewsletter] = useDeleteNewsletterMutation({
    refetchQueries: ["GetNewsletters"],
  });
  const navigate = useNavigate();
  const { region } = useParams();

  const handleCopyToClipboard = useCallback(async (text: string, value: string) => {
    const response = await copyToClipboard(value);
    if (response) {
      addSuccessNotification(`Copied ${text} to clipboard`, value);
    }
  }, []);

  async function handleDeleteNewsletter(id: string) {
    let notificationId;

    try {
      notificationId = addInProgressNotification("Deleting newsletter");

      await deleteNewsletter({
        refetchQueries: ["GetNewsletters"],
        variables: {
          params: {
            id,
            projectId: selectedEnvironmentProjectId,
          },
        },
      });

      addSuccessNotification("Config was deleted successfully");
      return;
    } catch (error) {
      const message = extractGraphqlError(error as ApolloError);
      addErrorNotification("You do not have access to delete this config", message);
    } finally {
      if (notificationId) {
        removeNotification(notificationId);
      }
    }
  }
  const createStatusTag = (feed: CustomFeed) =>
    feed.state === "Active" ? (
      <SuccessStatusTag label={CustomFeedStateTranslations[feed.state]} />
    ) : (
      <GrayStatusTag label={CustomFeedStateTranslations[feed.state]} />
    );

  return (
    <TableBody className="tableBody">
      {newsletters?.map((newsletter, index) => (
        <TableRow key={newsletter.id} className={index % 2 === 0 ? classes.rowGrey : classes.rowLight}>
          <TitleSubtitleCell
            title={
              <Link to={`/${region}/recommendations/newsletter/edit/${newsletter.id}`}>
                {newsletter.name.slice(0, 60)}
              </Link>
            }
            subtitle={
              <Tooltip
                triggerElement={
                  <IconButton
                    onClick={() => {
                      handleCopyToClipboard("Config Id: ", newsletter.id);
                      sendCopyIdButtonAnalyticsEvent();
                    }}
                    color="gray"
                    icon={<span>{newsletter.id}</span>}
                    variant="link"
                    className={classes.iconButton}
                  />
                }
                variant="dark"
              >
                Click to copy Config Id
              </Tooltip>
            }
          />
          <TitleSubtitleCell
            title={newsletter.updated_by}
            subtitle={newsletter.updated_at && date.format(date.fromSecondsSinceEpoch(newsletter.updated_at))}
          />
          <TableCell>
            <div>
              <span>{createStatusTag(newsletter)}</span>
            </div>
          </TableCell>

          <ActionCell
            copyToClipboard={() => {
              handleCopyToClipboard("Config URL: ", newsletter.url || "");
              sendCopyButtonAnalyticsEvent();
            }}
            deleteNewsletter={() => handleDeleteNewsletter(newsletter.id)}
            onEdit={() => navigate(`/${region}/recommendations/newsletter/edit/${newsletter.id}`)}
            onDuplicate={() => {
              navigate(`/${region}/recommendations/newsletter/duplicate/${newsletter.id}`);
              sendDuplicateButtonAnalyticsEvent();
            }}
          />
        </TableRow>
      ))}
    </TableBody>
  );
}
