import { useMemo } from "react";
import { useReactiveVar } from "@apollo/client";
import reactiveVariables from "common/graphql/global-reactive-variables";
import { useGetEnvironmentsQuery } from "generated";
import useViewer from "auth/hooks/use-viewer";

const useDefaultRegion = () => {
  const { data, loading } = useGetEnvironmentsQuery();
  const { viewer } = useViewer();

  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const defaultRegion = useMemo(() => {
    if (!loading) {
      if (data?.configuration?.environments && selectedEnvironmentProjectId) {
        const selectedEnv = data?.configuration?.environments.find(e => e.id === selectedEnvironmentProjectId);
        if (selectedEnv) {
          return selectedEnv.key;
        }
      }

      if (viewer?.environments.length > 0) {
        return viewer.environments[0].key;
      }
    }
  }, [data, selectedEnvironmentProjectId, viewer, loading]);

  return defaultRegion;
};

export default useDefaultRegion;
