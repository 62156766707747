import { ApolloError } from "@apollo/client";
import { CustomFeedPreviewQuery, Product } from "@coral/typings";
import CircularProgress from "@gfg/ui-v2/components/circular-progress";
import { AlertCircleIcon, ArrowRightIcon } from "@gfg/ui-v2/icons";
import { makeStyles } from "@gfg/ui-v2/theming";
import ErrorMessage from "../error-message";
import CustomSwiper from "../swiper/swiper";
import RecommendationsPreviewItem from "./recommendations-preview-item";
import makeImage from "./utils/make-image";
import makeItems from "./utils/make-items";
import RecommendationsPreviewSeedItem from "./recommendations-preview-seed-item";

const useStyles = makeStyles<{ seedProduct: boolean }>()(({ spacing, colors }, { seedProduct }) => ({
  container: {
    minHeight: spacing(237),
    display: "flex",
    justifyContent: "space-between",
    gap: spacing(20),
    alignItems: "top",
    minWidth: 0,
  },
  seedProductContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "top",
    alignItems: "center",
    width: "15%",
  },
  icon: {
    color: colors.gray,
  },
  drawerContainer: {
    minHeight: spacing(237),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: spacing(10),
  },
  warningMessage: {
    color: colors.gray,
  },
  errorMessage: {
    fontSize: 18,
  },
  arrowContainer: {
    display: "flex",
    alignItems: "center",
    width: "5%",
  },
  swiperContainer: {
    position: "relative",
    width: seedProduct ? "80%" : "100%",
  },
  descriptionSpan: {
    fontSize: 15,
    fontWeight: "bold",
  },
}));

interface RecommendationPreviewBodyProps {
  loading: boolean;
  error: ApolloError;
  data: CustomFeedPreviewQuery;

  seedProduct?: Product;

  strategyName: string;
}

export default function RecommendationsPreviewBody({
  strategyName,
  seedProduct,
  loading,
  error,
  data,
}: RecommendationPreviewBodyProps) {
  const { classes } = useStyles({ seedProduct: !!seedProduct });

  if (loading) {
    return (
      <div className={classes.drawerContainer}>
        <CircularProgress width={32} height={32} strokeWidth={2} />
      </div>
    );
  }

  if (error) {
    return (
      <div className={classes.drawerContainer}>
        <ErrorMessage className={classes.errorMessage} message={error.message} />
      </div>
    );
  }

  if (data?.customFeedPreview && data.customFeedPreview.status === "error") {
    return (
      <div className={classes.drawerContainer}>
        <ErrorMessage className={classes.errorMessage} message={data.customFeedPreview.message} />
      </div>
    );
  }

  if (!data?.customFeedPreview?.items || data?.customFeedPreview?.items.length === 0) {
    return (
      <div className={classes.drawerContainer}>
        <AlertCircleIcon className={classes.icon} height={32} width={32} />
        <h5 className={classes.warningMessage}>No product found for this feed configuration.</h5>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      {seedProduct && (
        <>
          <div className={classes.seedProductContainer}>
            <span className={classes.descriptionSpan}>Source SKU: {seedProduct?.skus[0]}</span>
            <RecommendationsPreviewSeedItem img={seedProduct?.imagePath} />
            <span className={classes.descriptionSpan}>{seedProduct?.title}</span>
          </div>
          <div className={classes.arrowContainer}>
            <ArrowRightIcon />
          </div>
        </>
      )}
      <div className={classes.swiperContainer}>
        <span className={classes.descriptionSpan}>{strategyName}</span>
        <CustomSwiper>
          {data.customFeedPreview?.items?.map(item => (
            <RecommendationsPreviewItem items={makeItems(item)} img={makeImage(item.images)} />
          ))}
        </CustomSwiper>
      </div>
    </div>
  );
}
