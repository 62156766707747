import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(theme => ({
  container: {
    boxShadow: theme.shadow("md"),
  },
}));

interface ContainerShadowProps {
  children: React.ReactNode;
  className?: string;
}

export default function ContainerShadow({ children, className }: ContainerShadowProps) {
  const { classes, cx } = useStyles();

  return <div className={cx(classes.container, className)}>{children}</div>;
}
