import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApolloError, useReactiveVar } from "@apollo/client";

import { Campaign } from "@coral/typings";
import extractGraphqlError from "common/utils/apollo/extract-graphql-error";
import { useCreateCampaignMutation } from "../../../generated";
import { FormData } from "../../components/campaign-form";
import useAppNotifications from "~/common/hooks/use-app-notifications";
import reactiveVariables from "~/common/graphql/global-reactive-variables";
import serializeAttributeFilterWithRule from "~/common/utils/search-config/serialize-attribute-filter-with-rule";
import serializeConditionalFilter from "~/common/utils/search-config/serialize-conditional-filter";

function useSubmit() {
  const { addSuccessNotification, addErrorNotification } = useAppNotifications();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const navigate = useNavigate();
  const { region } = useParams();

  const [createCampaign] = useCreateCampaignMutation({
    refetchQueries: ["GetCampaigns"],
  });

  return useCallback(
    async (formData: FormData) => {
      try {
        const { data } = await createCampaign({
          variables: {
            params: {
              campaignName: formData.campaignName,
              value: formData.value,
              hardBoostedSkus: formData.boostedSkus,
              projectId: selectedEnvironmentProjectId,
              rankingAttributes: formData.rankAttributes.map(serializeAttributeFilterWithRule),
              filterAttributes: formData.conditionFilters.map(serializeConditionalFilter),
              type: formData.type,
            },
          },
        });

        const campaign = data?.createCampaign as Campaign;

        addSuccessNotification("Merchandising rule was created successfully");
        navigate(`/${region}/merchandising/edit/${campaign.id}`);
      } catch (error) {
        const message = extractGraphqlError(error as ApolloError);
        addErrorNotification("Error while creating Merchandising Rule", message);
      }
    },
    [navigate, createCampaign],
  );
}

export default useSubmit;
