import { FilterAttribute } from "@coral/typings";
import { isNumber, isString } from "lodash";
import { ConditionalFilterFormValue } from "../../components/form-filters/conditional-filters";

function getDeserializedValue(attribute: FilterAttribute) {
  if (Array.isArray(attribute.strArrValue)) {
    return attribute.strArrValue;
  }

  if (isNumber(attribute.numValue)) {
    return attribute.numValue;
  }

  if (isString(attribute.strValue)) {
    return attribute.strValue;
  }

  return ""; // default empty value
}

function deserializeConditionalFilter(attribute: FilterAttribute): ConditionalFilterFormValue {
  return {
    id: attribute.id,
    rule: attribute.rule || "",
    type: attribute.fieldName,
    value: getDeserializedValue(attribute),
  };
}

export default deserializeConditionalFilter;
