import { InputField } from "@gfg/ui-v2/components/input";

export interface FilterTextProps {
  value: string;
  onChange(value: string): void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;

  disabled?: boolean;
  required?: boolean;

  label?: React.ReactNode;

  invalidHint?: string;
  valid?: boolean;
}

export default function FilterText({
  value,
  onChange,
  onBlur,
  label,
  disabled = false,
  required = true,
  invalidHint,
  valid,
}: FilterTextProps) {
  return (
    <InputField
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      placeholder="Choose the values"
      type="text"
      disabled={disabled}
      variant="outline"
      label={label}
      required={required}
      invalidHint={invalidHint}
      valid={valid}
      block
    />
  );
}
