import React from "react";
import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ spacing, colors, breakpoints }) => ({
  inputFieldLabelWrapper: {
    width: spacing(450),
    margin: 0,
    paddingRight: spacing(20),
  },
  inputFieldLabel: {
    color: colors.darkgray,
  },
  selectField: {
    "display": "flex",

    "& input": {
      width: spacing(480),
    },

    [breakpoints.down("xs")]: {
      "flexWrap": "wrap",

      "& input": {
        width: spacing(220),
      },
    },
  },
  label: {
    margin: 0,
  },
}));

interface SectionLabelProps {
  title: string;
  description?: string | React.ReactNode;
}

export default function SectionLabel({ title, description }: SectionLabelProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.inputFieldLabelWrapper}>
      <h6 className={classes.label}>{title}</h6>
      {description && (
        <label className={classes.inputFieldLabel} htmlFor={title}>
          {description}
        </label>
      )}
    </div>
  );
}
