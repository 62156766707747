import React from "react";
import { GlobalStyles, ThemeProvider, createTheme } from "@gfg/ui-v2/theming";
import { Theme } from "@gfg/ui-v2/theming/create-theme";
import { defaultColorSchemes, makeColorScheme } from "@gfg/ui-v2/theming/schemes";

const lightTheme = makeColorScheme("light", {
  primary: "#216FB3",
  secondary: "#0086C9",
  darkgray: "#344054",
  tertiary: "#ADADAD",
  gray: "#aaafb1",
  text: "#101828",
  background: "#ffffff",
  success: "#12B76A",
  error: "#bc3d00",
  warning: "#ffd53d",
});

const schemes = {
  ...defaultColorSchemes,
  light: lightTheme,
};

export const theme = createTheme({
  shadow: {
    sm: "0 1px 3px 0 rgba(0, 0, 0, .1)",
  },
  breakpoints: {
    xxl: 1576,
    xs: 480,
  },
  spacing: {
    xxl: 40,
  },
  typography: {
    fontFamilies: {
      base: "Inter",
      heading: "Inter",
      mono: "Roboto Mono",
    },
    fontWeights: {
      medium: 400,
      semiBold: 600,
    },
    label: {
      fontSize: 14,
    },
    h1: {
      fontSize: 60,
      fontWeight: 600,
    },
    h3: {
      fontSize: 30,
      fontWeight: 600,
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
    },
    h5: {
      fontSize: 18,
      fontWeight: 600,
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
    },
    small: {
      fontSize: 14,
    },
  },
  schemes,
});

const globalStyles = (theme: Theme) => ({
  a: {
    textDecoration: "none",
    color: theme.colors.primary,
  },
});

export function GlobalStylesV2() {
  return <GlobalStyles overrides={globalStyles} />;
}

export default function ThemeProviderV2(props: React.PropsWithChildren<{}>) {
  return <ThemeProvider theme={theme} {...props} />;
}
