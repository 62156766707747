import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { makeStyles } from "@gfg/ui-v2/theming";
import LoginContent from "./login-content";
import { useAuth } from "../auth-provider";

const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `0 ${theme.spacing(15)}`,
    height: "calc(100vh - 75px)",
    maxHeight: "100vh",
    [theme.breakpoints.up("xl")]: {
      height: "auto",
      flex: 1,
    },
  },
  logo: {
    width: 220,
  },
  datajetLogo: {
    marginTop: theme.spacing(70),
    [theme.breakpoints.up("xl")]: {
      marginTop: 0,
      flex: 1,
    },
  },
  gfgLogo: {
    marginBottom: theme.spacing(45),
  },
  logoLink: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    flex: 1,
  },
}));

export default function LoginSection() {
  const { classes, cx } = useStyles();
  const { viewer } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (viewer) {
      navigate("/", { replace: true });
    }
  }, [viewer]);

  return (
    <div className={classes.container}>
      <img className={cx(classes.logo, classes.datajetLogo)} src="/datajet-logo.svg" alt="Datajet logo" />
      <LoginContent />
      <a href="https://global-fashion-group.com/" target="_blank" rel="noreferrer" className={classes.logoLink}>
        <img className={cx(classes.logo, classes.gfgLogo)} src="/gfg-logo.svg" alt="Datajet logo" />
      </a>
    </div>
  );
}
