import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";

import { useGetDatadogConfigurationQuery } from "../../../generated";
import { useAuth } from "../../../auth/auth-provider";

export default function useDatadog() {
  const { viewer } = useAuth();
  const datadogConfig = useGetDatadogConfigurationQuery();

  const isProd = process.env.NODE_ENV === "production";

  useEffect(() => {
    if (isProd && viewer && datadogConfig.data?.configuration?.datadog) {
      const { applicationId, clientToken, service, site } = datadogConfig.data.configuration.datadog;

      datadogRum.init({
        applicationId,
        clientToken,
        site,
        service,
        env: process.env.NODE_ENV,
        // @ts-ignore
        version: `${__COMMIT_HASH__}`,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        allowedTracingUrls: [/https:\/\/(?:.*\.)?datajet\.io/],
        excludedActivityUrls: [],
        defaultPrivacyLevel: "mask-user-input",
      });

      datadogRum.startSessionReplayRecording();
      datadogRum.setUser({ id: viewer.id, email: viewer.email, name: viewer.name });
    }
  }, [datadogConfig, viewer]);
}
