import TableHeader from "common/components/table/table-header";
import useGetCampaigns from "campaigns/hooks/use-get-campaigns";
import { makeStyles } from "@gfg/ui-v2/theming";
import { useTableState } from "@gfg/ui-v2/components/data-table";
import Title from "~/common/components/title";
import useColumns, { Column } from "~/campaigns/hooks/use-columns";
import MerchandisingTable from "~/campaigns/components/data-table";
import useHandleCampaigns from "~/campaigns/hooks/use-handle-campaigns";

const useStyles = makeStyles()(theme => ({
  container: {
    margin: theme.spacing(20, 16),
  },
  tableContainer: {
    "boxShadow": theme.shadow("md"),
    "& .tableBody tr": {
      borderTopColor: "transparent",
    },
  },
  heading: {
    marginBottom: theme.spacing("md"),
  },
  headerCell: {
    color: theme.colors.text,
    background: theme.colors.background,
  },
}));

export default function CampaignListPage() {
  const { classes } = useStyles();
  const columns: Column[] = useColumns();

  const { campaigns, loading, limit, setLimit, offset, setOffset, searchInput, setSearchInput } = useGetCampaigns();
  const [state, onSort] = useTableState(columns, u => u?.id);

  const { onDuplicateCampaign, onRemoveCampaign, onEditCampaign, onCreateCampaign } = useHandleCampaigns();

  return (
    <div className={classes.container}>
      <Title className={classes.heading} text="Merchandising" />

      <div className={classes.tableContainer}>
        <TableHeader
          total={campaigns?.total}
          onSearchInputChange={setSearchInput}
          searchInput={searchInput}
          subtitle="Merchandising Rules"
          searchInputPlaceholder="Search"
          actionButtonLabel="Create new campaign"
          onActionButtonClick={onCreateCampaign}
        />
        <MerchandisingTable
          state={state}
          columns={columns}
          onDuplicateCampaign={onDuplicateCampaign}
          onSort={onSort}
          loading={loading}
          limit={limit}
          offset={offset}
          onEditCampaign={onEditCampaign}
          onRemoveCampaign={onRemoveCampaign}
          total={campaigns?.total}
          onLimitChange={setLimit}
          onOffsetChange={setOffset}
          campaigns={campaigns?.data}
        />
      </div>
    </div>
  );
}
