import { SearchOptimization, SearchOptimizationState } from "@coral/typings";
import { useCallback } from "react";
import { TableRowAction, TableRowActionProps } from "@gfg/ui-v2/components/table";
import { CheckSquareIcon, Edit3Icon, MoreHorizontalIcon, Trash2Icon } from "@gfg/ui-v2/icons";
import DropdownActionButton, { AvailableOption } from "~/common/components/buttons/dropdown-action-button";
import { IconButton } from "@gfg/ui-v2/components/button";
import Dropdown, { DropdownItem } from "@gfg/ui-v2/components/dropdown";

export type ActionsRow = "edit" | "delete" | "change-status";
const dropdownOptions: AvailableOption<SearchOptimizationState>[] = [
  {
    label: "Enable",
    value: "Enabled",
  },
  {
    label: "Reject",
    value: "Disabled",
  },
  {
    label: "Pending",
    value: "Pending",
  },
];

export function useTableRowActions(onAction: (action: ActionsRow, row: SearchOptimization) => void) {
  return useCallback(
    (_: SearchOptimization[], row: SearchOptimization): React.ReactElement<TableRowActionProps>[] | null => [
      <TableRowAction key={`${row.id}-edit`} icon={Edit3Icon} value="edit" onClick={() => onAction("edit", row)}>
        Edit Version
      </TableRowAction>,
      <TableRowAction key={`${row.id}-delete`} icon={Trash2Icon} value="delete" onClick={() => onAction("delete", row)}>
        Delete Version
      </TableRowAction>,
      <TableRowAction
        key={`${row.id}-change-status`}
        icon={CheckSquareIcon}
        value="change-status"
        onClick={() => onAction("change-status", row)}
      >
        Change status
      </TableRowAction>,
    ],
    [],
  );
}
