import { Tag } from "@gfg/ui-v2/components/tag";
import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ spacing, colors, palette, typography }) => ({
  container: {
    display: "flex",
    justifyContent: "start",
    height: "44px",
  },
  subtitle: {
    color: colors.text,
    fontSize: typography.small.fontSize,
    alignSelf: "center",
    fontWeight: "bolder",
  },
  tag: {
    border: "none",
    height: spacing(22),
    fontWeight: typography.fontWeights.medium,
    background: palette.secondary[100],
    color: colors.primary,
    fontSize: typography.label.fontSize,
    marginLeft: spacing(8),
    alignSelf: "center",
  },
}));

interface SearchResultProps {
  total: number;
  subtitle: string;
  label: string;
}

export default function SearchResult({ total, subtitle, label }: SearchResultProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <h4 className={classes.subtitle}>{subtitle}</h4>
      <Tag variant="filled" label={`${total ?? "?"} ${label}`} className={classes.tag} />
    </div>
  );
}
