import { SearchInput } from "@gfg/ui-v2/components/input";
import { useCallback, Dispatch, SetStateAction } from "react";
import { useReactiveVar } from "@apollo/client";
import { makeStyles } from "@gfg/ui-v2/theming";
import CreateUserModal from "./modal/create-user-modal";
import { createUserModalOpenStatusVar, selectedUserVar } from "../../../common/graphql/cache";
import CreateButton from "../../../common/components/create-button";

const useStyles = makeStyles()(({ spacing, colors, breakpoints, palette }) => ({
  container: {
    display: "flex",
    flexDirection: "unset",

    [breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  search: {
    alignSelf: "center",
    border: `1px solid ${palette.tertiary[200]}`,
    width: spacing(400),
    height: spacing(44),
    marginRight: spacing(16),
    color: colors.gray,

    [breakpoints.down("lg")]: {
      width: spacing(200),
    },

    [breakpoints.down("xs")]: {
      width: "100%",
      marginRight: 0,
      marginBottom: spacing(10),
    },
  },
}));

interface AdminHeaderActionsProps {
  searchInput: string;
  setSearchInput: Dispatch<SetStateAction<string>>;
}

export default function AdminHeaderActions({ searchInput, setSearchInput }: AdminHeaderActionsProps) {
  const { classes } = useStyles();
  const isUserModalOpen = useReactiveVar(createUserModalOpenStatusVar);

  const toggleModal = useCallback(
    async (value: boolean) => {
      if (value) {
        await selectedUserVar(null);
      }
      createUserModalOpenStatusVar(value);
    },
    [isUserModalOpen],
  );

  return (
    <div className={classes.container}>
      <SearchInput
        onChange={setSearchInput}
        value={searchInput}
        color="lightgray"
        placeholder="Search"
        size="medium"
        variant="outline"
        className={classes.search}
      />
      <CreateButton onClick={() => toggleModal(true)} text="Create new user" />
      <CreateUserModal open={!!isUserModalOpen} toggleModal={toggleModal} />
    </div>
  );
}
