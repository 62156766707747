import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(theme => ({
  container: {
    "boxShadow": theme.shadow("md"),
    "padding": theme.spacing(10, 24, 20),
    "display": "grid",
    "gridTemplateColumns": "1fr 1fr",
    "alignItems": "center",
    "justifyContent": "space-between",
    "gap": theme.spacing(80),
    "marginBottom": theme.spacing(25),

    "& > div": {
      "display": "inherit",
      "gridTemplateColumns": "inherit",
      "gap": "inherit",
      "alignItems": "inherit",

      "&:last-child": {
        gridTemplateColumns: "1fr 0fr",
      },
    },

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  subTitle: {
    fontWeight: theme.typography.fontWeights.semiBold,
    color: theme.colors.gray,
    margin: theme.spacing(8, 0),
  },
  formInput: {
    minWidth: theme.spacing(150),
    [theme.breakpoints.down("md")]: {
      minWidth: theme.spacing(0),
    },
  },
  valueInput: {
    "& input": {
      width: "calc(100vh - 200px)",
      minWidth: theme.spacing(150),
      flexGrow: "2",

      [theme.breakpoints.down("md")]: {
        width: "100%",
        minWidth: theme.spacing(0),
      },
    },
  },
  icon: {
    background: theme.palette.secondary[100],
    height: theme.spacing(40),
    width: theme.spacing(40),
    justifySelf: "flex-end",
  },
}));

export default useStyles;
