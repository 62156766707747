import { AvailableProductAttribute } from "@coral/typings";
import { IconButton } from "@gfg/ui-v2/components/button";
import { InputField } from "@gfg/ui-v2/components/input";
import { SelectField, Option } from "@gfg/ui-v2/components/select";
import { Trash2Icon } from "@gfg/ui-v2/icons";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { AttributeFilterFormValue } from "..";
import useFilterAvailability from "./use-filter-availability";
import useStyles from "./use-styles";

interface AttributeFilterFieldProps {
  fieldName: string;
  index: number;
  disabled: boolean;
  onRemoveFilter(index: number): void;
  attributeOptions: AvailableProductAttribute[];
}

export default function AttributeFilterField({
  fieldName,
  index,
  onRemoveFilter,
  attributeOptions,
  disabled,
}: AttributeFilterFieldProps) {
  const { classes } = useStyles();
  const { control, getFieldState } = useFormContext();

  const fieldData = useWatch({
    control,
    name: `${fieldName}.${index}`,
  }) as AttributeFilterFormValue;

  const filterData = useWatch({
    control,
    name: fieldName,
  }) as AttributeFilterFormValue[];

  const { isAttributeOptionDisabled } = useFilterAvailability(filterData);

  const typeError = getFieldState(`${fieldName}.${index}.type`).error;
  const weightError = getFieldState(`${fieldName}.${index}.weight`).error;

  return (
    <div className={classes.wrapper}>
      <div>
        <div className={classes.container}>
          <div className={classes.item}>
            <Controller
              name={`${fieldName}.${index}.type`}
              control={control}
              render={({ field }) => (
                <SelectField
                  {...field}
                  disabled={disabled}
                  valid={typeError ? false : undefined}
                  invalidHint={typeError?.message?.toString()}
                  placeholder="Select attribute"
                  label="Type"
                  required
                >
                  {attributeOptions.map(option => (
                    <Option
                      key={option.value}
                      text={option.label || option.value}
                      value={option.value}
                      disabled={
                        disabled || (isAttributeOptionDisabled(option.value) && option.value !== fieldData.type)
                      }
                    >
                      {option.label || option.value}
                    </Option>
                  ))}
                </SelectField>
              )}
            />
          </div>
          <div className={classes.item}>
            <Controller
              name={`${fieldName}.${index}.weight`}
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  type="number"
                  min="0.01"
                  step={0.01}
                  autoFocus={false}
                  disabled={disabled}
                  label="Weight"
                  valid={weightError ? false : undefined}
                  invalidHint={weightError?.message?.toString()}
                />
              )}
            />
          </div>
        </div>
      </div>
      <IconButton
        disabled={disabled}
        type="button"
        icon={Trash2Icon}
        round
        variant="light"
        className={classes.icon}
        onClick={() => onRemoveFilter(index)}
      />
    </div>
  );
}
