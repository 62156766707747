import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles<StylingProps>()((theme, { top, bottom, left, right }) => ({
  container: {
    marginTop: top ?? theme.spacing(top),
    marginBottom: bottom ?? theme.spacing(bottom),
    marginLeft: left ?? theme.spacing(left),
    marginRight: right ?? theme.spacing(right),
  },
}));

interface StylingProps {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

interface SpacingProps extends StylingProps {
  children?: React.ReactNode;
  className?: string;
}

export default function Spacing({ children, className, ...stylingProps }: SpacingProps) {
  const { classes, cx } = useStyles(stylingProps);

  return <div className={cx(classes.container, className)}>{children}</div>;
}
