/* eslint-disable import/prefer-default-export */

export function convertStringToSynonyms(input: string = ""): string[] {
  const newSynonyms = input
    .split(",")
    .map(synonym => synonym.trim())
    .filter(s => s !== "");

  return newSynonyms;
}
