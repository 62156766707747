import { useMemo } from "react";
import useMenuConfigList from "./use-menu-config-list";

const useDefaultPage = () => {
  const menuConfigList = useMenuConfigList();

  // gets first link from the menu
  const defaultPage = useMemo(
    () => menuConfigList[0]?.linkTo || menuConfigList[0]?.subcategories[0]?.linkTo,
    [menuConfigList],
  );

  return defaultPage;
};

export default useDefaultPage;
