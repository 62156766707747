import { SearchConfigurationVersion } from "@coral/typings";
import DataTable, { Column, useTableState } from "@gfg/ui-v2/components/data-table";
import { useCallback, useState } from "react";
import DeleteModal from "../../../common/components/delete-modal";
import useColumns from "./use-columns";
import useTableRowActions, { ActionsRow } from "./use-table-row-actions";

interface ConfigVersionsListProps {
  loading: boolean;
  versions: SearchConfigurationVersion[];
  onDuplicate(version: SearchConfigurationVersion): void;
  onEdit(version: SearchConfigurationVersion): void;
  onDelete(version: SearchConfigurationVersion): void;
}

export default function ConfigVersionsList({
  loading,
  versions,
  onDelete,
  onDuplicate,
  onEdit,
}: ConfigVersionsListProps) {
  const columns: Column<SearchConfigurationVersion>[] = useColumns();
  const [state] = useTableState(columns, u => u.id);
  const [toDelete, setToDelete] = useState<SearchConfigurationVersion | null>(null);

  const handleAction = useCallback((action: ActionsRow, row: SearchConfigurationVersion) => {
    switch (action) {
      case "delete":
        setToDelete(row);
        break;
      case "edit":
        onEdit(row);
        break;
      case "duplicate":
        onDuplicate(row);
        break;
      default:
    }
  }, []);

  const actions = useTableRowActions(handleAction);

  return (
    <>
      <DataTable
        actions={actions}
        selectable={false}
        columns={columns}
        state={state}
        data={versions}
        loading={loading}
      />
      <DeleteModal
        open={!!toDelete}
        title="Delete Version"
        text="Are you sure you want to delete this version?"
        onToggle={() => setToDelete(null)}
        onDelete={() => onDelete(toDelete)}
      />
    </>
  );
}
