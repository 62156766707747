import { ConditionalFilterInput, Operation } from "@coral/typings";
import { ConditionalFilterFormValue } from "../../components/form-filters/conditional-filters";

function serializeConditionalFilter(attribute: ConditionalFilterFormValue): ConditionalFilterInput {
  if (typeof attribute.value === "number") {
    return {
      id: attribute.id,
      fieldName: attribute.type,
      operation: attribute.rule as Operation,
      numValue: attribute.value,
    };
  }

  if (typeof attribute.value === "string") {
    return {
      id: attribute.id,
      fieldName: attribute.type,
      operation: attribute.rule as Operation,
      strValue: attribute.value,
    };
  }

  if (Array.isArray(attribute.value)) {
    return {
      id: attribute.id,
      fieldName: attribute.type,
      operation: attribute.rule as Operation,
      strArrValue: attribute.value,
    };
  }

  throw new Error(`Attribute value ${attribute.value} is not string or array, therefore it cant be serialized`);
}

export default serializeConditionalFilter;
