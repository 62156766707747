import { useMemo } from "react";
import { useReactiveVar } from "@apollo/client";

import { useGetEnvironmentsQuery } from "../../generated";
import reactiveVariables from "../graphql/global-reactive-variables";

function useCurrentSelectedEnvironment() {
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const { data: environmentsData } = useGetEnvironmentsQuery();

  const environment = useMemo(
    () => environmentsData?.configuration?.environments?.find(e => e.id === selectedEnvironmentProjectId),
    [environmentsData, selectedEnvironmentProjectId],
  );

  return environment;
}

export default useCurrentSelectedEnvironment;
