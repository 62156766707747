import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(theme => ({
  container: {
    margin: theme.spacing(16),
    marginBottom: 150,
  },
}));

interface PageContainerProps {
  children: React.ReactNode;
  className?: string;
}

export default function PageContainer({ children, className }: PageContainerProps) {
  const { classes, cx } = useStyles();

  return <div className={cx(classes.container, className)}>{children}</div>;
}
