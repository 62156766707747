import * as yup from "yup";

const searchOptimizationValidationSchema = yup.object().shape({
  query: yup.string().nullable().required("Query is required"),
  output: yup.string().nullable().required("Output is required"),
  state: yup.string().nullable().required("State is required"),
  createdAt: yup.string().nullable().notRequired(),
});

export default searchOptimizationValidationSchema;
