import { Dialog, DialogBody, DialogHeader } from "@gfg/ui-v2/components/dialog";
import React, { useEffect } from "react";
import { MailIcon } from "@gfg/ui-v2/icons";
import { InputField } from "@gfg/ui-v2/components/input";
import { Radio, RadioGroup } from "@gfg/ui-v2/components/radio";
import { FormGroup } from "@gfg/ui-v2/components/form-group";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useReactiveVar } from "@apollo/client";
import { Environment, Role } from "@coral/typings";
import CheckboxGroup from "common/components/inputs/checkbox-group";
import { makeStyles } from "@gfg/ui-v2/theming";
import { LinearProgress } from "@gfg/ui-v2/components/linear-progress";
import ModalActions from "./modal-actions";
import { useCreateUserMutation, useGetEnvironmentsQuery, useUpdateUserMutation } from "../../../../generated";
import validationSchema from "./validation-schema";
import ErrorMessage from "../../../../common/components/error-message";
import { selectedUserVar } from "../../../../common/graphql/cache";
import MultiSelect from "../../environment-multi-select/multi-select";
import RoleDisplay from "../../../../common/constants/role-display";
import { useAuth } from "../../../../auth/auth-provider";
import useAppNotifications from "../../../../common/hooks/use-app-notifications";

const useStyles = makeStyles()(({ spacing, colors, palette, shadow }) => ({
  dialogHeader: {
    "position": "relative",
    "& button": {
      boxShadow: `${shadow("md")}`,
      color: colors.text,
    },
  },
  dialogBody: {
    position: "relative",
  },
  title: {
    margin: 0,
    color: colors.text,
    alignSelf: "center",
  },
  email: {
    "& label:last-child": {
      cursor: "pointer",
      border: `1px solid ${palette.tertiary[200]}`,
    },
    "& small": {
      color: colors.error,
      textAlign: "left",
      fontSize: 12,
    },
  },
  selectEnvContainer: {
    "textAlign": "left",
    "position": "relative",
    "& label:last-child": {
      border: `1px solid ${palette.tertiary[200]}`,
    },
  },
  selectEnvError: {
    top: spacing(68),
    position: "absolute",
  },
  loading: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    zIndex: 1,
  },
}));

interface CreateUserModalProps {
  open: boolean;
  toggleModal: (value: boolean) => void;
}

export default function CreateUserModal({ open, toggleModal }: CreateUserModalProps) {
  const { classes } = useStyles();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
  } = useForm({ resolver: yupResolver(validationSchema) });

  const formRole = (watch("role") as Role) || null;

  const mutationConfig = { refetchQueries: ["GetUsers"] };
  const [createUser, { loading: loadingCreateUser }] = useCreateUserMutation({ ...mutationConfig });
  const [updateUser, { loading: loadingUpdateUser }] = useUpdateUserMutation({ ...mutationConfig });
  const selectedUser = useReactiveVar(selectedUserVar);
  const { addSuccessNotification, addErrorNotification } = useAppNotifications();
  const { viewer } = useAuth();
  const isRegionalManager = viewer?.role === "regional_manager";
  const roles = Object.entries(RoleDisplay).filter(([key]) => (isRegionalManager ? key !== "master" : true));
  const editableResources = viewer?.resources?.filter(r => r.editable_by_users) || [];
  const { data } = useGetEnvironmentsQuery();
  const allEnvironments = (data?.configuration.environments as Environment[]) || [];
  const viewerEnvironments =
    ((viewer?.role === "master" ? data?.configuration.environments : viewer?.environments) as Environment[]) || [];

  async function onSubmit(data: any) {
    const { email, role, environments, resources } = data;

    try {
      if (selectedUser && selectedUser.operation === "edit") {
        const response = await updateUser({
          variables: {
            id: selectedUser.id,
            user: {
              role,
              environments,
              resources,
            },
          },
        });
        if (response) {
          reset();
          toggleModal(false);
          addSuccessNotification("User information successfully updated");
          return;
        }
      }
      const response = await createUser({
        variables: {
          user: {
            email,
            role,
            environments,
            resources,
          },
        },
      });
      if (response) {
        reset();
        toggleModal(false);
        addSuccessNotification("The user access has been successfully created");
      }
    } catch (error) {
      addErrorNotification("Error", error instanceof Error ? error.message : "Unknown Error");
    }
  }

  useEffect(() => {
    if (selectedUser) {
      reset({
        email: selectedUser.operation === "edit" ? selectedUser.email : "",
        role: selectedUser.role,
        environments: selectedUser.environments.map(e => e.id),
        resources: selectedUser.resources.map(r => r.id),
      });
      return;
    }
    reset({
      email: undefined,
      role: undefined,
      environment: undefined,
      resources: undefined,
    });
  }, [open, selectedUser]);

  return (
    <Dialog
      open={open}
      size="medium"
      onClose={() => {
        toggleModal(false);
      }}
    >
      <DialogHeader className={classes.dialogHeader}>
        {(loadingCreateUser || loadingUpdateUser) && (
          <div className={classes.loading}>
            <LinearProgress indeterminate />
          </div>
        )}
        <h4 className={classes.title}>{selectedUser?.operation === "edit" ? "Edit User" : "Create new users"}</h4>
      </DialogHeader>
      <DialogBody className={classes.dialogBody}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <InputField
                {...field}
                onChange={(val: string) => field.onChange(val?.trim())}
                hint={errors.email?.message?.toString()}
                placeholder="Email address"
                label="Email address"
                block
                leftIcon={<MailIcon color="gray" />}
                className={classes.email}
                disabled={selectedUser?.operation === "edit"}
              />
            )}
          />
          <FormGroup label="Role">
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field}>
                  {roles.map(([key, value]) => (
                    <Radio key={key} color="primary" value={key}>
                      {value}
                    </Radio>
                  ))}
                </RadioGroup>
              )}
            />
            <ErrorMessage message={errors.role?.message?.toString()} />
          </FormGroup>
          <FormGroup label="Sections">
            <Controller
              name="resources"
              control={control}
              render={({ field }) => (
                <CheckboxGroup {...field} options={editableResources.map(r => ({ label: r.title, value: r.id }))} />
              )}
            />
            <ErrorMessage message={errors.resources?.message?.toString()} />
          </FormGroup>
          {formRole !== "master" && (
            <div className={classes.selectEnvContainer}>
              <Controller
                name="environments"
                control={control}
                render={({ field }) => (
                  <MultiSelect
                    label="Environments"
                    allEnvironments={allEnvironments}
                    viewerEnvironments={viewerEnvironments}
                    {...field}
                  />
                )}
              />
              <ErrorMessage message={errors.environments?.message?.toString()} className={classes.selectEnvError} />
            </div>
          )}
          <ModalActions
            submitBtnName={selectedUser?.operation === "edit" ? "Save" : "Create new user"}
            closeModal={() => {
              reset();
              toggleModal(false);
            }}
          />
        </form>
      </DialogBody>
    </Dialog>
  );
}
