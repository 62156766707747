import { useCallback, useMemo } from "react";
import { AttributeFilterFormValue } from "..";

function useFilterAvailability(usedFilters: AttributeFilterFormValue[]) {
  const alreadyUsedAttributesMap = useMemo(
    () => Object.fromEntries(usedFilters.map(ao => [ao.type, true])),
    [usedFilters],
  );

  const isAttributeOptionDisabled = useCallback(
    (value: string) => alreadyUsedAttributesMap[value],
    [alreadyUsedAttributesMap],
  );

  return {
    isAttributeOptionDisabled,
  };
}

export default useFilterAvailability;
