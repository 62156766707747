// @ts-nocheck

import { useEffect } from "react";

export default function useAppVersion() {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(`Current app version: ${__COMMIT_HASH__}`); // __COMMIT_HASH__ is defined at vite.config.ts
  }, []);
}
