import * as yup from "yup";
import attributeFilterWithRulesObjectShape from "../../../common/components/form-filters/attribute-filters-with-rules/validation-schema";

const searchConfigValidationSchema = yup.object().shape({
  campaignName: yup.string().nullable().required("Campaign name is required"),
  value: yup.string().nullable().required("Value is required"),
  rankAttributes: yup.array().of(attributeFilterWithRulesObjectShape).nullable(),
  boostedSkus: yup.array().of(yup.string()).nullable(),
  type: yup.string().required("Type is required"),
});

export default searchConfigValidationSchema;
