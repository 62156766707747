import React, { ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import Dialog, { DialogBody, DialogFooter } from "@gfg/ui-v2/components/dialog";
import { FormGroup, Hint } from "@gfg/ui-v2/components/form-group";
import Radio, { RadioGroup } from "@gfg/ui-v2/components/radio";
import Button from "@gfg/ui-v2/components/button";
import { LinearProgress } from "@gfg/ui-v2/components/linear-progress";
import { makeStyles } from "@gfg/ui-v2/theming";
import DialogHeaderWithCloseTooltip from "~/common/components/dialog-header-with-close-tooltip";

const useStyles = makeStyles()(({ spacing }) => ({
  description: {
    padding: spacing("sm", 0),
  },
  dialog: {
    overflow: "hidden",
  },
  header: {
    position: "relative",
  },
  loading: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    zIndex: 1,
  },
  footer: {
    display: "flex",
    flexDirection: "row",
    columnGap: spacing("sm"),
    justifyContent: "flex-end",
    padding: spacing(0, "lg", "lg"),
  },
  wrapper: {
    alignItems: "baseline",
  },
  container: {
    display: "flex",
    flexDirection: "column",
  },
  hint: {
    margin: spacing(0, 0, "xs", 0),
  },
}));

export interface ExportDialogOption {
  description?: string;
  disabled?: boolean;
  label: string;
}

interface ExportDialogProps {
  defaultOption: string;
  filter: ReturnType<any>;
  description?: string;
  loading: boolean;
  open: boolean;
  options: Record<string, ExportDialogOption>;
  selectedIds?: string[];
  title: ReactNode;

  onExport(variables: Record<string, any>, whatToExport: string): void;
  setOpen(flag: boolean): void;
}

const ExportDialogBase = forwardRef<{ selectedOption: string }, ExportDialogProps>(
  (
    {
      defaultOption,
      description,
      filter,
      open,
      loading,
      selectedIds,
      setOpen,
      title,
      options,

      onExport,
    },
    ref,
  ) => {
    const { classes } = useStyles();
    const [whatToExport, setWhatToExport] = useState<string>(defaultOption);

    useEffect(() => {
      setWhatToExport(defaultOption);
    }, [defaultOption]);

    useImperativeHandle(
      ref,
      () => ({
        selectedOption: whatToExport,
      }),
      [whatToExport],
    );

    const handleChangeExportType = useCallback((variant: string) => {
      setWhatToExport(variant);
    }, []);

    const handleExport = useCallback(async () => {
      let variables = {};

      switch (whatToExport) {
        // here we don't use all-... case, because we don't have to change variables
        case "current-filter":
          variables = filter;
          break;
        default: {
          break;
        }
      }

      await onExport(variables, whatToExport);
      setWhatToExport(defaultOption);
      setOpen(false);
    }, [whatToExport, onExport, defaultOption, filter, selectedIds]);

    const handleClose = useCallback(() => {
      setOpen(false);
      setWhatToExport(defaultOption);
    }, [defaultOption, setOpen]);

    const buttonDisabled = options[whatToExport]?.disabled || !whatToExport;

    return (
      <Dialog className={classes.dialog} open={open} size="small" onClose={handleClose}>
        <DialogHeaderWithCloseTooltip className={classes.header}>
          <div>
            {loading && (
              <div className={classes.loading}>
                <LinearProgress indeterminate />
              </div>
            )}
            <h4>{title}</h4>
          </div>
        </DialogHeaderWithCloseTooltip>
        <DialogBody>
          {description && (
            <div className={classes.description}>
              <small>{description}</small>
            </div>
          )}
          <FormGroup label="Export parameters">
            <RadioGroup direction="vertical" value={whatToExport} onChange={handleChangeExportType}>
              {Object.keys(options).map(key => (
                <Radio
                  key={key}
                  className={classes.wrapper}
                  color="primary"
                  disabled={options[key].disabled}
                  value={key}
                >
                  <div className={classes.container}>
                    {options[key].label}
                    {options[key].description && (
                      <Hint className={classes.hint} valid={false}>
                        {options[key].description || ""}
                      </Hint>
                    )}
                  </div>
                </Radio>
              ))}
            </RadioGroup>
          </FormGroup>
        </DialogBody>
        <DialogFooter className={classes.footer}>
          <Button color="text" variant="link" onClick={handleClose}>
            Cancel
          </Button>
          <Button disabled={loading || buttonDisabled} onClick={handleExport}>
            {loading ? "Exporting" : "Export"}
          </Button>
        </DialogFooter>
      </Dialog>
    );
  },
);

export default ExportDialogBase;
