import { useMemo } from "react";
import { useAuth } from "../../auth/auth-provider";
import { useGetEnvironmentsQuery } from "../../generated";

function useAllowedEnvironments() {
  const { viewer } = useAuth();
  const { data: environmentsData } = useGetEnvironmentsQuery();

  const environments = useMemo(
    () => (viewer?.role === "master" ? environmentsData?.configuration.environments : viewer?.environments),
    [viewer, environmentsData],
  );

  return environments;
}

export default useAllowedEnvironments;
