import { useState } from "react";

import { useReactiveVar } from "@apollo/client";
import { PaginatedSearchOptimizations, SearchOptimizationState } from "@coral/typings";
import { ColumnSortingDirection } from "@gfg/ui-v2/types";
import pagination from "../../common/constants/pagination";
import { useGetSearchOptimizationsQuery } from "../../generated";
import reactiveVariables from "../../common/graphql/global-reactive-variables";

interface UseGetSearchOptimizationsParams {
  sort?: {
    column: string;
    direction: ColumnSortingDirection;
  };
  stateFilter?: SearchOptimizationState;
  setStateFilter?: (state: SearchOptimizationState) => void;
}

export default function useGetSearchOptimizations({ sort, stateFilter }: UseGetSearchOptimizationsParams) {
  const { column, direction } = sort || {};
  const [searchInput, setSearchInput] = useState("");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(+pagination.LIMITS[0]);
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const { data, previousData, loading } = useGetSearchOptimizationsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        search: searchInput,
        projectId: selectedEnvironmentProjectId,
        stateFilter: stateFilter as SearchOptimizationState,
      },
      sort: {
        column: column || "UPDATED_AT",
        direction: direction === "ascending" ? "asc" : "desc",
      },
      pagination: {
        offset,
        limit,
      },
    },
  });
  const searchOptimizations = (data ?? previousData)?.searchOptimizations as PaginatedSearchOptimizations;

  return {
    searchOptimizations,
    searchInput,
    setSearchInput,
    loading,
    offset,
    setOffset,
    limit,
    setLimit,
  };
}
