import { useCallback } from "react";
import LinearProgress from "@gfg/ui-v2/components/linear-progress";
import { useNotification } from "@gfg/ui-v2/components/notification";

function useAppNotifications() {
  const { addNotification, removeNotification } = useNotification();
  const handleBasicNotification = useCallback(
    (text: string, color: string, content: React.ReactNode = null, timeout = 3000) =>
      addNotification(text, content, {
        color,
        autogrow: true,
        timeout,
      }),
    [addNotification],
  );

  const handleSuccessNotification = useCallback(
    (text: string, content?: React.ReactNode) => handleBasicNotification(text, "success", content),
    [handleBasicNotification],
  );
  const handleErrorNotification = useCallback(
    (text: string, content?: React.ReactNode) => handleBasicNotification(text, "error", content),
    [handleBasicNotification],
  );
  const handleInProgressNotification = useCallback(
    (text: string) =>
      addNotification(<span>{text}</span>, <LinearProgress indeterminate />, {
        color: "primary",
        autogrow: true,
        closable: false,
      }),
    [addNotification],
  );

  return {
    addNotification,
    removeNotification,
    addSuccessNotification: handleSuccessNotification,
    addErrorNotification: handleErrorNotification,
    addInProgressNotification: handleInProgressNotification,
  };
}

export default useAppNotifications;
