import { useCallback } from "react";
import { ApolloError, useReactiveVar } from "@apollo/client";

import extractGraphqlError from "common/utils/apollo/extract-graphql-error";
import { useActivateSearchConfigVersionMutation, useDeactivateSearchConfigVersionMutation } from "../../../generated";
import useAppNotifications from "../../../common/hooks/use-app-notifications";
import reactiveVariables from "../../../common/graphql/global-reactive-variables";

function useActivateSearchConfig() {
  const { addSuccessNotification, addErrorNotification } = useAppNotifications();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const [activateSearchConfig] = useActivateSearchConfigVersionMutation({
    refetchQueries: ["GetSearchConfiguration", "GetSearchConfigurations"],
  });

  return useCallback(
    async (configId: string, versionId: string) => {
      try {
        await activateSearchConfig({
          variables: {
            input: {
              configurationId: configId,
              versionId,
              projectId: selectedEnvironmentProjectId,
            },
          },
        });

        addSuccessNotification("Configuration was activated successfully");
      } catch (error) {
        const message = extractGraphqlError(error as ApolloError);
        addErrorNotification("Error while editing configuration", message);
      }
    },
    [activateSearchConfig],
  );
}

function useDeactivateSearchConfig() {
  const { addSuccessNotification, addErrorNotification } = useAppNotifications();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const [deactivateSearchConfig] = useDeactivateSearchConfigVersionMutation({
    refetchQueries: ["GetSearchConfiguration", "GetSearchConfigurations"],
  });

  return useCallback(
    async (configId: string, versionId: string) => {
      try {
        await deactivateSearchConfig({
          variables: {
            input: {
              configurationId: configId,
              versionId,
              projectId: selectedEnvironmentProjectId,
            },
          },
        });

        addSuccessNotification("Configuration was deactivated successfully");
      } catch (error) {
        const message = extractGraphqlError(error as ApolloError);
        addErrorNotification("Error while editing configuration", message);
      }
    },
    [deactivateSearchConfig],
  );
}
export { useActivateSearchConfig, useDeactivateSearchConfig };
