import * as yup from "yup";
import { convertStringToSynonyms } from "../../utils";

export default yup.object().shape({
  synonyms: yup
    .string()
    .nullable()
    .required("Synonyms are required")
    .test("synonyms", "Only unique values are allowed", value => {
      const listOfSynonyms = convertStringToSynonyms(value).map(s => s.toLowerCase());

      return listOfSynonyms.length === new Set(listOfSynonyms)?.size;
    })
    .test("testCondition", "You need at least two synonyms to save a set", value => {
      const listOfSynonyms = convertStringToSynonyms(value);

      return listOfSynonyms.length >= 2;
    }),
});
