import Checkbox from "@gfg/ui-v2/components/checkbox";
import FlexWrapContainer from "common/components/containers/flex-wrap-container";
import Spacing from "common/components/spacing";

interface Option {
  label: string;
  value: string;
}

interface CheckboxGroupProps {
  value: string[];
  onChange(values: string[]): void;

  options: Option[];
}

export default function CheckboxGroup({ value: values = [], onChange, options }: CheckboxGroupProps) {
  const isChecked = (optionValue: string) => values.includes(optionValue);

  const handleChange = (optionValue: string) => {
    if (values.includes(optionValue)) {
      onChange(values.filter(value => value !== optionValue));
    } else {
      onChange([...values, optionValue]);
    }
  };

  return (
    <FlexWrapContainer gap={0}>
      {options.map(o => (
        <Spacing key={o.value} right={10}>
          <Checkbox color="primary" checked={isChecked(o.value)} onChange={() => handleChange(o.value)}>
            {o.label}
          </Checkbox>
        </Spacing>
      ))}
    </FlexWrapContainer>
  );
}
