import { IconButton } from "@gfg/ui-v2/components/button";
import { ChevronDownIcon } from "@gfg/ui-v2/icons";

import Dropdown, { DropdownItem } from "@gfg/ui-v2/components/dropdown";
import { makeStyles } from "@gfg/ui-v2/theming";

interface AvailableFilter {
  label: string;
  value: string;
}

interface TableFilterProps {
  filters: AvailableFilter[];

  value: string | undefined;
  onChange(value: string): void;
}

const useStyles = makeStyles()(({ size }) => ({
  iconButton: {
    margin: size(5),
  },
}));

export default function TableFilter({ filters, value, onChange }: TableFilterProps) {
  const { classes } = useStyles();

  const handleChange = (selectedValue: string) => {
    if (value === selectedValue) {
      onChange(undefined);
    } else {
      onChange(selectedValue);
    }
  };

  return (
    <Dropdown
      triggerElement={
        <IconButton
          className={classes.iconButton}
          size="small"
          icon={ChevronDownIcon}
          variant={value ? "filled" : "link"}
        />
      }
      onSelect={handleChange}
      value={value}
    >
      {filters.map(({ label, value }) => (
        <DropdownItem key={value} value={value}>
          {label}
        </DropdownItem>
      ))}
    </Dropdown>
  );
}
