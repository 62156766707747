import getGlobalObject from "../../utils/misc/get-global-object";
import { SELECTED_ENVIRONMENT_VARIABLE_LOCAL_STORAGE_KEY } from "../../graphql/global-reactive-variables";

export default function addDatadogError(error: unknown, context: object = {}) {
  const RumGlobal = getGlobalObject<Window>().DD_RUM;
  if (RumGlobal) {
    RumGlobal.addError(error, {
      currentEnvironmentId: localStorage.getItem(SELECTED_ENVIRONMENT_VARIABLE_LOCAL_STORAGE_KEY),
      ...context,
    });
  }

  if (process.env.NODE_ENV !== "production" || !RumGlobal) {
    // eslint-disable-next-line no-console
    console.error(error, { context });
  }
}
