import { IconButton } from "@gfg/ui-v2/components/button";
import { Edit3Icon, Trash2Icon, CheckIcon } from "@gfg/ui-v2/icons";
import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ spacing, palette }) => ({
  container: {
    "display": "flex",
    "justifyContent": "flex-end",
    "& button": {
      marginRight: spacing(10),
    },
    "marginLeft": spacing(15),
  },
  icon: {
    background: palette.secondary[100],
  },
  synonymsInput: {
    width: "100%",
  },
}));

export interface ActionsProps {
  onEdit(): void;
  isAddMode?: boolean;
  editMode: boolean;
  unsavedChanges: boolean;
  onDelete(): void;
  onSave(): void;
  isSaveButtonDisabled?: boolean;
}

export default function Actions({
  isAddMode,
  onDelete,
  onEdit,
  onSave,
  editMode,
  unsavedChanges,
  isSaveButtonDisabled,
}: ActionsProps) {
  const { classes } = useStyles();

  const SaveButton = (
    <IconButton
      onClick={onSave}
      disabled={isSaveButtonDisabled}
      color={unsavedChanges || isAddMode ? "success" : "primary"}
      size="small"
      icon={<CheckIcon color="white" />}
    />
  );

  return (
    <div className={classes.container}>
      {!editMode && (
        <>
          <IconButton onClick={onEdit} size="small" icon={Edit3Icon} round variant="faded" className={classes.icon} />
          <IconButton
            onClick={onDelete}
            size="small"
            icon={Trash2Icon}
            round
            variant="faded"
            className={classes.icon}
          />
        </>
      )}
      {editMode && SaveButton}
    </div>
  );
}
