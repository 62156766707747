import { useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { UnidirectionalSynonymsSet } from "@coral/typings";
import { useReactiveVar } from "@apollo/client";
import { useGetUnidirectionalSynonymsLazyQuery } from "../../generated";
import reactiveVariables from "../../common/graphql/global-reactive-variables";

export default function useGetSynonyms(search: string) {
  const [limit, setLimit] = useState(300);
  const [offset, setOffset] = useState(0);
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const [getSynonyms, { data, loading }] = useGetUnidirectionalSynonymsLazyQuery({
    variables: { params: { limit, offset, projectId: selectedEnvironmentProjectId, search } },
    fetchPolicy: "cache-first",
  });

  const synonyms = (data?.unidirectionalSynonymsSets as UnidirectionalSynonymsSet[]) ?? null;

  const fetchSynonyms = useCallback(
    debounce(async (limit: number, offset: number, projectId: string, searchValue: string) => {
      await getSynonyms({ variables: { params: { offset, limit, projectId, search: searchValue } } });
    }, 200),
    [limit, offset, selectedEnvironmentProjectId, getSynonyms],
  );

  useEffect(() => {
    fetchSynonyms(limit, offset, selectedEnvironmentProjectId, search);
  }, [search, limit, offset, fetchSynonyms, selectedEnvironmentProjectId]);

  return {
    synonyms,
    loading,
    limit,
    offset,
    setLimit,
    setOffset,
  };
}
