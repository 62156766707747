import { Table, TableCell, TableHead, TableRow } from "@gfg/ui-v2/components/table";
import LinearProgress from "@gfg/ui-v2/components/linear-progress";
import { useNavigate, useParams } from "react-router-dom";
import TableFilter from "common/components/table/table-filter";
import CustomFeedStateMap from "common/constants/custom-feed-state";
import { CustomFeedStateTranslations } from "common/translations";
import { makeStyles } from "@gfg/ui-v2/theming";
import useGetNewsletters from "../../hooks/use-get-newsletters";
import NewsletterList from "./newsletter-list";
import TableFooter from "../../../common/components/table/table-footer";
import TableHeader from "../../../common/components/table/table-header";
import useEventTracking from "../../../common/hooks/use-tracking-event";

const useStyles = makeStyles()(theme => ({
  tableContainer: {
    "boxShadow": theme.shadow("md"),
    "margin": `${theme.spacing(16)}`,
    "& .tableBody tr": {
      borderTopColor: "transparent",
    },
  },
  headerCell: {
    color: theme.colors.text,
    background: theme.colors.background,
  },
  headerCellWithIcon: {
    display: "flex",
    alignItems: "center",
  },
}));

export default function NewsletterTable() {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const { region } = useParams();

  const {
    newsletters,
    searchInput,
    setSearchInput,
    stateFilter,
    setStateFilter,
    offset,
    setOffset,
    limit,
    setLimit,
    loading,
  } = useGetNewsletters();

  const sendCreateButtonAnalyticsEvent = useEventTracking({ eventName: "recos-nl-create-new" });
  const sendSearchAnalyticsEvent = useEventTracking({ eventName: "recos-nl-search" });

  const handleCreateNewsletter = () => {
    navigate(`/${region}/recommendations/newsletter/create`);
    sendCreateButtonAnalyticsEvent();
  };

  return (
    <div className={classes.tableContainer}>
      <TableHeader
        total={newsletters?.total}
        onSearchInputChange={setSearchInput}
        searchInput={searchInput}
        subtitle="Recommendation Newsletters"
        searchInputPlaceholder="Newsletter name, ID or user name"
        onSearchInputFocus={sendSearchAnalyticsEvent}
        actionButtonLabel="Create newsletter"
        onActionButtonClick={handleCreateNewsletter}
      />
      {loading && <LinearProgress indeterminate />}
      <Table block>
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerCell}>Name</TableCell>
            <TableCell className={classes.headerCell}>Last Modified</TableCell>
            <TableCell className={cx(classes.headerCell, classes.headerCellWithIcon)}>
              Status
              <TableFilter
                filters={Object.values(CustomFeedStateMap).map(value => ({
                  value,
                  label: CustomFeedStateTranslations[value],
                }))}
                onChange={setStateFilter}
                value={stateFilter}
              />
            </TableCell>

            <TableCell className={classes.headerCell} />
          </TableRow>
        </TableHead>
        {newsletters?.data && <NewsletterList newsletters={newsletters.data} />}
      </Table>
      <TableFooter
        onOffsetChange={setOffset}
        offset={offset}
        total={newsletters?.total}
        limit={limit.toString()}
        onLimitChange={setLimit}
      />
    </div>
  );
}
