import { AvailableProductAttribute } from "@coral/typings";
import Button from "@gfg/ui-v2/components/button";
import { PlusIcon } from "@gfg/ui-v2/icons";
import { useFieldArray, useFormContext } from "react-hook-form";
import FlexWrapContainer from "../../containers/flex-wrap-container";
import { ComponentProps } from "../dynamic-filter-component";
import { FilterAutocompleteMultiselectProps } from "../dynamic-filter-component/filter-components/autocomplete-multiselect-filter";
import AttributeFilterWithRulesField from "./attribute-filter-field";

export interface AttributeFilterWithRuleFormValue {
  id?: string;
  type: string;
  weight: number;
  rule?: string;
  value?: ComponentProps["value"];
}

interface AttributeFilterGroupProps {
  disabled: boolean;
  fieldName: string;
  addAttributeButtonLabel: string;
  attributeOptions: AvailableProductAttribute[];

  getFacetValues: FilterAutocompleteMultiselectProps["getValues"];
}

export default function AttributeFilterWithRulesGroupField({
  disabled,
  fieldName,
  addAttributeButtonLabel,
  attributeOptions,
  getFacetValues,
}: AttributeFilterGroupProps) {
  const { control } = useFormContext();
  const { append, remove, fields } = useFieldArray({ name: fieldName, control });

  const addFilter = () => {
    const fieldData: AttributeFilterWithRuleFormValue = {
      type: "",
      weight: 1,
      rule: "",
      value: "",
    };

    append(fieldData);
  };

  return (
    <div>
      <FlexWrapContainer>
        {fields.map((field, index) => (
          <AttributeFilterWithRulesField
            key={field.id}
            fieldName={fieldName}
            index={index}
            onRemoveFilter={remove}
            disabled={disabled}
            attributeOptions={attributeOptions}
            getFacetValues={getFacetValues}
          />
        ))}
      </FlexWrapContainer>
      <Button disabled={disabled} type="button" onClick={addFilter} variant="outline" leftIcon={<PlusIcon />}>
        {addAttributeButtonLabel}
      </Button>
    </div>
  );
}
