import Button from "@gfg/ui-v2/components/button";
import { LinearProgress } from "@gfg/ui-v2/components/linear-progress";
import { PlusIcon } from "@gfg/ui-v2/icons";
import { useFieldArray, useFormContext } from "react-hook-form";
import { makeStyles } from "@gfg/ui-v2/theming";
import { useCallback } from "react";
import FilterRow, { AttributeOption } from "./filter-row-with-fields";
import { ComponentProps } from "../dynamic-filter-component";
import { FilterAutocompleteMultiselectProps } from "../dynamic-filter-component/filter-components/autocomplete-multiselect-filter";

export interface ConditionalFilterFormValue {
  id?: string;
  type: string;
  rule: string;
  value: ComponentProps["value"];
}

const useStyles = makeStyles()(theme => ({
  container: {
    paddingBottom: theme.spacing(30),
    borderBottom: `1px solid ${theme.palette.tertiary[200]}`,
  },
}));

interface ConditionalFilterProps {
  onAddFeedFilter?(): void;
  disabled?: boolean;
  allowDuplicates?: boolean;

  fieldName: string;
  attributeOptions: AttributeOption[];
  loading: boolean;
  getFacetValues: FilterAutocompleteMultiselectProps["getValues"];
}

export default function ConditionalFilter({
  onAddFeedFilter,
  attributeOptions,
  loading,
  disabled = false,
  allowDuplicates = false,
  fieldName,
  getFacetValues,
}: ConditionalFilterProps) {
  const { classes } = useStyles();

  const { control } = useFormContext();
  const { append, remove, fields } = useFieldArray({ name: fieldName, control });

  const addFilter = useCallback(() => {
    const fieldData: ConditionalFilterFormValue = {
      type: "",
      rule: "",
      value: "",
    };
    append(fieldData);

    if (onAddFeedFilter) {
      onAddFeedFilter();
    }
  }, [append, onAddFeedFilter]);

  return (
    <div className={classes.container}>
      {loading && <LinearProgress indeterminate />}
      {!loading && attributeOptions && (
        <>
          {fields.map((field, index) => (
            <FilterRow
              key={field.id}
              disabled={disabled}
              allowDuplicates={allowDuplicates}
              attributeOptions={attributeOptions}
              index={index}
              removeFilter={remove}
              fieldName={fieldName}
              getFacetValues={getFacetValues}
            />
          ))}
          <Button disabled={disabled} type="button" onClick={addFilter} variant="outline" leftIcon={<PlusIcon />}>
            Add Filter
          </Button>
        </>
      )}
    </div>
  );
}
