import React from "react";
import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(theme => ({
  coverContainer: {
    paddingTop: 0,
    [theme.breakpoints.up("xl")]: {
      paddingTop: theme.spacing(16),
      paddingRight: theme.spacing(16),
    },
  },
  image: {
    display: "none",
    [theme.breakpoints.up("xl")]: {
      display: "block",
    },
  },
  imageMobile: {
    display: "block",
    background: `url('/login-cover-mobile.png')`,
    width: "100%",
    height: 75,
    [theme.breakpoints.up("xl")]: {
      display: "none",
    },
  },
}));

export default function LoginCover() {
  const { classes } = useStyles();

  return (
    <div className={classes.coverContainer}>
      <img className={classes.image} src="/login-cover.png" alt="Login cover" />
      <div className={classes.imageMobile} />
    </div>
  );
}
