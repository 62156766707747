import { Dispatch, SetStateAction } from "react";
import { makeStyles } from "@gfg/ui-v2/theming";
import SearchResult from "../../../common/components/table/search-result";
import AdminHeaderActions from "./admin-header-actions";

const useStyles = makeStyles()(({ spacing, colors, palette, breakpoints }) => ({
  container: {
    display: "flex",
    borderBottom: `1px solid ${palette.tertiary[200]}`,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "space-between",
    height: spacing(84),
    padding: spacing(0, 24),
    marginBottom: spacing(24),
    background: colors.background,

    [breakpoints.down("xs")]: {
      height: "auto",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: spacing(10),
    },
  },
}));

interface AdminHeaderProps {
  searchInput: string;
  total: number;
  setSearchInput: Dispatch<SetStateAction<string>>;
}

export default function AdminHeader({ searchInput, setSearchInput, total }: AdminHeaderProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <SearchResult total={total} subtitle="Team members" label="users" />
      <AdminHeaderActions searchInput={searchInput} setSearchInput={setSearchInput} />
    </div>
  );
}
