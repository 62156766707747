import React from "react";
import { makeStyles } from "@gfg/ui-v2/theming";

interface ProductCatalogRowProps {
  children: React.ReactNode;
}

const useStyles = makeStyles()(({ spacing, palette }) => ({
  item: {
    display: "flex",
    justifyContent: "space-between",
    padding: spacing(8, 0),
    borderBottom: `1px solid ${palette.tertiary[200]}`,
  },
}));

export default function ProductCatalogTable({ children }: ProductCatalogRowProps) {
  const { classes } = useStyles();

  return <div className={classes.item}>{children}</div>;
}
