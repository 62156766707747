/* eslint-disable import/prefer-default-export */

export function addOrRemove(array: any[], valueToBeRemoved: any) {
  const index = array.indexOf(valueToBeRemoved);

  if (index === -1) {
    return [...array, valueToBeRemoved];
  }

  const copiedArray = [...array];
  copiedArray.splice(index, 1);

  return copiedArray;
}
