import { useState } from "react";
import { List } from "@gfg/ui-v2/components/list";
import { LinearProgress } from "@gfg/ui-v2/components/linear-progress";
import { makeStyles } from "@gfg/ui-v2/theming";
import useGetUnidirectionalSynonyms from "../../hooks/use-get-unidirectional-synonyms";
import SynonymsSet from "../synonyms-set";
import DeleteModal from "../../../common/components/delete-modal";
import useDeleteSynonymsSet from "../../hooks/use-delete-synonyms-set";

interface SynonymsListProps {
  search: string;
}

const useStyles = makeStyles()(({ typography, spacing }) => ({
  title: {
    fontWeight: typography.fontWeights.semiBold,
    fontSize: typography.h4.fontSize,
    lineHeight: typography.h4.lineHeight,
    marginBottom: spacing("xs"),
  },
}));

export default function SynonymsList({ search }: SynonymsListProps) {
  const { synonyms, loading } = useGetUnidirectionalSynonyms(search);
  const [synonymsSetIdToBeDeleted, setSynonymsSetIdToBeDeleted] = useState<string | undefined>();
  const deleteSynonymsSet = useDeleteSynonymsSet();
  const { classes } = useStyles();

  const handleDeleteSet = async () => {
    await deleteSynonymsSet(synonymsSetIdToBeDeleted);
  };

  const areSynonymsReady = !(!synonyms || loading);

  return (
    <>
      <h4 className={classes.title}>All Synonyms Sets</h4>
      {areSynonymsReady ? (
        <List>
          {synonyms.map(({ id, inputs, outputs }) => (
            <SynonymsSet
              key={id}
              id={id}
              inputs={inputs}
              outputs={outputs}
              onDelete={() => setSynonymsSetIdToBeDeleted(id)}
            />
          ))}
        </List>
      ) : (
        <LinearProgress indeterminate />
      )}
      <DeleteModal
        open={synonymsSetIdToBeDeleted !== undefined}
        title="Delete Synonyms"
        text="Are you sure that you want to delete the synonyms set?"
        onToggle={() => setSynonymsSetIdToBeDeleted(undefined)}
        onDelete={handleDeleteSet}
      />
    </>
  );
}
