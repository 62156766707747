import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ spacing, colors, typography }) => ({
  container: {
    display: "flex",
    marginBottom: spacing(10),
    background: "#FFFFFF",
    border: "0.5px solid #D0D5DD",
    boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    borderRadius: spacing(4),
    padding: spacing(16),
    height: spacing(237),
  },
  imgContainer: {
    width: spacing(180),
    objectFit: "cover",
  },
  name: {
    color: colors.primary,
    fontWeight: typography.fontWeights.bold,
  },
}));

interface RecommendationsPreviewSeedItemProps {
  img: string;
}

export default function RecommendationsPreviewSeedItem({ img }: RecommendationsPreviewSeedItemProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <img className={classes.imgContainer} src={img} alt="" />
    </div>
  );
}
