import Button from "@gfg/ui-v2/components/button";
import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(theme => ({
  container: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column-reverse",
    justifyContent: "center",
    padding: theme.spacing("xl"),

    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
  },
  textContainer: {
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing("xl"),
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "642px",
    },
  },
  heading: {
    marginTop: theme.spacing("sm"),
    marginBottom: theme.spacing("md"),
  },
  subheading: {
    fontWeight: theme.typography.fontWeights.semiBold,
    color: theme.colors.primary,
    margin: "0",
  },
  text: {
    lineHeight: "30px",
    color: theme.colors.gray,
    marginTop: theme.spacing("sm"),
    marginBottom: theme.spacing("lg"),
  },
  button: {
    marginTop: theme.spacing("lg"),
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
  },
  errorImage: {
    maxWidth: "519.5px",
    width: "100%",
  },
}));

interface GeneralErrorProps {
  heading: string;
  subheading: string;
  text: React.ReactNode;
  buttonLabel: string;
  onButtonClick: () => void;
  img: string;
}

export default function GeneralError({
  heading,
  subheading,
  text,
  buttonLabel,
  onButtonClick,
  img,
}: GeneralErrorProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.textContainer}>
        <h5 className={classes.subheading}>{subheading}</h5>
        <h1 className={classes.heading}>{heading}</h1>
        <h4 className={classes.text}>{text}</h4>
        <Button variant="filled" size="large" className={classes.button} onClick={onButtonClick}>
          {buttonLabel}
        </Button>
      </div>
      <div className={classes.imageContainer}>
        <img src={img} className={classes.errorImage} alt="Error" />
      </div>
    </div>
  );
}
