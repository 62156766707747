import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../common/components/breadcrumbs";
import PageContainer from "../../../common/components/containers/page-container";
import SearchOptimizationsForm from "~/search-optimizations/components/optimizations-form";
import useSubmit from "./use-submit";

export default function CreateSearchOptimizationPage() {
  const navigate = useNavigate();
  const submit = useSubmit();
  const { region } = useParams();

  return (
    <PageContainer>
      <Breadcrumbs />
      <SearchOptimizationsForm
        onCancel={() => navigate(`/${region}/search/optimizations`)}
        formTitle="Create Search Optimization"
        onSubmit={submit}
      />
    </PageContainer>
  );
}
