import Table, { TableCell, TableHead, TableRow } from "@gfg/ui-v2/components/table";
import { LinearProgress } from "@gfg/ui-v2/components/linear-progress";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@gfg/ui-v2/theming";
import TableFooter from "../../../common/components/table/table-footer";
import useGetSearchConfigurations from "../../hooks/use-get-search-configurations";
import SearchConfigList from "../../components/search-config-table";
import TableHeader from "../../../common/components/table/table-header";
import useEventTracking from "../../../common/hooks/use-tracking-event";

const useStyles = makeStyles()(theme => ({
  tableContainer: {
    "boxShadow": theme.shadow("md"),
    "margin": `${theme.spacing(16)}`,
    "& .tableBody tr": {
      borderTopColor: "transparent",
    },
  },
  headerCell: {
    color: theme.colors.text,
    background: theme.colors.background,
  },
}));

export default function SearchConfigPage() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { region } = useParams();

  const { searchConfigurations, loading, limit, setLimit, offset, setOffset, searchInput, setSearchInput } =
    useGetSearchConfigurations();

  const sendCreateButtonAnalyticsEvent = useEventTracking({ eventName: "search-add-new-configuration" });
  const sendSearchAnalyticsEvent = useEventTracking({ eventName: "search-config-page-search" });

  const handleCreateFeed = () => {
    navigate(`/${region}/search/search-config/create`);
    sendCreateButtonAnalyticsEvent();
  };

  return (
    <div>
      <div className={classes.tableContainer}>
        <TableHeader
          total={searchConfigurations?.total}
          onSearchInputChange={setSearchInput}
          searchInput={searchInput}
          subtitle="Search Configurations"
          searchInputPlaceholder="Search"
          onSearchInputFocus={sendSearchAnalyticsEvent}
          actionButtonLabel="Create configuration"
          onActionButtonClick={handleCreateFeed}
        />
        {loading && <LinearProgress indeterminate />}
        <Table block>
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>Name</TableCell>
              <TableCell className={classes.headerCell}>UUID</TableCell>
              <TableCell className={classes.headerCell}>Last Modified</TableCell>
              <TableCell className={classes.headerCell}>Status</TableCell>
              <TableCell className={classes.headerCell} />
            </TableRow>
          </TableHead>
          {searchConfigurations?.data && <SearchConfigList searchConfigurations={searchConfigurations?.data} />}
        </Table>
        <TableFooter
          onOffsetChange={setOffset}
          offset={offset}
          total={searchConfigurations?.total}
          limit={limit.toString()}
          onLimitChange={setLimit}
        />
      </div>
    </div>
  );
}
