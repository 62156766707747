import { ResourceName, Role } from "@coral/typings";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { useMemo } from "react";
import reactiveVariables from "../common/graphql/global-reactive-variables";

import ForbiddenPage from "../common/pages/forbidden-page";
import { useAuth } from "./auth-provider";

interface RequireAuthProps {
  roles?: Role[];
  section?: ResourceName;
}

export default function RequireAuth({ roles, section, children }: React.PropsWithChildren<RequireAuthProps>) {
  const { viewer } = useAuth();
  const { region } = useParams();

  const hasAccessToSection = useMemo(
    () => !section || viewer?.resources.some(r => r.name === section),
    [section, viewer],
  );

  const hasAccessToRegion = useMemo(
    () => !region || viewer?.role === "master" || viewer?.environments?.some(e => e.key === region),
    [region, viewer],
  );

  const hasRequiredRole = useMemo(() => !roles?.length || roles?.includes(viewer.role), [roles, viewer]);

  if (!viewer) {
    return <Navigate to="/login" />;
  }

  if (!hasRequiredRole || !hasAccessToSection) {
    return <ForbiddenPage />;
  }

  if (!hasAccessToRegion) {
    reactiveVariables.selectedEnvironmentIdVar(undefined); // clear default environment to force loading any env user has access to
    return <ForbiddenPage />;
  }

  return (children as JSX.Element) || <Outlet />;
}
