import { useParams } from "react-router-dom";

function useWithRegion() {
  const { region } = useParams();

  return (url: string | undefined) => {
    if (url === undefined) {
      return undefined;
    }

    if (url.startsWith("/")) {
      return `/${region}${url}`;
    }

    return `/${region}/${url}`;
  };
}

export default useWithRegion;
