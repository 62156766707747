/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useReactiveVar } from "@apollo/client";
import { SearchConfigurationShort } from "@coral/typings";
import { TableCell, TableRow } from "@gfg/ui-v2/components/table";
import sortBy from "lodash.sortby";
import { useCallback } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { makeStyles } from "@gfg/ui-v2/theming";
import TitleSubtitleCell from "../../../../common/components/table/cells/title-subtitle-cell";
import GrayStatusTag from "../../../../common/components/status-tags/gray-tag";
import SuccessStatusTag from "../../../../common/components/status-tags/success-tag";
import reactiveVariables from "../../../../common/graphql/global-reactive-variables";
import useAppNotifications from "../../../../common/hooks/use-app-notifications";
import useCopyClipboard from "../../../../common/hooks/use-copy-clipboard";
import date from "../../../../common/services/dates";
import { useDeleteSearchConfigurationMutation } from "../../../../generated";
import ActionCell from "../cells/action-cell";

export interface RowProps {
  searchConfig: SearchConfigurationShort;
  index: number;
}

const useStyles = makeStyles()(({ colors, palette }) => ({
  rowGrey: {
    background: palette.gray[100],
  },
  rowLight: {
    background: colors.background,
  },
  iconButton: {
    height: 0,
    paddingLeft: 0,
  },
  uuidContainer: {
    color: colors.gray,
    cursor: "pointer",
  },
}));

export default function Row({ searchConfig, index }: RowProps) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { region } = useParams();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const { addSuccessNotification } = useAppNotifications();
  const { copyToClipboard } = useCopyClipboard();

  const [deleteSearchConfig] = useDeleteSearchConfigurationMutation({ refetchQueries: ["GetSearchConfigurations"] });

  const handleCopyToClipboard = useCallback(async (text: string, value: string) => {
    const response = await copyToClipboard(value);
    if (response) {
      addSuccessNotification(`Copied ${text} to clipboard`, value);
    }
  }, []);

  const lastModifiedVersion = sortBy(searchConfig.versions, v => v.updatedAt || v.createdAt).reverse()[0];

  const statusTag = searchConfig.versions.some(v => v.status === "active") ? (
    <SuccessStatusTag label="Active" />
  ) : (
    <GrayStatusTag label="Draft" />
  );

  return (
    <TableRow key={searchConfig.id} className={index % 2 === 0 ? classes.rowGrey : classes.rowLight}>
      <TitleSubtitleCell
        title={
          <Link to={`/${region}/search/search-config/${searchConfig.id}/versions`}>
            {searchConfig.name.slice(0, 60)}
          </Link>
        }
        subtitle={<span>{searchConfig.versions.length} versions</span>}
      />
      <TableCell>
        <div>
          <p className={classes.uuidContainer} onClick={() => handleCopyToClipboard("UUID: ", searchConfig.id)}>
            {searchConfig.id}
          </p>
        </div>
      </TableCell>
      {lastModifiedVersion.modifiedBy ? (
        <TitleSubtitleCell
          title={lastModifiedVersion.modifiedBy}
          subtitle={date.format(date.fromSecondsSinceEpoch(lastModifiedVersion.updatedAt))}
        />
      ) : (
        <TitleSubtitleCell
          title={lastModifiedVersion.createdBy}
          subtitle={date.format(date.fromSecondsSinceEpoch(lastModifiedVersion.createdAt))}
        />
      )}
      <TableCell>
        <div>
          <span>{statusTag}</span>
        </div>
      </TableCell>
      <ActionCell
        onCopy={() => handleCopyToClipboard("Config URL: ", searchConfig.url)}
        onDuplicate={() => navigate(`/${region}/search/search-config/duplicate/${searchConfig.id}`)}
        onEdit={() => navigate(`/${region}/search/search-config/${searchConfig.id}/versions`)}
        onDelete={() =>
          deleteSearchConfig({
            variables: { input: { configurationId: searchConfig.id, projectId: selectedEnvironmentProjectId } },
          })
        }
      />
    </TableRow>
  );
}
