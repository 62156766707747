import { makeStyles } from "@gfg/ui-v2/theming";
import { useState } from "react";
import Title from "../common/components/title";
import SynonymsList from "./components/synonyms-list/synonyms-list";
import SynonymsHeader from "./components/synonyms-header/synonyms-header";

const useStyles = makeStyles()(({ spacing }) => ({
  container: {
    margin: spacing(20, 16),
  },
}));

export default function UnidirectionalSynonymsPage() {
  const [searchValue, setSearchValue] = useState("");
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Title text="Unidirectional Synonyms" />
      <p>Unidirectional Synonyms operate in a single direction but not in the reverse direction.</p>
      <SynonymsHeader searchValue={searchValue} setSearchValue={setSearchValue} />
      <SynonymsList search={searchValue} />
    </div>
  );
}
