/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { InputField } from "@gfg/ui-v2/components/input";
import { Controller, useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { useCallback } from "react";
import Radio, { RadioGroup } from "@gfg/ui-v2/components/radio";
import { SearchOptimizationState } from "@coral/typings";
import SectionTitle from "../../../common/components/recommendations/section-title";
import SectionLabel from "../../../common/components/recommendations/section-label";
import useStyles from "./use-styles";
import preventSubmitOnEnter from "../../../common/utils/form/prevent-submit-on-enter";
import searchOptimizationValidationSchema from "./validation-schema";
import ErrorMessage from "~/common/components/error-message";
import { SearchOptimizationStateTranslations } from "~/common/translations";
import SearchOptimizationStateMap from "~/common/constants/search-optimization-state";
import SearchOptimizationsFormHeader from "./header";

export interface FormData {
  query: string;
  output: string;
  state: SearchOptimizationState;
  createdAt?: Date;
}

export interface SearchConfigFormProps {
  onSubmit(formData: FormData): Promise<void>;
  onCancel(): void;
  formTitle: string;
  defaultValues?: FormData;
  allowImmediateSubmit?: boolean;

  readOnly?: boolean;
  queryFieldDisabled?: boolean;
}

export default function SearchOptimizationsForm({
  onSubmit,
  onCancel,
  formTitle,
  allowImmediateSubmit,
  defaultValues,
  readOnly = false,
  queryFieldDisabled = false,
}: SearchConfigFormProps) {
  const { classes, cx } = useStyles();
  const methods = useForm<FormData>({
    resolver: yupResolver(searchOptimizationValidationSchema),
    mode: "onChange",
    defaultValues: defaultValues ?? {
      output: "",
      query: "",
      state: "Pending",
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
  } = methods;

  const handleSubmitAndResetForm: SubmitHandler<FormData> = useCallback(
    async formData => {
      await onSubmit(formData);
      reset({}, { keepValues: true });
    },
    [onSubmit, reset],
  );

  const isSubmitDisabled = readOnly || (allowImmediateSubmit ? !isValid : !isDirty || !isValid);
  return (
    <FormProvider {...methods}>
      <form onKeyDown={preventSubmitOnEnter} onSubmit={handleSubmit(handleSubmitAndResetForm)}>
        <SearchOptimizationsFormHeader title={formTitle} onCancel={onCancel} disabledSubmit={isSubmitDisabled} />
        <div className={classes.generalSettings}>
          <SectionTitle
            title="General Information"
            description="This section contains fields for general information about the Search Query Optimiser. This information helps to identify the rule by query, suggestion, and status."
          />
          <div className={cx(classes.inputField, classes.textField, classes.verticallyMiddle)}>
            <SectionLabel title="Search Query" description="Query/Keyterm for which suggestion is applied" />
            <Controller
              name="query"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  disabled={readOnly || queryFieldDisabled}
                  hint={errors.query?.message}
                  placeholder="Query"
                  block
                />
              )}
            />
          </div>
          <div className={cx(classes.inputField, classes.textField, classes.verticallyMiddle)}>
            <SectionLabel
              title="Contextual Suggestion"
              description="Suggestion that is contextually matching with user intent"
            />
            <Controller
              name="output"
              control={control}
              render={({ field }) => (
                <InputField {...field} disabled={readOnly} hint={errors.query?.message} placeholder="Output" block />
              )}
            />
          </div>
          <div className={cx(classes.inputField, classes.selectField, classes.verticallyMiddle)}>
            <SectionLabel
              title="Status"
              description="Defines the state of suggestion, Enabled means the optimization is live"
            />
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  style={{ display: "flex", flexDirection: "column" }}
                  className={classes.stateRadioGroup}
                >
                  {Object.values(SearchOptimizationStateMap).map(stateValue => (
                    <Radio key={stateValue} color="primary" value={stateValue}>
                      {SearchOptimizationStateTranslations[stateValue]}
                    </Radio>
                  ))}
                </RadioGroup>
              )}
            />
            <ErrorMessage message={errors.state?.message?.toString()} />
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
