import React, { useCallback, useMemo, useState } from "react";
import Menu, { MenuItem } from "@gfg/ui-v2/components/menu";
import { makeStyles } from "@gfg/ui-v2/theming";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../../auth/auth-provider";
import useMenuConfigList from "./use-menu-config-list";
import useWithRegion from "~/common/hooks/use-with-region";
import { MenuItemConfig } from "~/common/constants/menu-config-list";

const useStyles = makeStyles()(({ palette, colors }) => ({
  menuListContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    minWidth: 0,
    overflow: "visible",
  },
  leftIcon: {
    color: colors.gray,
  },
  rightIcon: {
    color: palette.gray[200],
  },
}));

interface SidebarProps {
  open: boolean;
  onToggle(): void;
}

export default function SidebarMenu({ open, onToggle }: SidebarProps) {
  const { classes } = useStyles();
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({
    search: true,
    recommendations: true,
  });
  const { viewer } = useAuth();
  const location = useLocation();
  const withRegion = useWithRegion();
  const userIsAdmin = viewer?.role === "master" || viewer?.role === "regional_manager";

  const menuConfigList = useMenuConfigList();

  const toggleCategory = useCallback(
    (id: string) => {
      setExpanded(prevState => ({ ...prevState, [id]: !prevState[id] }));
    },
    [expanded],
  );

  const renderMenuItem = useCallback(
    ({ text, linkTo, external, reloadDocument, leftIcon: LeftIcon, subcategories = [], id }: MenuItemConfig) => {
      return subcategories?.length ? (
        <MenuItem
          key={id}
          leftIcon={<LeftIcon className={classes.leftIcon} />}
          onClick={() => toggleCategory(id)}
          active={false}
          label={text}
        >
          <Menu open={expanded[id]}>
            {subcategories!.map(subcategory => (
              <MenuItem
                label={
                  subcategory.external ? (
                    <a href={subcategory.linkTo} target="_blank" rel="noreferrer">
                      {subcategory.text}
                    </a>
                  ) : (
                    <Link
                      to={withRegion(subcategory.linkTo)}
                      reloadDocument={subcategory.reloadDocument}
                      onClick={e => {
                        e.stopPropagation();
                        onToggle();
                      }}
                    >
                      {subcategory.text}
                    </Link>
                  )
                }
                key={subcategory.id}
                active={location.pathname.includes(withRegion(subcategory.linkTo))}
              />
            ))}
          </Menu>
        </MenuItem>
      ) : (
        <MenuItem
          key={id}
          leftIcon={<LeftIcon className={classes.leftIcon} />}
          active={location.pathname.includes(withRegion(linkTo))}
          label={
            external ? (
              <a href={linkTo} target="_blank" rel="noreferrer">
                {text}
              </a>
            ) : (
              <Link reloadDocument={reloadDocument} to={withRegion(linkTo)}>
                {text}
              </Link>
            )
          }
        />
      );
    },
    [expanded, classes, location.pathname, onToggle, withRegion, toggleCategory],
  );

  const mainList = useMemo(
    () => menuConfigList.filter(cat => !cat.bottom && (userIsAdmin || !cat.admin)).map(renderMenuItem),
    [renderMenuItem],
  );
  const bottomList = useMemo(
    () => menuConfigList.filter(cat => cat.bottom && (userIsAdmin || !cat.admin)).map(renderMenuItem),
    [renderMenuItem],
  );
  return (
    <Menu className={classes.menuListContainer} collapsed={!open}>
      {mainList}
      {bottomList}
    </Menu>
  );
}
