import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApolloError, useReactiveVar } from "@apollo/client";

import { CustomFeedState } from "@coral/typings";
import extractGraphqlError from "common/utils/apollo/extract-graphql-error";
import { useCreateFeedMutation } from "../../../generated";
import { FormData } from "../feeds-form";
import serializeFeedFilters from "../../../common/utils/feed-filters/serialize-feed-filters";
import useAppNotifications from "../../../common/hooks/use-app-notifications";
import reactiveVariables from "../../../common/graphql/global-reactive-variables";

function useSubmit() {
  const { addSuccessNotification, addErrorNotification, removeNotification, addInProgressNotification } =
    useAppNotifications();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);
  const navigate = useNavigate();
  const { region } = useParams();

  const [createFeed] = useCreateFeedMutation({
    refetchQueries: ["GetFeeds"],
  });

  return useCallback(
    async (formData: FormData) => {
      let notificationId;

      try {
        notificationId = addInProgressNotification("Duplicating feed");

        const response = await createFeed({
          variables: {
            feed: {
              orderStrategy: formData.orderStrategy,
              feedStrategy: formData.feedStrategy,
              feedName: formData.feedName,
              placement: Number.parseInt(formData.placement, 10),
              feedFilters: serializeFeedFilters(formData.feedFilters ?? []),
              scenario: formData.scenario,
              screenName: formData.screenName,
              projectId: selectedEnvironmentProjectId,
              state: formData.feedStatus as CustomFeedState,
            },
          },
        });

        addSuccessNotification("Feed was duplicated successfully");
        if (response.data?.createFeed?.id) {
          navigate(`/${region}/recommendations/feed/edit/${response.data?.createFeed?.id}`);
        }
      } catch (error) {
        const message = extractGraphqlError(error as ApolloError);
        addErrorNotification("Error while duplicating feed", message);
      } finally {
        if (notificationId) {
          removeNotification(notificationId);
        }
      }
    },
    [navigate, createFeed],
  );
}

export default useSubmit;
