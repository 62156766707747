import React, { useEffect } from "react";
import { Pagination } from "@gfg/ui-v2/components/pagination";
import { makeStyles } from "@gfg/ui-v2/theming";
import ViewPerPage from "../view-per-page";

const useStyles = makeStyles()(({ spacing, colors, palette }) => ({
  footerContainer: {
    padding: spacing(16, 0),
    borderTop: `1px solid ${palette.tertiary[200]}`,
    background: colors.background,
  },
  pagination: {
    justifyContent: "center",
  },
}));

interface TableFooterProps {
  total: number;
  offset: number;
  limit: string;

  onLimitChange: (limit: number) => void;
  onOffsetChange: (offset: number) => void;
}

export default function TableFooter({ total, offset, onOffsetChange, limit, onLimitChange }: TableFooterProps) {
  const { classes } = useStyles();

  const handleOffsetChange = (offset: number) => {
    onOffsetChange(offset);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (Math.ceil(total / offset) === 1) {
      onOffsetChange(0);
    }
  }, [total, onOffsetChange]);

  return (
    <div className={classes.footerContainer}>
      <ViewPerPage limit={limit} setLimit={(limit: string) => onLimitChange(+limit)} />
      <Pagination
        count={+limit}
        className={classes.pagination}
        size="medium"
        skip={offset}
        total={total}
        onChange={handleOffsetChange}
      />
    </div>
  );
}
