/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useCallback } from "react";
import { InputField } from "@gfg/ui-v2/components/input";
import { Option, SelectField } from "@gfg/ui-v2/components/select";
import { CheckIcon } from "@gfg/ui-v2/icons";
import { Radio, RadioGroup } from "@gfg/ui-v2/components/radio";
import { Controller, FormProvider, useForm, useWatch, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useParams } from "react-router-dom";
import CustomFeedStateMap from "common/constants/custom-feed-state";
import OrderStrategy from "../../../common/constants/order-strategy";
import FeedsCreateActions from "./feeds-create-actions";
import ErrorMessage from "../../../common/components/error-message";
import SectionTitle from "../../../common/components/recommendations/section-title";
import feedsValidationSchema from "./feeds-validation-schema";
import SectionLabel from "../../../common/components/recommendations/section-label";
import useStyles from "./use-styles";
import { ConditionalFilterFormValue } from "../../../common/components/form-filters/conditional-filters";
import FeedStrategy, { RequiredPreviewFields } from "../../../common/constants/feed-strategy";
import {
  CustomFeedStateTranslations,
  FeedStrategyTranslations,
  OrderStrategyTranslations,
  ScenarioTranslations,
  ScreenNameTranslations,
} from "../../../common/translations";
import ScreenName from "../../../common/constants/screen-names";
import preventSubmitOnEnter from "../../../common/utils/form/prevent-submit-on-enter";
import RecommendationsPreview from "../../../common/components/recommendations/recommendations-preview";
import useEventTracking from "../../../common/hooks/use-tracking-event";
import FeedFilters from "./feed-filters";
import { Scenario } from "../../../common/components/form-filters/conditional-filters/constants";
import useVisiblePreviewFields from "../../../common/components/recommendations/hooks/use-visible-preview-fields";

export interface FormData {
  feedName: string;
  feedStrategy: string[];
  orderStrategy: string;
  feedFilters: ConditionalFilterFormValue[];
  scenario: string;
  screenName: string;
  placement: string;
  feedStatus: string;
}

export interface FeedsFormProps {
  onSubmit(formData: FormData): Promise<void>;
  formTitle: string;
  defaultValues?: FormData;
  allowImmediateSubmit?: boolean;
  allowPreview?: boolean;
}

export default function FeedsForm({
  onSubmit,
  formTitle,
  defaultValues,
  allowImmediateSubmit,
  allowPreview,
}: FeedsFormProps) {
  const { classes, cx } = useStyles();
  const sendAddFeedFilterAnalyticsEvent = useEventTracking({ eventName: "recos-onsite-add-filter" });

  const methods = useForm<FormData>({
    resolver: yupResolver(feedsValidationSchema),
    mode: "onChange",
    defaultValues: defaultValues ?? {
      feedName: "",
      feedStatus: "Draft",
      scenario: "",
      screenName: "",
      feedStrategy: [],
      feedFilters: [],
      orderStrategy: "",
      placement: "",
    },
  });

  const handleSubmitAndResetForm: SubmitHandler<FormData> = useCallback(
    async formData => {
      await onSubmit(formData);
      methods.reset({}, { keepValues: true });
    },
    [onSubmit, methods.reset],
  );

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
  } = methods;
  const maxPlacementPosition = 10;
  const { feedId } = useParams();
  const selectedFeedStrategies = useWatch({ control, name: "feedStrategy" });
  const { showIdField, showSkuField } = useVisiblePreviewFields(selectedFeedStrategies, RequiredPreviewFields);

  const showOrderStrategySelectField = selectedFeedStrategies.length > 1;

  return (
    <FormProvider {...methods}>
      <RecommendationsPreview
        feedPreviewType="product"
        title={defaultValues?.feedName}
        feedId={feedId}
        showIdField={showIdField}
        showSkuField={showSkuField}
        strategyName={selectedFeedStrategies.map(s => FeedStrategyTranslations[s]).join(", ")}
      />

      <form onKeyDown={preventSubmitOnEnter} onSubmit={handleSubmit(handleSubmitAndResetForm)}>
        <FeedsCreateActions
          title={formTitle}
          disabledSubmit={allowImmediateSubmit ? !isValid : !isDirty || !isValid}
          allowPreview={allowPreview}
        />
        <div className={classes.generalSettings}>
          <SectionTitle
            title="General Information"
            description="This section contains fields for general information about the configuration. This information helps to identify the configuration by its name and also determine its key behavior"
          />
          <div className={cx(classes.inputField, classes.textField, classes.verticallyMiddle)}>
            <SectionLabel
              title="Feed name"
              description="The feed name helps to identify the configuration. This is shown in the table, and users can search for this configuration by this name."
            />
            <Controller
              name="feedName"
              control={control}
              render={({ field }) => (
                <InputField {...field} hint={errors.feedName?.message?.toString()} placeholder="Feed name" block />
              )}
            />
          </div>
          <div className={cx(classes.inputField, classes.textField, classes.verticallyMiddle)}>
            <SectionLabel title="Feed status" description="Status helps to identify and sort configurations." />
            <Controller
              name="feedStatus"
              control={control}
              render={({ field }) => (
                <RadioGroup {...field} className={cx(classes.radioGroup, classes.orderStrategy)}>
                  {Object.values(CustomFeedStateMap).map(feedStateValue => (
                    <Radio key={feedStateValue} color="primary" value={feedStateValue}>
                      {CustomFeedStateTranslations[feedStateValue]}
                    </Radio>
                  ))}
                </RadioGroup>
              )}
            />
            <ErrorMessage message={errors.orderStrategy?.message?.toString()} />
          </div>
          <div className={cx(classes.inputField, classes.verticallyMiddle)}>
            <SectionLabel
              title="Feed strategy"
              description={
                <>
                  The feed strategy determines how this configuration will fetch the set of products. Users can add more
                  than one strategy here. <br />
                  <a target="_blank" href="/docs/recommendation-system/plug-and-play-strategies">
                    Read more about the feed strategy
                  </a>
                </>
              }
            />
            <Controller
              name="feedStrategy"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectField
                  placeholder="Choose feed strategy"
                  block
                  multiple
                  value={value}
                  onChange={onChange}
                  hint={(errors.feedStrategy as any)?.message}
                >
                  {Object.values(FeedStrategy).map(strategyValue => (
                    <Option key={strategyValue} text={FeedStrategyTranslations[strategyValue]} value={strategyValue}>
                      <div className={classes.optionContainer}>
                        <span className={classes.optionName}>{FeedStrategyTranslations[strategyValue]}</span>
                        {value?.includes(strategyValue) && <CheckIcon className={classes.icon} />}
                      </div>
                    </Option>
                  ))}
                </SelectField>
              )}
            />
          </div>
          {showOrderStrategySelectField && (
            <div className={cx(classes.inputField, classes.verticallyMiddle)}>
              <SectionLabel
                title="Order strategy"
                description={
                  <>
                    When you add more than one Feed Strategy, the Order strategy helps to determine the order of
                    products from different strategies in the result. <br />
                    <a target="_blank" href="/docs/recommendation-system/feed-config#set-the-order-strategy">
                      Read more about the order strategy
                    </a>
                  </>
                }
              />
              <Controller
                name="orderStrategy"
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    style={{ display: "flex", flexDirection: "column" }}
                    className={classes.orderStrategy}
                  >
                    {Object.values(OrderStrategy).map(strategyValue => (
                      <Radio key={strategyValue} color="primary" value={strategyValue}>
                        {OrderStrategyTranslations[strategyValue]}
                      </Radio>
                    ))}
                  </RadioGroup>
                )}
              />
              <ErrorMessage message={errors.orderStrategy?.message?.toString()} />
            </div>
          )}
          <SectionTitle
            title="Feed filters"
            description={
              <>
                Feed filters help us to either include or exclude a set of products based on the defined rules. When
                users add inclusive rules, only products from the defined set are shown. When users add exclusive rules,
                all the products defined by that rule are removed from the results. <br />
                <a target="_blank" href="/docs/recommendation-system/feed-config#integrate-feed-filters">
                  Read more about Feed filters
                </a>
              </>
            }
          />
          <FeedFilters onAddFeedFilter={sendAddFeedFilterAnalyticsEvent} />
          <SectionTitle
            title="Placement settings"
            description={
              <>
                Datajet collects this information to optimize the ML models to recommend better products for these
                positions <br />
                <a target="_blank" href="/docs/recommendation-system/feed-config#tweak-placement-settings">
                  Read more about Placement settings
                </a>
              </>
            }
          />
          <div className={cx(classes.inputField, classes.textField, classes.verticallyMiddle)}>
            <SectionLabel
              title="Device"
              description="Please select where you are planning to use this configuration."
            />
            <Controller
              name="scenario"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectField
                  placeholder="Choose scenario"
                  block
                  value={value}
                  onChange={(value: string) => onChange(value)}
                  hint={errors.scenario?.message?.toString()}
                >
                  <Option text="--empty--" value="">
                    --empty--
                  </Option>

                  {Object.values(Scenario).map(scenarioValue => (
                    <Option key={scenarioValue} text={ScenarioTranslations[scenarioValue]} value={scenarioValue}>
                      <div className={classes.optionContainer}>
                        <span className={classes.optionName}>{ScenarioTranslations[scenarioValue]}</span>
                        {value?.includes(scenarioValue) && <CheckIcon className={classes.icon} />}
                      </div>
                    </Option>
                  ))}
                </SelectField>
              )}
            />
          </div>
          <div className={cx(classes.inputField, classes.textField, classes.verticallyMiddle)}>
            <SectionLabel
              title="Screen Name"
              description="Datajet Please select which screen you are going to use this configuration on."
            />
            <Controller
              name="screenName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectField
                  placeholder="Choose screen name"
                  block
                  value={value}
                  onChange={(value: string) => onChange(value)}
                  hint={errors.screenName?.message?.toString()}
                >
                  <Option text="--empty--" value="">
                    --empty--
                  </Option>

                  {Object.values(ScreenName).map(screenNameValue => (
                    <Option
                      key={screenNameValue}
                      text={ScreenNameTranslations[screenNameValue]}
                      value={screenNameValue}
                    >
                      <div className={classes.optionContainer}>
                        <span className={classes.optionName}>{ScreenNameTranslations[screenNameValue]}</span>
                        {value?.includes(screenNameValue) && <CheckIcon className={classes.icon} />}
                      </div>
                    </Option>
                  ))}
                </SelectField>
              )}
            />
          </div>
          <div className={cx(classes.inputField, classes.textField, classes.verticallyMiddle)}>
            <SectionLabel
              title="Placement Position"
              description={
                <>
                  Please specify in which position you are placing this recommendation on the page/screen. <br />
                  <a target="_blank" href="/docs/recommendation-system/feed-config#tweak-placement-settings">
                    Read more about Placement settings
                  </a>
                </>
              }
            />
            <Controller
              name="placement"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectField
                  placeholder="Choose placement position"
                  block
                  value={value}
                  onChange={(value: string) => onChange(value)}
                  hint={errors.placement?.message?.toString()}
                >
                  <Option text="--empty--" value="">
                    --empty--
                  </Option>

                  {[...Array.from({ length: maxPlacementPosition })].map((_, index) => {
                    const placementPositionString = String(index + 1);

                    return (
                      <Option
                        key={placementPositionString}
                        text={placementPositionString}
                        value={placementPositionString}
                      >
                        <div className={classes.optionContainer}>
                          <span className={classes.optionName}>{placementPositionString}</span>
                          {value?.includes(placementPositionString) && <CheckIcon className={classes.icon} />}
                        </div>
                      </Option>
                    );
                  })}
                </SelectField>
              )}
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
