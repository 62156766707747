import { ConditionRuleWithInputType } from "@coral/typings";
import { useCallback, useMemo } from "react";
import { ComponentProps } from "../../dynamic-filter-component";

interface Attribute {
  id: string;
  label?: string;
  value?: string;
  type?: string;
  rules?: ConditionRuleWithInputType[];
}

interface FormValue {
  type: string;
  rule?: string;
  value?: ComponentProps["value"];
}

function getIfAllRulesOnAttributeAreUsed(attribute: Attribute, usedFilters: FormValue[]) {
  return attribute.rules.every(rule =>
    usedFilters.some(filter => filter.type === attribute.value && filter.rule === rule.value),
  );
}

function getIfAttributeHasBeenUsedMoreTimesThanItHasRulesCount(attribute: Attribute, usedFilters: FormValue[]) {
  return usedFilters.filter(filter => filter.type === attribute.value).length >= attribute.rules.length;
}

function useFeedFilterAvailability(usedFilters: FormValue[], availableAttributes: Attribute[]) {
  const alreadyUsedAttributesMap = useMemo(() => {
    const alreadyUsedAttributeOptions = availableAttributes.filter(
      attribute =>
        getIfAllRulesOnAttributeAreUsed(attribute, usedFilters) ||
        getIfAttributeHasBeenUsedMoreTimesThanItHasRulesCount(attribute, usedFilters),
    );

    return Object.fromEntries(alreadyUsedAttributeOptions.map(ao => [ao.value, true]));
  }, [availableAttributes, usedFilters]);

  const isAttributeOptionDisabled = useCallback(
    (value: string) => alreadyUsedAttributesMap[value],
    [alreadyUsedAttributesMap],
  );

  const isRuleOptionDisabled = useCallback(
    (attributeValue: string, ruleValue: string) =>
      usedFilters.some(filter => filter.type === attributeValue && filter.rule === ruleValue),
    [usedFilters],
  );

  return {
    isAttributeOptionDisabled,
    isRuleOptionDisabled,
  };
}

export default useFeedFilterAvailability;
