import { useEffect, useState } from "react";

import { useReactiveVar } from "@apollo/client";
import pagination from "../../../../common/constants/pagination";
import reactiveVariables from "../../../../common/graphql/global-reactive-variables";
import { useGetSearchPreviewLazyQuery } from "../../../../generated";

interface UsePreviewProps {
  configId: string;
  versionId: string;
  limit?: number;
  offset?: number;
}

export default function usePreview({ configId, versionId }: UsePreviewProps) {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(+pagination.LIMITS[pagination.LIMITS.length - 1]);
  const [uuid, setUuid] = useState("");
  const [query, setQuery] = useState("");

  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const [getSearchPreview, { data, previousData, loading }] = useGetSearchPreviewLazyQuery({
    fetchPolicy: "network-only",
    variables: {
      previewParams: {
        configId,
        projectId: selectedEnvironmentProjectId,
        versionId,
        limit,
        offset,
        query,
        uuid,
      },
      searchConfigParams: {
        id: configId,
        projectId: selectedEnvironmentProjectId,
      },
    },
  });

  const previewData = (data ?? previousData)?.searchConfigPreview;
  const configData = (data ?? previousData)?.searchConfiguration;

  useEffect(() => {
    const timerId = setTimeout(() => {
      getSearchPreview({
        variables: {
          previewParams: {
            configId,
            projectId: selectedEnvironmentProjectId,
            versionId,
            limit,
            offset,
            query,
            uuid,
          },
          searchConfigParams: {
            id: configId,
            projectId: selectedEnvironmentProjectId,
          },
        },
      });
    }, 1);

    return () => {
      clearTimeout(timerId);
    };
  }, [query, uuid, configId, versionId, offset, limit]);

  return {
    getSearchPreview,
    previewData,
    configData,
    setUuid,
    uuid,
    setQuery,
    query,
    loading,
    offset,
    setOffset,
    limit,
    setLimit,
  };
}
