import { useNavigate, useParams } from "react-router-dom";
import CampaignForm, { FormData } from "campaigns/components/campaign-form";
import Breadcrumbs from "../../../common/components/breadcrumbs";
import PageContainer from "../../../common/components/containers/page-container";
import useSubmit from "./use-submit";

export default function CreateSearchConfigurationPage() {
  const navigate = useNavigate();
  const submit = useSubmit();
  const { region } = useParams();

  const handleSubmit = async (formData: FormData) => {
    await submit(formData);
  };

  return (
    <PageContainer>
      <Breadcrumbs />
      <CampaignForm
        onCancel={() => navigate(`/${region}/merchandising`)}
        formTitle="Create Marchandising Rule"
        onSubmit={handleSubmit}
      />
    </PageContainer>
  );
}
