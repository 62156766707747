import { useMemo } from "react";
import { useReactiveVar } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../common/components/breadcrumbs";
import { useGetSearchConfigurationQuery } from "../../../generated";
import SearchConfigForm, { FormData } from "../../components/search-config-form";
import { isSuccessfulResponse } from "../search-config-versions";
import reactiveVariables from "../../../common/graphql/global-reactive-variables";
import useSubmit from "./use-submit";
import PageContainer from "../../../common/components/containers/page-container";
import deserializeAttributeFilter from "../../../common/utils/search-config/deserialize-attribute-filter";
import deserializeAttributeFilterWithRule from "../../../common/utils/search-config/deserialize-attribute-filter-with-rule";
import deserializeConditionalFilter from "../../../common/utils/search-config/deserialize-conditional-filter";

export default function DuplicateSearchConfigurationVersionPage() {
  const navigate = useNavigate();
  const { configId, versionId, region } = useParams();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const submit = useSubmit();

  const { data } = useGetSearchConfigurationQuery({
    variables: {
      params: {
        id: configId,
        projectId: selectedEnvironmentProjectId,
      },
    },
  });

  const handleSubmit = async (formData: FormData) => {
    await submit(configId, formData);
  };

  const defaultValues = useMemo<FormData>(() => {
    if (isSuccessfulResponse(data?.searchConfiguration)) {
      const version = data?.searchConfiguration.versions.find(v => v.id === versionId);

      if (version) {
        return {
          configurationName: data?.searchConfiguration.name,
          versionName: version.name,
          versionDescription: version.description,
          conditionFilters: version.filterAttributes.map(deserializeConditionalFilter),
          personalisationAttributes: version.personalisedAttributes.map(deserializeAttributeFilter),
          rankAttributes: version.rankAttributes.map(deserializeAttributeFilterWithRule),
        };
      }
    }
  }, [data]);

  return (
    <PageContainer>
      <Breadcrumbs />
      {isSuccessfulResponse(data?.searchConfiguration) && defaultValues && (
        <SearchConfigForm
          onCancel={() => navigate(`/${region}/search/search-config/${configId}/versions`)}
          formTitle="Duplicate Version"
          onSubmit={handleSubmit}
          defaultValues={defaultValues}
          allowImmediateSubmit
        />
      )}
    </PageContainer>
  );
}
