import React, { DetailedHTMLProps, ImgHTMLAttributes, useRef, useState, useEffect, useCallback } from "react";

export default function ImageWithPlaceholder({
  src,
  placeholderSrc,
  onLoad,
  ...props
}: {
  onLoad?: () => void;
  placeholderSrc?: string;
} & DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);
  // Store the onLoad prop in a ref to stop new Image() from re-running
  const onLoadRef = useRef(onLoad);
  useEffect(() => {
    onLoadRef.current = onLoad;
  }, [onLoad]);

  const onLoadListener = useCallback(() => {
    setImgSrc(src);
    if (onLoadRef.current) {
      onLoadRef.current();
    }
  }, [src]);

  useEffect(() => {
    const img = new Image();

    img.addEventListener("load", onLoadListener);
    img.src = src;

    return () => {
      img.removeEventListener("load", onLoadListener);
    };
  }, [src]);

  return (
    <div>
      <img src={imgSrc} {...props} alt="preview" />
    </div>
  );
}
