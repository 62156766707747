import { makeStyles } from "@gfg/ui-v2/theming";
import { useMemo } from "react";
import SkuButton from "~/common/components/sku-button";

const useStyles = makeStyles()(({ spacing, colors, typography }) => ({
  container: {
    display: "flex",
    marginBottom: spacing(10),
    background: "#FFFFFF",
    border: "0.5px solid #D0D5DD",
    boxShadow: "0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)",
    borderRadius: spacing(4),
    padding: spacing(16),
    width: spacing(480),
    height: spacing(268),
  },
  imgContainer: {
    width: spacing(180),
    objectFit: "cover",
  },
  itemsContainer: {
    flex: 3,
    paddingLeft: spacing(12),
  },
  item: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: spacing(7),
    borderBottom: `1px solid ${colors.tertiary[200]}`,
  },
  name: {
    color: colors.primary,
    fontWeight: typography.fontWeights.bold,
  },
  skuItem: {
    marginLeft: spacing("xs"),
  },
  tag: {
    cursor: "pointer",
  },
}));

interface RecommendationsPreviewItemProps {
  items: Array<{ name: string; value: string }>;
  img: string;
}

export default function RecommendationsPreviewItem({ items, img }: RecommendationsPreviewItemProps) {
  const { classes, cx } = useStyles();
  const skuItem = useMemo(() => items.find(item => item.name === "SKU"), []);

  return (
    <div className={classes.container}>
      <img className={classes.imgContainer} src={img} alt="" />

      <div className={classes.itemsContainer}>
        {skuItem && (
          <div className={cx(classes.item, classes.skuItem)} key={skuItem.name}>
            <span className={classes.name}>{skuItem.name}</span>
            <SkuButton className={classes.skuItem} value={skuItem.value} size="small" />
          </div>
        )}
        {items
          .filter(item => item.name !== "SKU")
          .map(item => (
            <div className={classes.item} key={item.name}>
              <span className={classes.name}>{item.name}</span> <span>{item.value}</span>
            </div>
          ))}
      </div>
    </div>
  );
}
