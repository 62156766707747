import { IconButton } from "@gfg/ui-v2/components/button";
import { ArrowLeftIcon, ArrowRightIcon } from "@gfg/ui-v2/icons";
import { useEffect, useState } from "react";
import { type Swiper as SwiperType } from "swiper";
import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ spacing, colors, palette }) => ({
  navigationContainer: {
    display: "grid",
    placeItems: "center",
    paddingTop: spacing(20),
    paddingBottom: spacing(10),
  },
  navigationItems: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: spacing(100),
    width: "100%",
  },
  icon: {
    background: colors.background,
    color: colors.text,
    borderRadius: "100%",
    borderStyle: "solid",
    border: spacing(1),
    borderColor: palette.gray[100],
  },
}));

interface CustomNavigationProps {
  swiper?: SwiperType;
}

export default function CustomNavigationButton({ swiper }: CustomNavigationProps) {
  const [slideProgress, setSlideProgress] = useState<number>(0);

  useEffect(() => {
    const eventHandler = () => {
      setSlideProgress(swiper.progress);
    };

    swiper?.on("slideChange", eventHandler);

    return () => swiper?.off("slideChange", eventHandler);
  }, [swiper, setSlideProgress]);

  const { classes } = useStyles();
  return (
    <div className={classes.navigationContainer}>
      <div className={classes.navigationItems}>
        <IconButton
          className={classes.icon}
          icon={ArrowLeftIcon}
          onClick={() => swiper?.slidePrev()}
          disabled={slideProgress === 0}
        />
        <IconButton
          className={classes.icon}
          icon={ArrowRightIcon}
          onClick={() => swiper?.slideNext()}
          disabled={slideProgress === 1}
        />
      </div>
    </div>
  );
}
