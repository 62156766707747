import { JSXElementConstructor } from "react";
import { DataInputType } from "@coral/typings";
import NumberFilter, { FilterNumberProps } from "./filter-components/number-filter";
import TextFilter, { FilterTextProps } from "./filter-components/text-filter";
import RangeFilter, { FilterRangeProps } from "./filter-components/range-filter";
import AutocompleteMultiselectFilter, {
  FilterAutocompleteMultiselectProps,
} from "./filter-components/autocomplete-multiselect-filter";
import AutocompleteFilter, { FilterAutocompleteProps } from "./filter-components/autocomplete-filter";

export type FiltersMap = {
  [key in DataInputType]: any; // TODO: proper type
};

const filtersMap: FiltersMap = {
  autocomplete_multiselect: AutocompleteMultiselectFilter,
  autocomplete: AutocompleteFilter,
  range: RangeFilter,
  text: TextFilter,
  number: NumberFilter,
};

export type ComponentProps =
  | FilterAutocompleteMultiselectProps
  | FilterNumberProps
  | FilterRangeProps
  | FilterTextProps;

interface DynamicFilterComponentProps {
  component: keyof FiltersMap;
}

function isAutocompleteMultiselect(
  component: keyof FiltersMap,
  componentProps: ComponentProps,
): componentProps is FilterAutocompleteMultiselectProps {
  return component === "autocomplete_multiselect";
}

function isAutocomplete(
  component: keyof FiltersMap,
  componentProps: ComponentProps,
): componentProps is FilterAutocompleteProps {
  return component === "autocomplete";
}

function isNumber(component: keyof FiltersMap, componentProps: ComponentProps): componentProps is FilterNumberProps {
  return component === "number";
}

function isText(component: keyof FiltersMap, componentProps: ComponentProps): componentProps is FilterTextProps {
  return component === "text";
}

function isRange(component: keyof FiltersMap, componentProps: ComponentProps): componentProps is FilterRangeProps {
  return component === "range";
}

export default function DynamicFilterComponent({
  component,
  ...componentProps
}: DynamicFilterComponentProps & ComponentProps) {
  if (isAutocompleteMultiselect(component, componentProps)) {
    const AutocompleteMultiselectFilter = filtersMap[
      component
    ] as JSXElementConstructor<FilterAutocompleteMultiselectProps>;
    return (
      <AutocompleteMultiselectFilter
        onChange={componentProps.onChange}
        value={componentProps.value}
        disabled={componentProps.disabled}
        getValues={componentProps.getValues}
        attributeId={componentProps.attributeId}
        label={componentProps.label}
        invalidHint={componentProps.invalidHint}
        valid={componentProps.valid}
        onBlur={componentProps.onBlur}
      />
    );
  }

  if (isAutocomplete(component, componentProps)) {
    const AutocompleteFilter = filtersMap[component] as JSXElementConstructor<FilterAutocompleteProps>;
    return (
      <AutocompleteFilter
        onChange={componentProps.onChange}
        value={componentProps.value}
        disabled={componentProps.disabled}
        getValues={componentProps.getValues}
        attributeId={componentProps.attributeId}
        label={componentProps.label}
        invalidHint={componentProps.invalidHint}
        valid={componentProps.valid}
        onBlur={componentProps.onBlur}
      />
    );
  }

  if (isNumber(component, componentProps)) {
    const NumberFilter = filtersMap[component] as JSXElementConstructor<FilterNumberProps>;
    return (
      <NumberFilter
        disabled={componentProps.disabled}
        onChange={componentProps.onChange}
        value={componentProps.value}
        label={componentProps.label}
        required={componentProps.required}
        invalidHint={componentProps.invalidHint}
        valid={componentProps.valid}
        onBlur={componentProps.onBlur}
      />
    );
  }

  if (isText(component, componentProps)) {
    const TextFilter = filtersMap[component] as JSXElementConstructor<FilterTextProps>;

    return (
      <TextFilter
        onChange={componentProps.onChange}
        value={componentProps.value}
        disabled={componentProps.disabled}
        label={componentProps.label}
        required={componentProps.required}
        invalidHint={componentProps.invalidHint}
        valid={componentProps.valid}
        onBlur={componentProps.onBlur}
      />
    );
  }

  if (isRange(component, componentProps)) {
    const RangeFilter = filtersMap[component] as JSXElementConstructor<FilterRangeProps>;

    return (
      <RangeFilter
        disabled={componentProps.disabled}
        onChange={componentProps.onChange}
        value={componentProps.value}
        label={componentProps.label}
        required={componentProps.required}
        onBlur={componentProps.onBlur}
      />
    );
  }

  throw new Error(`Dynamic filter component not implemented for type: ${component}`);
}
