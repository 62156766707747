/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { InputField } from "@gfg/ui-v2/components/input";
import { Controller, useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { useCallback } from "react";
import SectionTitle from "../../../common/components/recommendations/section-title";
import SectionLabel from "../../../common/components/recommendations/section-label";
import useStyles from "./use-styles";
import preventSubmitOnEnter from "../../../common/utils/form/prevent-submit-on-enter";
import SearchConfigFormHeader from "./form-header";
import searchConfigValidationSchema from "./validation-schema";
import { AttributeFilterFormValue } from "../../../common/components/form-filters/attribute-filters";
import { AttributeFilterWithRuleFormValue } from "../../../common/components/form-filters/attribute-filters-with-rules";
import SearchConfigAttributeWithRulesFilter from "./filters/search-config-attribute-with-rules";
import SearchConfigAttributeFilter from "./filters/search-config-attribute";
import { ConditionalFilterFormValue } from "../../../common/components/form-filters/conditional-filters";
import SearchConfigConditionalFilter from "./filters/search-config-conditional-filter";

export interface FormData {
  configurationName: string;
  versionName: string;
  versionDescription: string;
  rankAttributes: AttributeFilterWithRuleFormValue[];
  personalisationAttributes: AttributeFilterFormValue[];
  conditionFilters: ConditionalFilterFormValue[];
}

export interface SearchConfigFormProps {
  onSubmit(formData: FormData): Promise<void>;
  onCancel(): void;
  onActivationToggle?(): void;
  onPreview?(): void;
  formTitle: string;
  defaultValues?: FormData;
  allowImmediateSubmit?: boolean;

  readOnly?: boolean;
  fixedSearchConfigName?: string;
  activationMode?: "ACTIVE" | "DRAFT";
}

export default function SearchConfigForm({
  onSubmit,
  onCancel,
  onActivationToggle,
  onPreview,
  activationMode,
  formTitle,
  allowImmediateSubmit,
  defaultValues,
  readOnly = false,
  fixedSearchConfigName,
}: SearchConfigFormProps) {
  const { classes, cx } = useStyles();
  const methods = useForm<FormData>({
    resolver: yupResolver(searchConfigValidationSchema),
    mode: "onChange",
    defaultValues: defaultValues ?? {
      configurationName: fixedSearchConfigName ?? "",
      conditionFilters: [],
      rankAttributes: [],
      personalisationAttributes: [],
      versionDescription: "",
      versionName: "",
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
  } = methods;

  const handleSubmitAndResetForm: SubmitHandler<FormData> = useCallback(
    async formData => {
      await onSubmit(formData);
      reset({}, { keepValues: true });
    },
    [onSubmit, reset],
  );

  const isSubmitDisabled = readOnly || (allowImmediateSubmit ? !isValid : !isDirty || !isValid);
  return (
    <FormProvider {...methods}>
      <form onKeyDown={preventSubmitOnEnter} onSubmit={handleSubmit(handleSubmitAndResetForm)}>
        <SearchConfigFormHeader
          title={formTitle}
          activationMode={activationMode}
          onActivationToggle={onActivationToggle}
          onCancel={onCancel}
          onPreview={onPreview}
          disabledActivation={isDirty}
          disabledSubmit={isSubmitDisabled}
          disabledPreview={isDirty}
        />
        <div className={classes.generalSettings}>
          <SectionTitle
            title="General Information"
            description="This section contains fields for general information about the configuration. This information helps to identify the configuration by its name, version, and description."
          />
          <div className={cx(classes.inputField, classes.textField, classes.verticallyMiddle)}>
            <SectionLabel
              title="Configuration name"
              description="The configuration name helps to identify the configuration. This is shown in the table, and users can search for this configuration by this name."
            />
            <Controller
              name="configurationName"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  disabled={readOnly || !!fixedSearchConfigName}
                  hint={errors.configurationName?.message}
                  placeholder="Configuration name"
                  block
                />
              )}
            />
          </div>
          <div className={cx(classes.inputField, classes.textField, classes.verticallyMiddle)}>
            <SectionLabel
              title="Version name"
              description="Each configuration can have multiple versions. Version name helps to identify the version."
            />
            <Controller
              name="versionName"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  disabled={readOnly}
                  hint={errors.versionName?.message}
                  placeholder="Version name"
                  block
                />
              )}
            />
          </div>
          <div className={cx(classes.inputField, classes.textField, classes.verticallyMiddle)}>
            <SectionLabel
              title="Version description"
              description="Version description is the description of the version. Regions can use this field to write extra information about this version like purpose, test number, etc."
            />
            <Controller
              name="versionDescription"
              control={control}
              render={({ field }) => (
                <InputField
                  {...field}
                  disabled={readOnly}
                  hint={errors.versionDescription?.message}
                  placeholder="Version description"
                  block
                />
              )}
            />
          </div>
          <div>
            <SectionTitle
              title="Ranking"
              description={
                <>
                  Ranking attributes determine the logic for ranking products in the results. Attributes with higher
                  weight influence product ranking more than the attributes with lower weights. <br />
                  <a target="_blank" href="/docs/search-management/search-config#components-of-search-configurations">
                    Read more about the Ranking
                  </a>
                </>
              }
            />
            <SearchConfigAttributeWithRulesFilter
              addAttributeButtonLabel="Add Ranking Attribute"
              disabled={readOnly}
              fieldName="rankAttributes"
              type="Ranking"
            />
          </div>
          <div>
            <SectionTitle
              title="Personalisation"
              description={
                <>
                  Personalisation ranks the products on the basis of user behavior. <br />
                  <a target="_blank" href="/docs/search-management/search-config#components-of-search-configurations">
                    Read more about the feed Personalisation
                  </a>
                </>
              }
            />
            <SearchConfigAttributeFilter
              disabled={readOnly}
              fieldName="personalisationAttributes"
              addAttributeButtonLabel="Add Personalisation Attribute"
              type="Personalised"
            />
          </div>
          <div>
            <SectionTitle
              title="Filters"
              description={
                <>
                  Filters are the conditional rules to exclude or include certain products from the results. <br />
                  <a target="_blank" href="/docs/search-management/search-config#components-of-search-configurations">
                    Read more about the Filters
                  </a>
                </>
              }
            />
            <SearchConfigConditionalFilter fieldName="conditionFilters" disabled={readOnly} />
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
