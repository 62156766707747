import { Chart } from "@coral/typings";
import { makeStyles } from "@gfg/ui-v2/theming";
import DynamicChart from "./dynamic-chart";
import Spacing from "../spacing";

interface ChartProps {
  chart: Chart;
}

const useStyles = makeStyles()(({ colors }) => ({
  title: {
    fontSize: "1.25em",
    fontWeight: "bold",
  },
  subtitle: {
    color: colors.darkgray,
  },
  description: {
    color: colors.text,
  },
}));

export default function ChartBox({ chart }: ChartProps) {
  const { classes, cx } = useStyles();

  if (chart.options.size === "small") {
    return (
      <div style={{ flex: 0.5, minWidth: "40%" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <DynamicChart chart={chart} />
          <Spacing bottom={10} />
          <div style={{ textAlign: "left", padding: "0 10 0 10" }}>
            <p className={cx(classes.subtitle)}>{chart.options.description}</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ flex: 1, minWidth: "90%" }}>
      <div style={{ display: "flex" }}>
        <div style={{ flex: "0 0 65%", boxSizing: "border-box" }}>
          <DynamicChart chart={chart} />
        </div>
        <div
          style={{
            flex: "0 0 35%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            boxSizing: "border-box",
            padding: 20,
          }}
        >
          <div style={{ textAlign: "left" }}>
            <span className={classes.title}>{chart.options.title}</span>
          </div>
          <div style={{ textAlign: "left" }}>
            <p className={classes.subtitle}>{chart.options.subtitle}</p>
          </div>
          <Spacing bottom={10} />
          <div style={{ textAlign: "left" }}>
            <p className={classes.description}>{chart.options.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
