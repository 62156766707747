// This hook makes sure the region in URL and selected environment in env selector stays the same

import { useReactiveVar } from "@apollo/client";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import reactiveVariables from "../graphql/global-reactive-variables";
import useAllowedEnvironments from "./use-allowed-environments";

function useSynchronizeSelectedEnv() {
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);
  const environments = useAllowedEnvironments();

  const { region } = useParams();

  useEffect(() => {
    if (region && environments) {
      const env = environments.find(e => e.key === region);
      if (env && env.id !== selectedEnvironmentProjectId) {
        reactiveVariables.selectedEnvironmentIdVar(env.id);
      }
    }
  }, [region, environments]);
}

export default useSynchronizeSelectedEnv;
