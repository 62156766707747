import React, { useEffect, useState } from "react";
import Button from "@gfg/ui-v2/components/button";
import { ArrowLeftIcon, FrownIcon, HomeIcon } from "@gfg/ui-v2/icons";
import { Theme } from "@gfg/ui-v2/theming/create-theme";
import { makeStyles } from "@gfg/ui-v2/theming";
import { fade } from "@gfg/ui-v2/theming/utils/color";
import addDatadogError from "./services/datadog/add-datadog-error";

const useStyles = makeStyles()(({ colors, spacing }) => ({
  actions: {
    display: "flex",
    gridGap: spacing("sm"),
    flexWrap: "wrap",
  },
  error: {
    "color": colors.error,
    "backgroundColor": fade(colors.error, 0.1),

    "& pre": {
      margin: 0,
      overflowY: "auto",
    },
  },
  headline: {
    display: "flex",
    gridColumnGap: spacing("sm"),
    alignItems: "center",
    marginTop: 0,
    marginBottom: spacing("md"),
  },
  wrapper: {
    padding: spacing("md"),
  },
}));

function ErrorPage({ error, onGoBack }: { error: Error; onGoBack(): void }) {
  const [showError, setShowError] = useState(false);
  const { classes } = useStyles();

  useEffect(() => {
    window.addEventListener("popstate", onGoBack);

    return () => window.removeEventListener("popstate", onGoBack);
  });

  return (
    <div className={classes.wrapper}>
      <h3 className={classes.headline}>
        <FrownIcon height={32} width={32} /> Oops! Something went wrong.
      </h3>
      <p>
        You see this screen because we were unable to recover from an unexpected situation. This error will
        automatically be sent to developers.
      </p>
      <div className={classes.actions}>
        <Button
          color="text"
          disabled={window?.history.length < 2}
          leftIcon={ArrowLeftIcon}
          shadow={false}
          variant="faded"
          onClick={() => window?.history.back()}
        >
          Go back
        </Button>
        <Button
          leftIcon={HomeIcon}
          shadow={false}
          variant="faded"
          onClick={() => {
            document.location.href = "/";
          }}
        >
          Go home
        </Button>
        {!showError && (
          <Button color="text" shadow={false} variant="faded" onClick={() => setShowError(true)}>
            Show error
          </Button>
        )}
      </div>
      {showError && (
        <pre>
          <b>
            {error.name}: {error.message}
          </b>{" "}
          at <br />
          {error.stack}
        </pre>
      )}
    </div>
  );
}

export default class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean; error: Error | null }
> {
  constructor(
    props: { theme: Theme; children: React.ReactNode } | Readonly<{ theme: Theme; children: React.ReactNode }>,
  ) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: any) {
    addDatadogError(error);

    return { hasError: true, error };
  }

  render() {
    const { error, hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorPage error={error!} onGoBack={() => this.setState({ hasError: false, error: null })} />;
    }

    return children;
  }
}
