import { ChartGroup } from "@coral/typings";
import Button, { ButtonGroup } from "@gfg/ui-v2/components/button";

interface ChartGroupSelectorProps {
  chartGroups: ChartGroup[];
  selectedGroupId?: string;
  onSelectGroupId(id: string): void;
}

export default function ChartGroupSelector({ chartGroups, selectedGroupId, onSelectGroupId }: ChartGroupSelectorProps) {
  return (
    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
      <ButtonGroup style={{ alignItems: "end" }} variant="outline">
        {chartGroups?.map(chartGroup => (
          <Button
            key={chartGroup.id}
            variant={selectedGroupId === chartGroup.id ? "filled" : undefined}
            onClick={() => onSelectGroupId(chartGroup.id)}
          >
            {chartGroup.name}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  );
}
