import { useEffect, useState } from "react";
import { CustomFeedState, PaginatedCustomFeeds } from "@coral/typings";

import { useReactiveVar } from "@apollo/client";
import { useGetNewslettersLazyQuery } from "../../generated";
import pagination from "../../common/constants/pagination";
import reactiveVariables from "../../common/graphql/global-reactive-variables";

export default function useGetNewsletters() {
  const [stateFilter, setStateFilter] = useState<string | undefined>();
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(+pagination.LIMITS[0]);
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const [getNewsletters, { data, previousData, loading }] = useGetNewslettersLazyQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filters: {
        search: "",
        recommendation_type: "newsletter",
        project_id: selectedEnvironmentProjectId,
        state: stateFilter as CustomFeedState,
      },
      pagination: {
        offset,
        limit,
      },
    },
  });
  const newsletters = (data ?? previousData)?.customFeeds as PaginatedCustomFeeds;
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    const timerId = setTimeout(() => {
      getNewsletters({
        fetchPolicy: "cache-and-network",
        variables: {
          filters: {
            search: searchInput ?? "",
            recommendation_type: "newsletter",
            project_id: selectedEnvironmentProjectId,
            state: stateFilter as CustomFeedState,
          },
          pagination: {
            offset,
            limit,
          },
        },
      });
    }, 1);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchInput, offset, limit, stateFilter]);

  return {
    stateFilter,
    setStateFilter,
    newsletters,
    searchInput,
    setSearchInput,
    loading,
    offset,
    setOffset,
    limit,
    setLimit,
  };
}
