import Breadcrumbs from "../../../common/components/breadcrumbs";
import PageContainer from "../../../common/components/containers/page-container";
import FeedsForm from "../feeds-form";
import useSubmit from "./use-submit";

export default function CreateFeedPage() {
  const handleSubmit = useSubmit();

  return (
    <PageContainer>
      <Breadcrumbs />
      <FeedsForm formTitle="Create Feed" onSubmit={handleSubmit} />
    </PageContainer>
  );
}
