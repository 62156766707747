import { CampaignPreview, SearchConfigPreview } from "@coral/typings";

const makePreviewRows = (
  preview: SearchConfigPreview | CampaignPreview,
  isCampaign?: boolean,
): { name: string; value: string }[] => [
  {
    name: "Name",
    value: preview.name,
  },
  {
    name: "Brand",
    value: preview.brand?.name,
  },
  {
    name: "Categories",
    value: preview.categories?.map(category => category.name)?.join(", "),
  },
  {
    name: "Price",
    value: `${preview.price?.current} ${preview.price?.currency}`,
  },
  {
    name: "Custom score",
    value: preview.customScore?.toString(),
  },
  ...(!isCampaign
    ? [
        {
          name: "Retrieval score",
          value: (preview as SearchConfigPreview).retrievalScore?.toString(),
        },
        {
          name: "Ranking score",
          value: (preview as SearchConfigPreview).rankingScore?.toString(),
        },
        ...((preview as SearchConfigPreview).additionalAttributes
          ? (preview as SearchConfigPreview).additionalAttributes.map(item => ({
              name: item.name,
              value: item.value.join(", "),
            }))
          : []),
      ]
    : []),
];

export default makePreviewRows;
