import { Column as DataTableColumn } from "@gfg/ui-v2/components/data-table/data-table";
import { SearchOptimization, SearchOptimizationState } from "@coral/typings";
import { Link, useParams } from "react-router-dom";
import { useMemo } from "react";
import useStyles from "~/search-config/components/search-config-versions-table/use-styles";
import date from "~/common/services/dates";
import SuccessStatusTag from "~/common/components/status-tags/success-tag";
import GrayStatusTag from "~/common/components/status-tags/gray-tag";
import TitleSubtitleCell from "~/common/components/table/cells/title-subtitle-cell";
import { SearchOptimizationStateTranslations } from "~/common/translations";
import TableFilter from "~/common/components/table/table-filter";
import SearchOptimizationStateMap from "~/common/constants/search-optimization-state";

export interface Column extends DataTableColumn<SearchOptimization> {
  sortField?: string;
}

const createStatusTag = (state: SearchOptimizationState) =>
  state === "Enabled" ? (
    <SuccessStatusTag label={SearchOptimizationStateTranslations[state]} />
  ) : (
    <GrayStatusTag label={SearchOptimizationStateTranslations[state]} />
  );

interface UseColumnsParams {
  onFilterState: (filter: SearchOptimizationState) => void;
  state: SearchOptimizationState | undefined;
}

export default function useColumns({ onFilterState, state }: UseColumnsParams): Column[] {
  const { classes } = useStyles();
  const { region } = useParams();

  return useMemo(
    () => [
      {
        id: "query",
        label: "Search Query",
        renderer: r => (
          <div>
            <p className={classes.name}>
              <Link to={`/${region}/search/optimizations/edit/${r.id}`}>{r.query}</Link>
            </p>
          </div>
        ),
        sortable: true,
        sortField: "ORIGINAL_QUERY",
        sticky: true,
      },
      {
        id: "contextual-suggestion",
        label: "Contextual Suggestion",
        renderer: r => r.output,
      },
      {
        id: "count",
        label: "Count",
        renderer: r => r.count,
        sortField: "ZSR_COUNT",
        sortable: true,
      },
      {
        id: "last-modified",
        label: "Last modified",
        renderer: r => <TitleSubtitleCell title="Last Modified" subtitle={r.updatedAt && date.format(r.updatedAt)} />,
        sortField: "UPDATED_AT",
        sortable: true,
      },
      {
        id: "state",
        label: (
          <div className={classes.status}>
            <span>Status</span>
            <TableFilter
              filters={Object.values(SearchOptimizationStateMap).map(value => ({
                value,
                label: SearchOptimizationStateTranslations[value],
              }))}
              onChange={onFilterState}
              value={state}
            />
          </div>
        ),
        renderer: r => (
          <div>
            <span>{createStatusTag(r.state)}</span>
          </div>
        ),
        sortField: "STATE",
      },
    ],
    [classes],
  );
}
