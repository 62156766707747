import { Controller, FieldValues, useForm } from "react-hook-form";
import { InputField, SearchInput } from "@gfg/ui-v2/components/input";
import { useCallback } from "react";
import { makeStyles } from "@gfg/ui-v2/theming";
import { ChevronRightIcon } from "@gfg/ui-v2/icons";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import CreateButton from "../../../common/components/create-button";
import useScreenWidth from "../../../common/hooks/use-screen-width";
import useCreateSynonymsSet from "../../hooks/use-create-synonyms-set";
import InProgressDialogNotification from "../../../common/components/dialogs/in-progress-dialog-notification";
import useEventTracking from "../../../common/hooks/use-tracking-event";
import validationSchema from "~/unidirectional-synonyms/components/synonyms-header/validation-schema";

const useStyles = makeStyles()(({ colors, spacing, size, breakpoints }) => ({
  button: {
    minWidth: "auto",
  },
  form: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingTop: spacing("xs"),
    paddingBottom: spacing(0),

    [breakpoints.down("md")]: {
      flexWrap: "wrap",
    },
  },
  inputs: {
    "display": "flex",
    "alignItems": "center",
    "minWidth": 0,
    "& input": {
      width: "100% !important", // TODO: Remove this when InputField component will be fixed
    },
    "& input::placeholder": {
      fontStyle: "italic",
    },
  },
  searchTermInputWrapper: {
    paddingRight: spacing("md"),
  },
  alternativesInputWrapper: {
    paddingLeft: spacing("md"),
    paddingRight: spacing("md"),
  },
  synonymsInput: {
    "width": "100%",
    "& small": {
      color: colors.error,
      textAlign: "left",
      fontSize: 12,
      height: 0,
    },
  },
  search: {
    width: "100%",
  },
}));

interface SynonymsHeaderProps {
  searchValue: string;
  setSearchValue: (val: string) => void;
}

export default function SynonymsHeader({ searchValue, setSearchValue }: SynonymsHeaderProps) {
  const { classes } = useStyles();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(validationSchema), defaultValues: { inputs: "", outputs: "" } });

  const sendAddSynonymsSetAnalyticsEvent = useEventTracking({ eventName: "synonym-add-new-set" });

  const { isDesktop } = useScreenWidth();
  const { createSynonymsSet, inProgress: createInProgress } = useCreateSynonymsSet();

  const onSubmit = useCallback(async (formData: FieldValues) => {
    const inputs = formData.inputs.split(",").map((item: string) => item.trim());
    const outputs = formData.outputs.split(",").map((item: string) => item.trim());

    if (await createSynonymsSet(inputs, outputs)) {
      reset();
    }
  }, []);

  const handleChangeSearchValue = useCallback((val: string) => setSearchValue(val), []);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <div className={classes.inputs}>
          <div className={classes.searchTermInputWrapper}>
            <Controller
              name="inputs"
              control={control}
              render={({ field, fieldState }) => (
                <InputField
                  {...field}
                  label="Search term"
                  valid={fieldState.invalid ? false : undefined}
                  hint={errors.inputs?.message?.toString()}
                  placeholder="e.g. Zarra, Zara"
                  className={classes.synonymsInput}
                  length={isDesktop ? 56 : undefined}
                />
              )}
            />
          </div>
          <ChevronRightIcon />
          <div className={classes.alternativesInputWrapper}>
            <Controller
              name="outputs"
              control={control}
              render={({ field, fieldState }) => (
                <InputField
                  {...field}
                  label="Alternatives"
                  valid={fieldState.invalid ? false : undefined}
                  hint={errors.outputs?.message?.toString()}
                  placeholder="e.g. Mango, Forever 21"
                  className={classes.synonymsInput}
                  length={isDesktop ? 56 : undefined}
                />
              )}
            />
          </div>
        </div>
        <CreateButton
          className={classes.button}
          onClick={() => {
            sendAddSynonymsSetAnalyticsEvent();
          }}
          text="Add Synonyms"
        />
      </form>
      <SearchInput
        className={classes.search}
        placeholder="Search Synonym..."
        value={searchValue}
        onChange={handleChangeSearchValue}
      />
      <InProgressDialogNotification open={createInProgress} closable={false} text="Creating synonyms" />
    </>
  );
}
