import React, { ReactNode } from "react";
import { TableCell } from "@gfg/ui-v2/components/table";
import { makeStyles } from "@gfg/ui-v2/theming";

interface TitleSubtitleCellProps {
  title: string | React.ReactNode | undefined | null;
  subtitle?: string | undefined | null | ReactNode;
}

const useStyles = makeStyles()(theme => ({
  title: {
    fontWeight: theme.typography.fontWeights.medium,
    margin: 0,
  },
  subtitle: {
    color: theme.colors.gray,
    margin: 0,
  },
}));

export default function TitleSubtitleCell({ title, subtitle }: TitleSubtitleCellProps) {
  const { classes } = useStyles();

  return (
    <TableCell>
      <div>
        <p className={classes.title}>{title}</p>
        {subtitle && <p className={classes.subtitle}>{subtitle}</p>}
      </div>
    </TableCell>
  );
}
