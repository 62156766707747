import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import NotificationProvider from "@gfg/ui-v2/components/notification";

import App from "./app";
import client from "./common/graphql/client";
import AuthProvider from "./auth/auth-provider";
import ThemeProviderV2, { GlobalStylesV2 } from "~/common/theming";
import ErrorBoundary from "~/common/error-boundary";

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <ThemeProviderV2>
      <GlobalStylesV2 />
      <ErrorBoundary>
        <NotificationProvider>
          <ApolloProvider client={client}>
            <AuthProvider>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </AuthProvider>
          </ApolloProvider>
        </NotificationProvider>
      </ErrorBoundary>
    </ThemeProviderV2>
  </React.StrictMode>,
);
