import { useParams } from "react-router-dom";
import LinearProgress from "@gfg/ui-v2/components/linear-progress";
import { SearchConfigPreview } from "@coral/typings";
import Button from "@gfg/ui-v2/components/button";
import { useState } from "react";
import { makeStyles } from "@gfg/ui-v2/theming";
import { isSuccessfulResponse } from "../search-config-versions";
import PageContainer from "../../../common/components/containers/page-container";
import ContentContainer from "../../../common/components/containers/content-container";
import SearchPreviewBody from "./components/search-preview-body";
import usePreview from "./hooks/use-preview";
import useExport from "~/search-config/pages/search-preview/hooks/use-export";
import ExportPreviewsDialog from "~/search-config/pages/search-preview/components/export-dialog";

const useStyles = makeStyles()(() => ({
  pageHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

export default function SearchPreviewPage() {
  const { configId, versionId } = useParams();
  const [open, setOpen] = useState(false);
  const { classes } = useStyles();

  const { configData, previewData, setUuid, setQuery, query, uuid, setOffset, setLimit, offset, loading, limit } =
    usePreview({
      configId,
      versionId,
    });

  const [onExport, isExportLoading] = useExport({
    configId,
    versionId,
    offset,
    uuid,
    query,
  });

  return (
    <PageContainer>
      <div className={classes.pageHeader}>
        <h4>Search preview</h4>
        <Button disabled={(!query && !uuid) || !previewData?.previews?.length} onClick={() => setOpen(true)}>
          Export
        </Button>
      </div>

      <ContentContainer>
        {isSuccessfulResponse(configData) && (
          <SearchPreviewBody
            loading={loading}
            configId={configId}
            versionId={versionId}
            configName={configData?.name}
            onQueryChange={setQuery}
            query={query}
            uuid={uuid}
            onUuidChange={setUuid}
            searchPreviewItems={previewData?.previews as SearchConfigPreview[]}
            limit={limit}
            offset={offset}
            onLimitChange={setLimit}
            onOffsetChange={setOffset}
            total={previewData?.total}
          />
        )}
        {!isSuccessfulResponse(configData) && loading && <LinearProgress indeterminate />}
      </ContentContainer>
      <ExportPreviewsDialog
        loading={isExportLoading}
        onExport={onExport}
        filter={{ query, uuid }}
        setOpen={setOpen}
        open={open}
      />
    </PageContainer>
  );
}
