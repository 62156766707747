import { InputField } from "@gfg/ui-v2/components/input";
import { useReactiveVar } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { CustomFeedPreviewQuery, FeedPreviewType } from "@coral/typings";
import { debounce } from "lodash";
import reactiveVariables from "common/graphql/global-reactive-variables";
import { useCustomFeedPreviewLazyQuery } from "generated";
import useCachedState from "common/hooks/use-cached-state";
import { makeStyles } from "@gfg/ui-v2/theming";
import Dialog, { DialogBody, DialogHeader } from "@gfg/ui-v2/components/dialog";
import RecommendationsPreviewBody from "./recommendations-preview-body";
import { previewDrawerState } from "../../graphql/cache";

const useStyles = makeStyles()(({ breakpoints, spacing }) => ({
  title: {
    margin: 0,
  },
  inputsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: spacing(16),
    gap: spacing(16),

    [breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  input: {
    "width": "100%",

    "& input": {
      cursor: "pointer",
    },
  },
}));

export interface RecommendationsPreviewProps {
  title: string;
  feedId: string;
  showIdField: boolean;
  showSkuField: boolean;

  feedPreviewType: FeedPreviewType;
  strategyName: string;
}

const USER_ID_POSTFIX = `preview-user-id`;
const SKU_KEY_POSTFIX = `preview-sku`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function RecommendationsPreview({
  feedId,
  title,
  showIdField,
  showSkuField,
  feedPreviewType,
  strategyName,
}: RecommendationsPreviewProps) {
  const [recommendations, setRecommendations] = useState<CustomFeedPreviewQuery | null>(null);
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const SKU_KEY = `${selectedEnvironmentProjectId}-${SKU_KEY_POSTFIX}`;
  const USER_ID_KEY = `${selectedEnvironmentProjectId}-${USER_ID_POSTFIX}`;

  const { classes } = useStyles();
  const previewState = useReactiveVar(previewDrawerState);
  const [customFeedPreview, { loading, error }] = useCustomFeedPreviewLazyQuery({
    onCompleted: data => setRecommendations(data),
  });
  const [sku = "", setSku] = useCachedState(SKU_KEY, showSkuField);
  const [userID = "", setUserID] = useCachedState(USER_ID_KEY, showIdField);

  useEffect(() => {
    if (!previewState) {
      setRecommendations(null);
    }
  }, [previewState, setRecommendations]);

  const queryCustomFeedPreview = useCallback(
    debounce(async (projectId, sku, userID) => {
      if (showSkuField && !sku) {
        return;
      }

      try {
        const result = await customFeedPreview({
          variables: {
            params: {
              feedID: feedId,
              projectId,
              feedPreviewType,
              sku,
              userID,
            },
          },
          fetchPolicy: "no-cache",
        });

        if (result.data.customFeedPreview?.items?.length) {
          if (sku) {
            localStorage.setItem(SKU_KEY, sku);
          }

          if (userID) {
            localStorage.setItem(USER_ID_KEY, userID);
          }
        }
      } catch {
        /* empty */
      }
    }, 200),
    [customFeedPreview, showIdField, userID, showSkuField, sku],
  );

  useEffect(() => {
    if (previewState) {
      queryCustomFeedPreview(selectedEnvironmentProjectId, sku, userID);
    }
  }, [sku, userID, queryCustomFeedPreview, previewState]);

  useEffect(() => {
    if (!showSkuField) {
      setSku("");
    }
  }, [showSkuField]);

  const handleClose = useCallback(() => {
    previewDrawerState(false);
  }, []);

  return (
    <Dialog open={previewState} size="fullsize" onClose={handleClose}>
      <DialogHeader>{title}</DialogHeader>
      <DialogBody>
        <div className={classes.inputsContainer}>
          {showSkuField && (
            <InputField
              valid={sku === "" ? false : undefined}
              label="SKU"
              invalidHint="SKU is required"
              block
              className={classes.input}
              placeholder="Enter SKU"
              onChange={setSku}
              value={sku}
            />
          )}
          {showIdField && (
            <InputField
              // valid={userID === "" ? false : undefined}
              // invalidHint="UUID is required"
              block
              label="UUID"
              className={classes.input}
              placeholder="Enter UUID"
              onChange={setUserID}
              value={userID}
            />
          )}
        </div>
        <RecommendationsPreviewBody
          strategyName={strategyName}
          data={recommendations}
          loading={loading}
          error={error}
          seedProduct={recommendations?.customFeedPreview?.seedProduct}
        />
      </DialogBody>
    </Dialog>
  );
}
