import * as yup from "yup";
import attributeFilterObjectShape from "../../../common/components/form-filters/attribute-filters/validation-schema";
import attributeFilterWithRulesObjectShape from "../../../common/components/form-filters/attribute-filters-with-rules/validation-schema";
import feedFilterObjectShape from "../../../common/components/form-filters/conditional-filters/validation-schema";

const searchConfigValidationSchema = yup.object().shape({
  configurationName: yup.string().nullable().required("Configuration name is required"),
  versionName: yup
    .string()
    .nullable()
    .max(60, "Maximum allowed length is 60 characters")
    .required("Version name is required"),
  versionDescription: yup.string().nullable(),
  conditionFilters: yup.array().of(feedFilterObjectShape).nullable(),
  rankAttributes: yup.array().of(attributeFilterWithRulesObjectShape).nullable(),
  personalisationAttributes: yup.array().of(attributeFilterObjectShape).nullable(),
});

export default searchConfigValidationSchema;
