import { IconButton } from "@gfg/ui-v2/components/button";
import Dropdown, { DropdownItem } from "@gfg/ui-v2/components/dropdown";
import Avatar from "@gfg/ui-v2/components/avatar";
import { useNavigate } from "react-router-dom";
import React from "react";
import { makeStyles } from "@gfg/ui-v2/theming";
import { useAuth } from "../../auth/auth-provider";

const useStyles = makeStyles()(({ colors, size, spacing, palette }) => ({
  container: {
    alignSelf: "center",
  },
  avatar: {
    marginBottom: spacing("sm"),
  },
  email: {
    color: colors.darkgray,
  },
  user: {
    display: "flex",
    textAlign: "center",
    padding: spacing("md"),
  },
  dropdown: {
    minWidth: size(335),
  },
  triggerButton: {
    padding: spacing(2),
    color: colors.secondary,
    backgroundColor: palette.secondary[100],
    borderColor: palette.secondary[100],
  },
  trigger: {
    fontWeight: "bold",
    color: colors.secondary,
    borderColor: palette.secondary[100],
  },
}));

export default function UserDropdown() {
  const { viewer } = useAuth();
  const navigate = useNavigate();
  const { classes } = useStyles();

  return (
    <Dropdown
      className={classes.container}
      strategy="align-right"
      triggerElement={
        <IconButton
          className={classes.triggerButton}
          icon={<Avatar name={viewer?.name} className={classes.trigger} />}
          round
        />
      }
    >
      <DropdownItem className={classes.user} hoverable={false}>
        <Avatar className={classes.avatar} name={viewer?.name} size="large" />
        <div>{viewer?.name}</div>
        <div className={classes.email}>{viewer?.email}</div>
      </DropdownItem>
      <DropdownItem className={classes.dropdown} divider value="logout" onClick={() => navigate("/logout")}>
        Logout
      </DropdownItem>
    </Dropdown>
  );
}
