import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApolloError, useReactiveVar } from "@apollo/client";

import { SearchConfiguration } from "@coral/typings";
import extractGraphqlError from "common/utils/apollo/extract-graphql-error";
import { useUpdateSearchConfigurationMutation } from "../../../generated";
import { FormData } from "../../components/search-config-form";
import useAppNotifications from "../../../common/hooks/use-app-notifications";
import reactiveVariables from "../../../common/graphql/global-reactive-variables";
import serializeAttributeFilter from "../../../common/utils/search-config/serialize-attribute-filter";
import serializeAttributeFilterWithRule from "../../../common/utils/search-config/serialize-attribute-filter-with-rule";
import serializeConditionalFilter from "../../../common/utils/search-config/serialize-conditional-filter";

function useSubmit() {
  const { addSuccessNotification, addErrorNotification } = useAppNotifications();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const navigate = useNavigate();
  const { region } = useParams();

  const [updateSearchConfig] = useUpdateSearchConfigurationMutation({
    refetchQueries: ["GetSearchConfiguration", "GetSearchConfigurations"],
  });

  return useCallback(
    async (configurationId: string, formData: FormData) => {
      try {
        const { data } = await updateSearchConfig({
          variables: {
            params: {
              versionId: "",
              configurationId,
              configurationName: formData.configurationName,
              versionName: formData.versionName,
              versionDescription: formData.versionDescription,
              projectId: selectedEnvironmentProjectId,
              rankingAttributes: formData.rankAttributes.map(serializeAttributeFilterWithRule),
              personalisationAttributes: formData.personalisationAttributes.map(serializeAttributeFilter),
              filterAttributes: formData.conditionFilters.map(serializeConditionalFilter),
            },
          },
        });

        const searchConfig = data?.updateSearchConfig as SearchConfiguration;
        addSuccessNotification("Version was created successfully");
        navigate(`/${region}/search/search-config/${configurationId}/versions/edit/${searchConfig?.versions[0].id}`);
      } catch (error) {
        const message = extractGraphqlError(error as ApolloError);
        addErrorNotification("Error while creating version", message);
      }
    },
    [navigate, updateSearchConfig],
  );
}

export default useSubmit;
