import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ApolloError, useReactiveVar } from "@apollo/client";

import { CustomFeedState } from "@coral/typings";
import extractGraphqlError from "common/utils/apollo/extract-graphql-error";
import { useUpdateFeedMutation } from "../../../generated";
import { FormData } from "../feeds-form";
import serializeFeedFilters from "../../../common/utils/feed-filters/serialize-feed-filters";
import useAppNotifications from "../../../common/hooks/use-app-notifications";
import reactiveVariables from "../../../common/graphql/global-reactive-variables";

function useSubmit() {
  const { addSuccessNotification, addErrorNotification, removeNotification, addInProgressNotification } =
    useAppNotifications();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);
  const navigate = useNavigate();

  const [updateFeed] = useUpdateFeedMutation({
    refetchQueries: ["GetFeeds"],
  });

  return useCallback(
    async (id: string, formData: FormData) => {
      let notificationId;

      try {
        notificationId = addInProgressNotification("Updating feed");

        await updateFeed({
          variables: {
            id,
            feed: {
              orderStrategy: formData.orderStrategy,
              feedStrategy: formData.feedStrategy,
              feedName: formData.feedName,
              placement: Number.parseInt(formData.placement, 10),
              feedFilters: serializeFeedFilters(formData.feedFilters ?? []),
              scenario: formData.scenario,
              screenName: formData.screenName,
              state: formData.feedStatus as CustomFeedState,
              projectId: selectedEnvironmentProjectId,
            },
          },
        });

        addSuccessNotification("Feed was edited successfully");
      } catch (error) {
        const message = extractGraphqlError(error as ApolloError);
        addErrorNotification("Error while editing feed", message);
      } finally {
        if (notificationId) {
          removeNotification(notificationId);
        }
      }
    },
    [navigate, updateFeed],
  );
}

export default useSubmit;
