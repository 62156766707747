import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ spacing, colors, typography }) => ({
  container: {
    marginTop: spacing(16),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
  },
  subTitle: {
    color: colors.gray,
  },
  title: {
    fontSize: typography.h3.fontSize,
    margin: 0,
  },
}));

export default function ProductCatalogHeader() {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <div>
        <h3 className={classes.title}>Product catalog</h3>
        <p className={classes.subTitle}>
          Check product data available in Datajet. Product catalog shows data stored by Datajet when you send it via
          fisherman.
        </p>
      </div>
      {/* <Button disabled variant="outline" color="gray" size="medium" leftIcon={DownloadIcon}>
        Download
      </Button> */}
    </div>
  );
}
