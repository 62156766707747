import { useCallback, useMemo } from "react";
import { Option, SelectField } from "@gfg/ui-v2/components/select";
import { IconButton } from "@gfg/ui-v2/components/button";
import { Trash2Icon } from "@gfg/ui-v2/icons";
import { Controller, useWatch, useFormContext } from "react-hook-form";
import { ConditionRuleWithInputType, DataInputType } from "@coral/typings";
import DynamicFilterComponent from "../../dynamic-filter-component";
import { ConditionalFilterFormValue } from "..";
import useStyles from "./use-styles";
import useFeedFilterAvailability from "./use-feed-filter-availability";
import { FilterAutocompleteMultiselectProps } from "../../dynamic-filter-component/filter-components/autocomplete-multiselect-filter";

export interface AttributeOption {
  id: string;
  label?: string;
  value: string;
  rules: ConditionRuleWithInputType[];
}

interface FilterRowWithFieldsProps {
  index: number;
  fieldName: string;
  removeFilter(index: number): void;

  attributeOptions: AttributeOption[];
  disabled?: boolean;

  allowDuplicates: boolean;
  getFacetValues: FilterAutocompleteMultiselectProps["getValues"];
}

const DEFAULT_ATTRIBUTE_TYPE: DataInputType = "text";

export default function FilterRowWithFields({
  removeFilter,
  index,
  attributeOptions,
  disabled = false,
  allowDuplicates,
  fieldName,
  getFacetValues,
}: FilterRowWithFieldsProps) {
  const { control, setValue } = useFormContext();
  const { classes } = useStyles();
  const fieldData = useWatch({
    control,
    name: `${fieldName}.${index}`,
  }) as ConditionalFilterFormValue;

  const filterData = useWatch({
    control,
    name: fieldName,
  }) as ConditionalFilterFormValue[];

  const createTypeChangeHandler = useCallback(
    (onChange: (type: string) => void) => (type: string) => {
      onChange(type);
      setValue(`${fieldName}.${index}.value`, "");
      setValue(`${fieldName}.${index}.rule`, "");
    },
    [fieldName, index, setValue],
  );

  const createRuleChangeHandler = useCallback(
    (onChange: (rule: string) => void) => (rule: string) => {
      onChange(rule);
      setValue(`${fieldName}.${index}.value`, "");
    },
    [fieldName, fieldName, index, setValue],
  );

  const { isAttributeOptionDisabled, isRuleOptionDisabled } = useFeedFilterAvailability(filterData, attributeOptions);

  const selectedAttribute = useMemo(() => attributeOptions.find(a => a.value === fieldData.type), [fieldData.type]);
  const selectedRule = useMemo(() => selectedAttribute?.rules.find(r => r.value === fieldData.rule), [fieldData.rule]);

  const currentComponentType = selectedRule?.type ?? DEFAULT_ATTRIBUTE_TYPE;

  const ruleOptions = useMemo(() => selectedAttribute?.rules ?? [], [selectedAttribute?.rules]);

  return (
    <div className={classes.container}>
      <div>
        <Controller
          name={`${fieldName}.${index}.type`}
          control={control}
          render={({ field }) => (
            <SelectField
              {...field}
              onChange={createTypeChangeHandler(field.onChange)}
              label="Type"
              block
              disabled={disabled}
              strategy="align-left"
              placeholder="Choose type"
            >
              {attributeOptions.map(({ value, label, id }) => (
                <Option
                  key={id}
                  text={label}
                  value={value}
                  disabled={
                    disabled || (!allowDuplicates && isAttributeOptionDisabled(value) && value !== fieldData.type)
                  }
                >
                  {label}
                </Option>
              ))}
            </SelectField>
          )}
        />

        <Controller
          name={`${fieldName}.${index}.rule`}
          control={control}
          render={({ field }) => (
            <SelectField
              {...field}
              onChange={createRuleChangeHandler(field.onChange)}
              label="Rules"
              block
              placeholder="Choose rule"
              className={classes.formInput}
              disabled={!fieldData.type || disabled}
            >
              {ruleOptions.map(({ value, label, id }) => (
                <Option
                  key={id}
                  text={label}
                  value={value}
                  disabled={
                    disabled ||
                    (!allowDuplicates && isRuleOptionDisabled(fieldData.type, value) && value !== fieldData.rule)
                  }
                >
                  {label}
                </Option>
              ))}
            </SelectField>
          )}
        />
      </div>

      <div>
        <Controller
          name={`${fieldName}.${index}.value`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <DynamicFilterComponent
              onChange={onChange}
              value={value}
              component={currentComponentType}
              disabled={!fieldData.rule || disabled}
              getValues={getFacetValues}
              attributeId={selectedAttribute?.id}
              label={currentComponentType === "range" ? "From/To" : "Values"}
            />
          )}
        />

        <IconButton
          type="button"
          disabled={disabled}
          icon={Trash2Icon}
          round
          variant="light"
          className={classes.icon}
          onClick={() => removeFilter(index)}
        />
      </div>
    </div>
  );
}
