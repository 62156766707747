import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ typography, spacing }) => ({
  container: {
    display: "flex",
    justifyContent: "start",
  },
  title: {
    fontWeight: typography.fontWeights.semiBold,
    fontSize: typography.h3.fontSize,
    lineHeight: typography.h3.lineHeight,
    margin: 0,
  },
}));

interface SectionTitleProps {
  text: string;
  className?: string;
}

export default function Title({ text, className = "" }: SectionTitleProps) {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.container, className)}>
      <h3 className={classes.title}>{text}</h3>
    </div>
  );
}
