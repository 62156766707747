import Breadcrumbs from "../../../common/components/breadcrumbs";
import PageContainer from "../../../common/components/containers/page-container";
import NewsletterForm from "../newsletter-form";
import useSubmit from "./use-submit";

export default function CreateNewsletterPage() {
  const handleSubmit = useSubmit();

  return (
    <PageContainer>
      <Breadcrumbs />
      <NewsletterForm formTitle="Create Newsletter" onSubmit={handleSubmit} />
    </PageContainer>
  );
}
