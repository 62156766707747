import React from "react";
import { Option, SelectField } from "@gfg/ui-v2/components/select";
import { CheckIcon, GlobeIcon } from "@gfg/ui-v2/icons";
import { Environment } from "@coral/typings";
import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ spacing, colors }) => ({
  container: {
    marginBottom: spacing(40),
  },
  optionContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  optionName: {
    color: colors.text,
  },
  icon: {
    paddingLeft: "auto",
  },
  hide: {
    display: "none",
  },
}));

interface MultiSelectProps {
  label: string;
  allEnvironments: Environment[];
  viewerEnvironments: Environment[];
  onChange: (value: string[]) => void;
  value: string[];
}

export default function MultiSelect({ label, allEnvironments, onChange, value, viewerEnvironments }: MultiSelectProps) {
  const { classes } = useStyles();
  const checkOptionAvailability = (optionId: string) => viewerEnvironments.find(({ id }) => id === optionId);

  return (
    <div className={classes.container}>
      <label htmlFor="environments">{label}</label>
      <SelectField
        leftIcon={<GlobeIcon color="gray" />}
        placeholder="Select Environment"
        block
        multiple
        value={value}
        onChange={onChange}
      >
        {allEnvironments.map(({ name, id }, i) => (
          <Option
            key={id}
            divider={i === 0}
            text={name}
            value={id}
            disabled={!checkOptionAvailability(id)}
            className={!checkOptionAvailability(id) ? classes.hide : ""}
          >
            <div className={classes.optionContainer}>
              <span className={classes.optionName}>{name}</span>
              {value?.includes(id) && <CheckIcon className={classes.icon} color="primary" />}
            </div>
          </Option>
        ))}
      </SelectField>
    </div>
  );
}
