import React, { useState } from "react";
import { CopyIcon, Edit2Icon, LinkIcon, Trash2Icon } from "@gfg/ui-v2/icons";
import { IconButton } from "@gfg/ui-v2/components/button";
import { makeStyles } from "@gfg/ui-v2/theming";
import DeleteModal from "../../../../common/components/delete-modal";
import ActionCellWithIcons from "../../../../common/components/table/cells/action-cell";

const useStyles = makeStyles()(({ palette }) => ({
  icon: {
    background: palette.secondary[100],
  },
}));

interface ActionCellProps {
  copyToClipboard(): void;
  deleteFeed(): void;
  onEdit(): void;
  onDuplicate(): void;
}

export default function ActionCell({ copyToClipboard, deleteFeed, onEdit, onDuplicate }: ActionCellProps) {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <>
      <ActionCellWithIcons>
        <IconButton onClick={copyToClipboard} icon={LinkIcon} round variant="light" className={classes.icon} />
        <IconButton onClick={onDuplicate} icon={CopyIcon} round variant="light" className={classes.icon} />
        <IconButton onClick={onEdit} icon={Edit2Icon} round variant="light" className={classes.icon} />
        <IconButton onClick={() => setOpen(true)} icon={Trash2Icon} round variant="light" className={classes.icon} />
      </ActionCellWithIcons>
      <DeleteModal
        open={open}
        title="Delete Feed"
        text="Are you sure you want to delete this feed?"
        onToggle={setOpen}
        onDelete={deleteFeed}
      />
    </>
  );
}
