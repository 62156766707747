import { useNavigate } from "react-router-dom";
import GeneralError from "../general-error";

export default function NotFoundPage() {
  const navigate = useNavigate();

  return (
    <GeneralError
      heading="Page not found!"
      subheading="404 error"
      // eslint-disable-next-line react/no-unescaped-entities
      text={<>Sorry, the page you are looking for doesn't exist or has been moved.</>}
      buttonLabel="Take me back"
      onButtonClick={() => navigate("/", { replace: true })}
      img="/not-found.svg"
    />
  );
}
