import { Role } from "@coral/typings";

const RoleDisplay: Record<Role, string> = {
  master: "GFG Master",
  regional_manager: "Regional Manager",
  regional_user: "Regional User",
  viewer: "Regional Viewer",
};

export default RoleDisplay;
