import { ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";

function extractGraphqlError(error: ApolloError) {
  if (error.graphQLErrors && error.graphQLErrors.length > 0) {
    // Assuming that the first GraphQL error message is relevant
    const firstGraphQLError: GraphQLError = error.graphQLErrors[0];
    return firstGraphQLError.message;
  }

  return error.message;
}

export default extractGraphqlError;
