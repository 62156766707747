import { ConditionalWeightFilterInput, Operation } from "@coral/typings";
import { AttributeFilterWithRuleFormValue } from "../../components/form-filters/attribute-filters-with-rules";

function serializeAttributeFilterWithRule(attribute: AttributeFilterWithRuleFormValue): ConditionalWeightFilterInput {
  if (!attribute.rule) {
    return {
      id: attribute.id,
      fieldName: attribute.type,
      weight: attribute.weight,
    };
  }

  if (typeof attribute.value === "number") {
    return {
      id: attribute.id,
      fieldName: attribute.type,
      weight: attribute.weight,
      operation: attribute.rule as Operation,
      numValue: attribute.value,
    };
  }

  if (typeof attribute.value === "string") {
    return {
      id: attribute.id,
      fieldName: attribute.type,
      weight: attribute.weight,
      operation: attribute.rule as Operation,
      strValue: attribute.value,
    };
  }

  if (Array.isArray(attribute.value)) {
    return {
      id: attribute.id,
      fieldName: attribute.type,
      weight: attribute.weight,
      operation: attribute.rule as Operation,
      strArrValue: attribute.value,
    };
  }

  throw new Error(`Attribute value ${attribute.value} is not string or array, therefore it cant be serialized`);
}

export default serializeAttributeFilterWithRule;
