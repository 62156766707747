import { CustomFeed } from "@coral/typings";
import CircularProgress from "@gfg/ui-v2/components/circular-progress";
import { useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";

import Breadcrumbs from "../../../common/components/breadcrumbs";
import { useGetFeedQuery } from "../../../generated";
import FeedsForm, { FormData } from "../feeds-form";
import convertCustomFeedToFormData from "../feeds-form/utils/convert-custom-feed-to-form-data";
import reactiveVariables from "../../../common/graphql/global-reactive-variables";
import useSubmit from "./use-submit";
import PageContainer from "../../../common/components/containers/page-container";

export default function CreateFeedPage() {
  const onSubmit = useSubmit();
  const { feedId } = useParams();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const { loading, data } = useGetFeedQuery({
    fetchPolicy: "network-only",
    variables: {
      params: {
        id: feedId as string,
        projectId: selectedEnvironmentProjectId,
      },
    },
  });

  const defaultValues = useMemo(() => {
    if (data?.customFeed !== undefined) {
      return convertCustomFeedToFormData(data?.customFeed as CustomFeed);
    }
  }, [data]);

  const handleSubmit = useCallback(
    async (formData: FormData) => {
      await onSubmit(feedId!, formData);
    },
    [defaultValues],
  );

  return (
    <PageContainer>
      <Breadcrumbs />
      {loading && <CircularProgress />}
      {!loading && (
        <FeedsForm defaultValues={defaultValues} formTitle="Edit Feed" onSubmit={handleSubmit} allowPreview />
      )}
    </PageContainer>
  );
}
