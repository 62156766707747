import { Button } from "@gfg/ui-v2/components/button";
import { PlusIcon } from "@gfg/ui-v2/icons";
import { makeStyles } from "@gfg/ui-v2/theming";
import { ButtonBaseProps } from "@gfg/ui-v2/components/button-base";

const useStyles = makeStyles()(({ spacing, colors, palette, breakpoints }) => ({
  createBtn: {
    "height": spacing(44),
    "& svg": {
      marginRight: spacing(10),
    },

    [breakpoints.down("md")]: {
      maxWidth: spacing(190),
    },
    [breakpoints.down("xs")]: {
      maxWidth: "initial",
      width: "100%",
      justifyContent: "center",
    },
  },
  plusIcon: {
    border: `2px solid ${colors.background}`,
    borderRadius: spacing(2.5),
    color: colors.background,
    strokeWidth: 3,
  },
}));

interface CreateButtonProps extends ButtonBaseProps {
  onClick: (e: React.MouseEvent) => void;
  text: string;
}

export default function CreateButton({ onClick, text, ...props }: CreateButtonProps) {
  const { classes } = useStyles();

  return (
    <Button
      onClick={onClick}
      color="primary"
      className={classes.createBtn}
      leftIcon={<PlusIcon className={classes.plusIcon} />}
      {...props}
    >
      {text}
    </Button>
  );
}
