import React, { useMemo } from "react";
import ExportDialogBase, { ExportDialogOption } from "~/common/components/export-dialog-base";

interface ExportPreviewsDialogProps {
  filter: {
    query?: string;
    uuid?: string;
  };
  loading?: boolean;
  open?: boolean;
  setOpen: (flag: boolean) => void;
  onExport: (variables: Record<string, any>, whatToExport: string) => void;
}

export default function ExportPreviewsDialog({ filter, loading, open, onExport, setOpen }: ExportPreviewsDialogProps) {
  const options = useMemo(
    () => ({
      "current-filter": {
        label: "Current Filter (Query and UUID)",
        disabled: !filter?.query && !filter?.uuid,
      },
    }),
    [filter],
  );

  return (
    <ExportDialogBase
      defaultOption="current-filter"
      description="It is possible to export up to 100 previews at a time."
      filter={filter}
      loading={loading}
      open={open}
      options={options as Record<string, ExportDialogOption>}
      title="Export previews"
      onExport={onExport}
      setOpen={setOpen}
    />
  );
}
