import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";

import { useUpdateSearchConfigurationMutation } from "../../../generated";
import { FormData } from "../../components/search-config-form";
import useAppNotifications from "../../../common/hooks/use-app-notifications";
import reactiveVariables from "../../../common/graphql/global-reactive-variables";
import serializeAttributeFilterWithRule from "../../../common/utils/search-config/serialize-attribute-filter-with-rule";
import serializeAttributeFilter from "../../../common/utils/search-config/serialize-attribute-filter";
import serializeConditionalFilter from "../../../common/utils/search-config/serialize-conditional-filter";

function useSubmit() {
  const { addSuccessNotification, addErrorNotification } = useAppNotifications();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const navigate = useNavigate();

  const [updateConfigurationVersion] = useUpdateSearchConfigurationMutation({
    refetchQueries: ["GetSearchConfiguration", "GetSearchConfigurations"],
  });

  return useCallback(
    async (configId: string, versionId: string, formData: FormData) => {
      try {
        await updateConfigurationVersion({
          variables: {
            params: {
              configurationId: configId,
              versionId,
              configurationName: formData.configurationName,
              versionName: formData.versionName,
              versionDescription: formData.versionDescription,
              projectId: selectedEnvironmentProjectId,
              rankingAttributes: formData.rankAttributes.map(serializeAttributeFilterWithRule),
              personalisationAttributes: formData.personalisationAttributes.map(serializeAttributeFilter),
              filterAttributes: formData.conditionFilters.map(serializeConditionalFilter),
            },
          },
        });

        addSuccessNotification("Configuration was updated successfully");
      } catch (error) {
        addErrorNotification("Error while editing configuration");
        throw error;
      }
    },
    [navigate, updateConfigurationVersion],
  );
}

export default useSubmit;
