import { CampaignShort } from "@coral/typings";
import DataTable, { TableState } from "@gfg/ui-v2/components/data-table";
import { SortFunction } from "@gfg/ui-v2/components/data-table/use-table-state";
import { useCallback } from "react";
import TableFooter from "~/common/components/table/table-footer";
import { Column } from "~/campaigns/hooks/use-columns";
import { ActionsRow, useTableRowActions } from "~/campaigns/hooks/use-table-row-actions";

interface MerchandisingTableProps {
  campaigns: CampaignShort[] | undefined;
  total: number | undefined;
  loading?: boolean;
  state: TableState<CampaignShort>;
  onSort: SortFunction<CampaignShort>;
  columns: Column[];

  onOffsetChange(offset: number): void;
  offset: number;

  onLimitChange(limit: number): void;
  limit: number;

  onEditCampaign(campaign: CampaignShort): void;
  onRemoveCampaign(campaign: CampaignShort): void;
  onDuplicateCampaign(campaign: CampaignShort): void;
}

export default function MerchandisingTable({
  campaigns,
  loading,
  state,
  onSort,
  onOffsetChange,
  onLimitChange,
  offset,
  columns,
  total,
  limit,
  onEditCampaign,
  onRemoveCampaign,
  onDuplicateCampaign,
}: MerchandisingTableProps) {
  const handleAction = useCallback((action: ActionsRow, row: CampaignShort) => {
    switch (action) {
      case "delete": {
        onRemoveCampaign(row);
        break;
      }

      case "edit": {
        onEditCampaign(row);
        break;
      }

      case "duplicate": {
        onDuplicateCampaign(row);
        break;
      }
      default:
    }
  }, []);

  const actions = useTableRowActions(handleAction);

  return (
    <>
      <DataTable
        onSort={onSort}
        actions={actions}
        selectable={false}
        columns={columns}
        state={state}
        data={campaigns || []}
        loading={loading}
      />
      <TableFooter
        onOffsetChange={onOffsetChange}
        offset={offset}
        total={total}
        limit={limit.toString()}
        onLimitChange={onLimitChange}
      />
    </>
  );
}
