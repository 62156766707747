import { useNavigate, useParams } from "react-router-dom";
import Breadcrumbs from "../../../common/components/breadcrumbs";
import PageContainer from "../../../common/components/containers/page-container";
import SearchConfigForm, { FormData } from "../../components/search-config-form";
import useSubmit from "./use-submit";

export default function CreateSearchConfigurationPage() {
  const navigate = useNavigate();
  const submit = useSubmit();
  const { region } = useParams();

  const handleSubmit = async (formData: FormData) => {
    await submit(formData);
  };

  return (
    <PageContainer>
      <Breadcrumbs />
      <SearchConfigForm
        onCancel={() => navigate(`/${region}/search/search-config`)}
        formTitle="Create Search Configuration"
        onSubmit={handleSubmit}
      />
    </PageContainer>
  );
}
