import { Column } from "@gfg/ui-v2/components/data-table";
import { User } from "@coral/typings";
import Avatar from "@gfg/ui-v2/components/avatar";
import { useMemo } from "react";
import { Tag } from "@gfg/ui-v2/components/tag";
import date from "../../../common/services/dates";
import RoleDisplay from "../../../common/constants/role-display";
import { useStyles } from "./data-table";
import FlexWrapContainer from "../../../common/components/containers/flex-wrap-container";

export default function useColumns(): Column<User>[] {
  const { classes } = useStyles();

  return useMemo(
    () => [
      {
        id: "name",
        label: "Name",
        renderer: r => (
          <div className={classes.wrapper}>
            <Avatar name={r.name} />
            <div>
              <p className={classes.name}>{r.name}</p>
              <p className={classes.email}>{r.email}</p>
            </div>
          </div>
        ),
        sticky: true,
      },
      {
        id: "environment",
        label: "Environment",
        renderer: r => {
          if (r.role === "master") {
            return "All environments";
          }

          return (
            <FlexWrapContainer>
              {r.environments.map(e => (
                <Tag size="small" variant="faded" label={e.name} />
              ))}
            </FlexWrapContainer>
          );
        },
      },
      {
        id: "section",
        label: "Section",
        renderer: section => (
          <FlexWrapContainer>
            {section.resources
              .filter(r => r.editable_by_users)
              .map(e => (
                <Tag size="small" variant="faded" label={e.title} />
              ))}
          </FlexWrapContainer>
        ),
      },
      {
        id: "role",
        label: "Role",
        renderer: r => RoleDisplay[r.role],
      },
      {
        id: "last-modified",
        label: "Last modified",
        renderer: r => (
          <div>
            <p>{r.modifier?.name}</p>
            <p className={classes.email}>{date.format(r.updated_at || r.created_at)}</p>
          </div>
        ),
      },
    ],
    [classes],
  );
}
