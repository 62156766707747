const FeedStrategy = {
  BestDeals: "BestDealsRecommender",
  BestDealsPersonalised: "BestDealsPersonalizedRecommender",
  Bestsellers: "BestsellersRecommender",
  BestsellersPersonalised: "BestsellersPersonalizedRecommender",
  MoreLikeThis: "MoreLikeThisRecommender",
  MoreLikeThisPersonalised: "MoreLikeThisPersonalizedRecommender",
  SimilarItems: "SimilarItemsRecommender",
  SimilarItemsPersonalised: "SimilarItemsPersonalizedRecommender",
  PopularInCategory: "PopularInCategoryRecommender",
  PopularInCategoryPersonalised: "PopularInCategoryPersonalizedRecommender",
  ViewedMost: "ViewedMostRecommender",
  ViewedMostPersonalised: "ViewedMostPersonalizedRecommender",
  NextBuy: "NextBuyRecommender",
  NextBuyPersonalised: "NextBuyPersonalizedRecommender",
  PurchasedRecently: "PurchasedRecentlyRecommender",
  ViewedRecently: "ViewedRecentlyRecommender",
  SpecialDeals: "SpecialDealsRecommender",
  SpecialDealsPersonalised: "SpecialDealsPersonalizedRecommender",
  TrendingProducts: "TrendingProductsRecommender",
  TrendingProductsPersonalised: "TrendingProductsPersonalizedRecommender",
  YouMightLike: "YouMightLikeRecommender",
  YouMightLikePersonalised: "YouMightLikePersonalizedRecommender",
  UserPreferences: "UserPreferencesRecommender",
  CompleteTheLook: "CompleteTheLookRecommender",
  ViewedTogether: "ViewedTogetherRecommender",
  NewArrivals: "NewArrivalsRecommender",
  CrossSelling: "CrossSellingRecommender",
};

export const RequiredPreviewFields = {
  [FeedStrategy.BestDeals]: {
    uuid: false,
    sku: false,
  },
  [FeedStrategy.BestDealsPersonalised]: {
    uuid: true,
    sku: false,
  },
  [FeedStrategy.Bestsellers]: {
    uuid: false,
    sku: false,
  },
  [FeedStrategy.BestsellersPersonalised]: {
    uuid: true,
    sku: false,
  },
  [FeedStrategy.MoreLikeThis]: {
    uuid: false,
    sku: true,
  },
  [FeedStrategy.MoreLikeThisPersonalised]: {
    uuid: true,
    sku: true,
  },
  [FeedStrategy.SimilarItems]: {
    uuid: false,
    sku: true,
  },
  [FeedStrategy.SimilarItemsPersonalised]: {
    uuid: true,
    sku: true,
  },
  [FeedStrategy.PopularInCategory]: {
    uuid: true,
    sku: false,
  },
  [FeedStrategy.PopularInCategoryPersonalised]: {
    uuid: true,
    sku: false,
  },
  [FeedStrategy.ViewedMost]: {
    uuid: false,
    sku: false,
  },
  [FeedStrategy.ViewedMostPersonalised]: {
    uuid: true,
    sku: false,
  },
  [FeedStrategy.NextBuy]: {
    uuid: false,
    sku: true,
  },
  [FeedStrategy.NextBuyPersonalised]: {
    uuid: true,
    sku: true,
  },
  [FeedStrategy.PurchasedRecently]: {
    uuid: true,
    sku: false,
  },
  [FeedStrategy.SpecialDeals]: {
    uuid: true,
    sku: false,
  },
  [FeedStrategy.SpecialDealsPersonalised]: {
    uuid: true,
    sku: false,
  },
  [FeedStrategy.ViewedRecently]: {
    uuid: true,
    sku: false,
  },
  [FeedStrategy.TrendingProducts]: {
    uuid: false,
    sku: false,
  },
  [FeedStrategy.TrendingProductsPersonalised]: {
    uuid: true,
    sku: false,
  },
  [FeedStrategy.YouMightLike]: {
    uuid: true,
    sku: false,
  },
  [FeedStrategy.YouMightLikePersonalised]: {
    uuid: true,
    sku: false,
  },
  [FeedStrategy.UserPreferences]: {
    uuid: true,
    sku: false,
  },
  [FeedStrategy.CompleteTheLook]: {
    uuid: false,
    sku: true,
  },
  [FeedStrategy.ViewedTogether]: {
    uuid: false,
    sku: true,
  },
  [FeedStrategy.NewArrivals]: {
    uuid: true,
  },
  [FeedStrategy.CrossSelling]: {
    uuid: false,
    sku: true,
  },
};

export default FeedStrategy;
