import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(theme => ({
  wrapper: {
    display: "grid",
    gridTemplateColumns: "min-content auto",
    gap: theme.size(16),
  },
  name: {
    fontWeight: theme.typography.fontWeights.medium,
    margin: 0,
  },
  email: {
    color: theme.colors.gray,
    margin: 0,
  },
  modified: {
    margin: 0,
  },
  status: {
    display: "flex",
    alignItems: "center",
  },
}));

export default useStyles;
