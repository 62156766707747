import { CustomFeed } from "@coral/typings";
import deserializeFeedFilters from "../../../../common/utils/feed-filters/deserialize-feed-fiters";
import { FormData } from "..";

function ConvertCustomFeedToFormData(customFeed: CustomFeed): FormData {
  return {
    feedFilters: customFeed.recipe?.filter ? deserializeFeedFilters(customFeed.recipe.filter) : [],
    feedStrategy: customFeed.recipe?.feed?.map(feed => feed?.name!) ?? [],
    orderStrategy: customFeed.recipe?.strategy || "",
    feedName: customFeed.name,
    placement: customFeed.placement || "",
    scenario: customFeed.scenario || "",
    screenName: customFeed.screen_name || "",
    feedStatus: customFeed.state,
  };
}

export default ConvertCustomFeedToFormData;
