import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ApolloError, useReactiveVar } from "@apollo/client";

import { CustomFeedState } from "@coral/typings";
import extractGraphqlError from "common/utils/apollo/extract-graphql-error";
import { useUpdateNewsletterMutation } from "../../../generated";
import { FormData } from "../newsletter-form";
import serializeFeedFilters from "../../../common/utils/feed-filters/serialize-feed-filters";
import useAppNotifications from "../../../common/hooks/use-app-notifications";
import reactiveVariables from "../../../common/graphql/global-reactive-variables";

function useSubmit() {
  const { addSuccessNotification, addErrorNotification, addInProgressNotification, removeNotification } =
    useAppNotifications();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);
  const navigate = useNavigate();

  const [updateNewsletter] = useUpdateNewsletterMutation({
    refetchQueries: ["GetNewsletters"],
  });

  return useCallback(
    async (id: string, formData: FormData) => {
      let notificationId;

      try {
        notificationId = addInProgressNotification("Editing newsletter");

        await updateNewsletter({
          variables: {
            id,
            newsletter: {
              orderStrategy: formData.orderStrategy,
              feedStrategy: formData.feedStrategy,
              htmlTileTemplate: formData.htmlTileTemplate,
              landingPageUrl: formData.landingPageUrl,
              newsletterName: formData.newsletterName,
              feedFilters: serializeFeedFilters(formData.feedFilters ?? []),
              projectId: selectedEnvironmentProjectId,
              state: formData.feedStatus as CustomFeedState,
            },
          },
        });

        addSuccessNotification("Newsletter was edited successfully");
      } catch (error) {
        const message = extractGraphqlError(error as ApolloError);
        addErrorNotification("Error while editing newsletter", message);
      } finally {
        if (notificationId) {
          removeNotification(notificationId);
        }
      }
    },
    [navigate, updateNewsletter],
  );
}

export default useSubmit;
