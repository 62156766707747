import { useNavigate, useParams } from "react-router-dom";
import { GetSearchConfigurationQuery, SearchConfiguration } from "@coral/typings";
import { useReactiveVar } from "@apollo/client";
import _ from "lodash";
import { useMemo } from "react";
import LinearProgress from "@gfg/ui-v2/components/linear-progress";
import { useDeleteSearchConfigurationVersionMutation, useGetSearchConfigurationQuery } from "../../../generated";
import ConfigVersionsList from "../../components/search-config-versions-table";
import PageContainer from "../../../common/components/containers/page-container";
import reactiveVariables from "../../../common/graphql/global-reactive-variables";
import TableHeader from "../../../common/components/table/table-header";

export function isSuccessfulResponse(
  fetchResponse: GetSearchConfigurationQuery["searchConfiguration"] | undefined,
): fetchResponse is SearchConfiguration {
  return fetchResponse?.__typename === "SearchConfiguration";
}

export default function ConfigurationVersionsPage() {
  const { configId, region } = useParams();
  const navigate = useNavigate();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const { loading, data } = useGetSearchConfigurationQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      params: {
        id: configId,
        projectId: selectedEnvironmentProjectId,
      },
    },
  });

  const [deleteSearchConfigVersion] = useDeleteSearchConfigurationVersionMutation({
    refetchQueries: ["GetSearchConfiguration"],
  });

  const sortedVersions = useMemo(() => {
    if (isSuccessfulResponse(data?.searchConfiguration)) {
      return _.orderBy(data?.searchConfiguration.versions, v => v.updatedAt || v.createdAt, "desc");
    }
  }, [data]);

  return (
    <PageContainer>
      {loading && !isSuccessfulResponse(data?.searchConfiguration) && <LinearProgress indeterminate />}
      {isSuccessfulResponse(data?.searchConfiguration) && (
        <>
          <TableHeader
            total={data?.searchConfiguration.versions.length}
            subtitle={data?.searchConfiguration.name}
            actionButtonLabel="Create version"
            onActionButtonClick={() => navigate(`/${region}/search/search-config/${configId}/versions/create`)}
            totalLabel="versions"
          />
          <ConfigVersionsList
            onDuplicate={({ id }) => navigate(`/${region}/search/search-config/${configId}/versions/duplicate/${id}`)}
            onEdit={({ id }) => navigate(`/${region}/search/search-config/${configId}/versions/edit/${id}`)}
            onDelete={({ id }) =>
              deleteSearchConfigVersion({
                variables: {
                  input: { configurationId: configId, projectId: selectedEnvironmentProjectId, versionId: id },
                },
              })
            }
            loading={loading}
            versions={sortedVersions}
          />
        </>
      )}
    </PageContainer>
  );
}
