import { useReactiveVar } from "@apollo/client";

import { useCallback } from "react";
import reactiveVariables from "../../../../common/graphql/global-reactive-variables";
import { useGetFilterAttributesQuery, useGetFilterAttributeValuesLazyQuery } from "../../../../generated";
import ConditionalFilter from "../../../../common/components/form-filters/conditional-filters";
import { FilterAutocompleteMultiselectProps } from "../../../../common/components/form-filters/dynamic-filter-component/filter-components/autocomplete-multiselect-filter";

interface FeedFilterProps {
  disabled?: boolean;
}

interface FeedFilterProps {
  onAddFeedFilter?(): void;
}

export default function FeedFilters({ onAddFeedFilter, disabled = false }: FeedFilterProps) {
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const { data, loading } = useGetFilterAttributesQuery({
    variables: { params: { projectId: selectedEnvironmentProjectId } },
  });

  const [getFilterAttributeValues] = useGetFilterAttributeValuesLazyQuery({});

  const getFacetValues: FilterAutocompleteMultiselectProps["getValues"] = useCallback(
    async ({ attributeId, limit, query }) => {
      const response = await getFilterAttributeValues({
        variables: { params: { attributeId, projectId: selectedEnvironmentProjectId, limit, query } },
      });

      return { data: response.data?.feedFilterAttributeValues?.map(({ value, label }) => ({ value, label })) };
    },
    [getFilterAttributeValues],
  );

  return (
    <ConditionalFilter
      disabled={disabled}
      fieldName="feedFilters"
      loading={loading}
      onAddFeedFilter={onAddFeedFilter}
      attributeOptions={data?.feedFilterAttributes ?? []}
      getFacetValues={getFacetValues}
    />
  );
}
