import { useMemo } from "react";
import { RequiredPreviewFields } from "../../../constants/feed-strategy";

function useVisiblePreviewFields(feedStrategies: string[], requiredFields: typeof RequiredPreviewFields) {
  const showIdField = useMemo(
    () => feedStrategies.some(s => requiredFields[s]?.uuid),
    [feedStrategies, requiredFields],
  );
  const showSkuField = useMemo(
    () => feedStrategies.some(s => requiredFields[s]?.sku),
    [feedStrategies, requiredFields],
  );

  return { showIdField, showSkuField };
}

export default useVisiblePreviewFields;
