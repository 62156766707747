import { Column as DataTableColumn } from "@gfg/ui-v2/components/data-table/data-table";
import { CampaignShort, SearchConfigType } from "@coral/typings";
import { Link, useParams } from "react-router-dom";
import { useMemo } from "react";
import useStyles from "~/search-config/components/search-config-versions-table/use-styles";
import date from "~/common/services/dates";
import TitleSubtitleCell from "~/common/components/table/cells/title-subtitle-cell";

export interface Column extends DataTableColumn<CampaignShort> {
  sortField?: string;
}

const getSubtitleByMerchandisingType = (type: SearchConfigType) => {
  switch (type) {
    case "Campaign":
      return "Campaign ID";
    case "Brand":
      return "Brand name";
    case "Category":
      return "Category name";
    case "Search":
      return "Search term";
    default:
      return "";
  }
};

export default function useColumns(): Column[] {
  const { classes } = useStyles();
  const { region } = useParams();

  return useMemo(
    () => [
      {
        id: "id",
        label: "Name",
        renderer: r => (
          <TitleSubtitleCell
            title={<Link to={`/${region}/merchandising/edit/${r.id}`}>{r.name.slice(0, 60)}</Link>}
            subtitle={
              <span>
                [{getSubtitleByMerchandisingType(r.type)}] {r.value}
              </span>
            }
          />
        ),
        sticky: true,
      },
      {
        id: "type",
        label: "Type",
        renderer: r => r.type,
        sticky: true,
      },
      {
        id: "last-modified",
        label: "Created/Modified by",
        renderer: r =>
          r.modifiedBy ? (
            <TitleSubtitleCell title={r.modifiedBy} subtitle={date.format(date.fromSecondsSinceEpoch(r.updatedAt))} />
          ) : (
            <TitleSubtitleCell title={r.createdBy} subtitle={date.format(date.fromSecondsSinceEpoch(r.createdAt))} />
          ),
        sortField: "UPDATED_AT",
        sortable: true,
      },
    ],
    [classes],
  );
}
