import { LinearProgress } from "@gfg/ui-v2/components/linear-progress";
import { makeStyles } from "@gfg/ui-v2/theming";
import AdminHeader from "../admin-header/admin-header";
import useGetUsers from "../../hooks/use-get-users";
import TableFooter from "../../../common/components/table/table-footer";
import AdminDataTable from "./data-table";

const useStyles = makeStyles()(theme => ({
  tableContainer: {
    "boxShadow": theme.shadow("md"),
    "& $table .tableBody tr": {
      borderTopColor: "transparent",
    },
    "& table": {
      display: "table",
    },
  },
  headerCell: {
    color: theme.colors.text,
    background: theme.colors.background,
  },
}));

export default function AdminTable() {
  const { classes } = useStyles();
  const { users, searchInput, setSearchInput, loading, offset, setOffset, limit, setLimit } = useGetUsers();

  if (!users) {
    return <LinearProgress indeterminate />;
  }

  return (
    <div className={classes.tableContainer}>
      <AdminHeader searchInput={searchInput} setSearchInput={setSearchInput} total={users.total} />
      <AdminDataTable loading={loading} users={users.data} />
      <TableFooter
        onOffsetChange={setOffset}
        offset={offset}
        total={users.total}
        limit={limit.toString()}
        onLimitChange={setLimit}
      />
    </div>
  );
}
