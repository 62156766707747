import { useCallback } from "react";
import { useReactiveVar } from "@apollo/client";
import { useCreateSearchOptimizationMutation } from "~/generated";
import { FormData } from "../components/optimizations-form";
import reactiveVariables from "~/common/graphql/global-reactive-variables";

function useCreateSearchOptimization() {
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const [createSearchOptimization] = useCreateSearchOptimizationMutation({
    refetchQueries: ["GetSearchOptimizations"],
  });

  return useCallback(
    async (formData: FormData) =>
      await createSearchOptimization({
        variables: {
          params: {
            query: formData.query,
            output: formData.output,
            state: formData.state,
            projectId: selectedEnvironmentProjectId,
          },
        },
      }),
    [createSearchOptimization],
  );
}

export default useCreateSearchOptimization;
