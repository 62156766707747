import makeVarPersisted from "../utils/apollo/make-var-persisted";

export const SELECTED_ENVIRONMENT_VARIABLE_LOCAL_STORAGE_KEY = "selected_environment_variable";

const reactiveVariables = {
  selectedEnvironmentIdVar: makeVarPersisted<string | undefined>(
    undefined,
    SELECTED_ENVIRONMENT_VARIABLE_LOCAL_STORAGE_KEY,
  ),
};

export default reactiveVariables;
