import { useReactiveVar } from "@apollo/client";
import { SearchConfigAttributeType } from "@coral/typings";
import LinearProgress from "@gfg/ui-v2/components/linear-progress";
import AttributeFilter from "../../../../../common/components/form-filters/attribute-filters";
import reactiveVariables from "../../../../../common/graphql/global-reactive-variables";
import { useGetSearchConfigAttributesQuery } from "../../../../../generated";

interface SearchConfigAttributeFilterProps {
  fieldName: string;
  disabled: boolean;
  addAttributeButtonLabel: string;
  type: SearchConfigAttributeType;
}

export default function SearchConfigAttributeFilter({
  disabled,
  fieldName,
  addAttributeButtonLabel,
  type,
}: SearchConfigAttributeFilterProps) {
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const { data, loading } = useGetSearchConfigAttributesQuery({
    variables: { params: { attributeType: type, projectId: selectedEnvironmentProjectId } },
  });

  return (
    <>
      {loading && <LinearProgress indeterminate />}
      {!loading && (
        <AttributeFilter
          fieldName={fieldName}
          disabled={disabled}
          addAttributeButtonLabel={addAttributeButtonLabel}
          attributeOptions={data?.searchConfigAttributes || []}
        />
      )}
    </>
  );
}
