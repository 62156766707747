import React from "react";
import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ spacing, palette }) => ({
  container: {
    padding: spacing(24),
    borderBottom: `1px solid ${palette.tertiary[200]}`,
  },
  title: {
    margin: 0,
  },
}));

interface ProductSectionTitleProps {
  title: string;
}

export default function ProductSectionTitle({ title }: ProductSectionTitleProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <h5 className={classes.title}>{title}</h5>
    </div>
  );
}
