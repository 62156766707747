/* eslint-disable unicorn/no-thenable */
import * as yup from "yup";
import feedFilterObjectShape from "../../../common/components/form-filters/conditional-filters/validation-schema";

export default yup.object().shape({
  feedName: yup.string().nullable().required("Feed name is required"),
  feedStrategy: yup
    .array()
    .min(1, "Please select at least one feed strategy")
    .nullable()
    .required("Please select at least one feed strategy"),
  feedStatus: yup.string().nullable().required("Feed status is required"),
  orderStrategy: yup
    .string()
    .nullable()
    .when("feedStrategy", {
      is: (feedStrategy: string[]) => feedStrategy && feedStrategy.length > 1,
      then: yup.string().required("Please select an order strategy"),
      otherwise: yup.string().nullable(),
    }),
  scenario: yup.string().nullable(),
  screenName: yup.string().nullable(),
  placement: yup.string().nullable(),
  feedFilters: yup.array().of(feedFilterObjectShape).nullable(),
});
