import produce from "immer";
import { useCallback, useMemo, useState } from "react";
import { convertStringToSynonyms } from "../../utils";

export interface SynonymInEditMode {
  synonym: string;
  newState?: "added" | "deleted";
}

interface EditedSynonymsState {
  [synonymName: string]: SynonymInEditMode;
}

function getEditedSynonyms(synonyms: string[]): EditedSynonymsState {
  return Object.fromEntries(synonyms.map(synonym => [synonym, { synonym, newState: undefined }]));
}

const filterAndMapSynonyms = (synonyms: SynonymInEditMode[]) =>
  synonyms?.filter(({ newState }) => newState !== "deleted").map(({ synonym }) => synonym);

function useSynonymsEditor() {
  const [editedSynonymState, setEditedSynonymState] = useState<{
    inputs: EditedSynonymsState;
    outputs: EditedSynonymsState;
  }>({
    inputs: {},
    outputs: {},
  });
  const [editMode, setEditMode] = useState(false);

  const synonymsInEditMode = useMemo(
    () => ({
      inputs: Object.values(editedSynonymState.inputs),
      outputs: Object.values(editedSynonymState.outputs),
    }),
    [editedSynonymState],
  );

  const unsavedChanges = useMemo(
    () => Object.values(synonymsInEditMode).some(synonyms => synonyms.some(es => es.newState !== undefined)),
    [synonymsInEditMode],
  );

  const handleAddSynonymsToSet = useCallback(
    (synonymsInputValue: string, type: "INPUT" | "OUTPUT") => {
      const newSynonyms = convertStringToSynonyms(synonymsInputValue);

      setEditedSynonymState(oldState =>
        produce(oldState, draft => {
          for (const synonym of newSynonyms) {
            draft[type === "INPUT" ? "inputs" : "outputs"][synonym] = draft[type === "INPUT" ? "inputs" : "outputs"][
              synonym
            ] ?? { synonym, newState: "added" };
          }
        }),
      );
    },
    [convertStringToSynonyms, setEditedSynonymState, produce],
  );

  const handleDeleteSynonymFromSet = useCallback(
    (editedSynonym: SynonymInEditMode, type: "INPUT" | "OUTPUT") => {
      if (editedSynonym.newState === "added") {
        setEditedSynonymState(oldState =>
          produce(oldState, draft => {
            delete draft[type === "INPUT" ? "inputs" : "outputs"][editedSynonym.synonym];
          }),
        );
      } else if (editedSynonym.newState === "deleted") {
        setEditedSynonymState(oldState =>
          produce(oldState, draft => {
            // if synonym was deleted and it is "deleted" again - it will reappear back as not deleted
            draft[type === "INPUT" ? "inputs" : "outputs"][editedSynonym.synonym].newState = undefined;
          }),
        );
      } else {
        setEditedSynonymState(oldState =>
          produce(oldState, draft => {
            draft[type === "INPUT" ? "inputs" : "outputs"][editedSynonym.synonym].newState = "deleted";
          }),
        );
      }
    },
    [setEditedSynonymState, produce],
  );

  const handleStartEditing = useCallback(
    (inputs: string[], outputs: string[]) => {
      setEditedSynonymState({
        inputs: getEditedSynonyms(inputs),
        outputs: getEditedSynonyms(outputs),
      });
      setEditMode(true);
    },
    [setEditedSynonymState, setEditMode],
  );

  const handleStopEditing = useCallback(() => {
    setEditedSynonymState({
      inputs: {},
      outputs: {},
    });
    setEditMode(false);
  }, [setEditedSynonymState, setEditMode]);

  return {
    synonymsInEditMode,
    synonymsAfterChanges: {
      inputs: filterAndMapSynonyms(synonymsInEditMode.inputs),
      outputs: filterAndMapSynonyms(synonymsInEditMode.outputs),
    },
    unsavedChanges,
    addSynonymsToSet: handleAddSynonymsToSet,
    deleteSynonymFromSet: handleDeleteSynonymFromSet,
    startEditing: handleStartEditing,
    stopEditing: handleStopEditing,
    isEditInProgress: editMode,
  };
}

export default useSynonymsEditor;
