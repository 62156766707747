import { Dispatch, SetStateAction } from "react";
import { SearchInput } from "@gfg/ui-v2/components/input";
import { makeStyles } from "@gfg/ui-v2/theming";
import SearchResult from "./search-result";
import CreateButton from "../create-button";

const useStyles = makeStyles()(({ spacing, colors, breakpoints, palette }) => ({
  container: {
    display: "flex",
    borderBottom: `1px solid ${palette.tertiary[200]}`,
    alignItems: "center",
    textAlign: "center",
    justifyContent: "space-between",
    height: spacing(84),
    padding: spacing(0, 24),
    marginBottom: spacing(24),
    background: colors.background,

    [breakpoints.down("xs")]: {
      height: "auto",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: spacing(10),
    },
  },
  headerActionsContainer: {
    display: "flex",
    flexDirection: "unset",

    [breakpoints.down("xs")]: {
      flexWrap: "wrap",
    },
  },
  search: {
    alignSelf: "center",
    border: `1px solid ${palette.tertiary[200]}`,
    width: spacing(400),
    height: spacing(44),
    marginRight: spacing(16),
    color: colors.gray,

    [breakpoints.down("lg")]: {
      width: spacing(200),
    },

    [breakpoints.down("xs")]: {
      width: "100%",
      marginRight: 0,
      marginBottom: spacing(10),
    },
  },
}));

interface TableHeaderProps {
  totalLabel?: string;
  subtitle: string;
  searchInput?: string;
  searchInputPlaceholder?: string;
  total: number;
  actionButtonLabel: string;
  onActionButtonClick(): void;

  onSearchInputChange?: Dispatch<SetStateAction<string>>;
  onSearchInputFocus?(): void;
}

export default function TableHeader({
  searchInput,
  searchInputPlaceholder,
  subtitle,
  onSearchInputChange,
  onSearchInputFocus,
  onActionButtonClick,
  actionButtonLabel,
  total,
  totalLabel = "",
}: TableHeaderProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <SearchResult total={total} subtitle={subtitle} label={totalLabel} />
      <div className={classes.headerActionsContainer}>
        {onSearchInputChange && (
          <SearchInput
            color="lightgray"
            placeholder={searchInputPlaceholder}
            size="medium"
            variant="outline"
            className={classes.search}
            onChange={onSearchInputChange}
            onFocus={onSearchInputFocus}
            value={searchInput}
          />
        )}
        <CreateButton onClick={onActionButtonClick} text={actionButtonLabel} />
      </div>
    </div>
  );
}
