import React, { useCallback } from "react";
import { TableBody, TableCell, TableRow } from "@gfg/ui-v2/components/table";
import { CustomFeed } from "@coral/typings";
import Tooltip from "@gfg/ui-v2/components/tooltip";
import { IconButton } from "@gfg/ui-v2/components/button";
import { Link, useNavigate, useParams } from "react-router-dom";
import produce from "immer";
import { ApolloError, useReactiveVar } from "@apollo/client";

import SuccessStatusTag from "common/components/status-tags/success-tag";
import GrayStatusTag from "common/components/status-tags/gray-tag";
import { CustomFeedStateTranslations } from "common/translations";
import extractGraphqlError from "common/utils/apollo/extract-graphql-error";
import { makeStyles } from "@gfg/ui-v2/theming";
import ActionCell from "./cells/action-cell";
import TitleSubtitleCell from "../../../common/components/table/cells/title-subtitle-cell";
import date from "../../../common/services/dates";
import useCopyClipboard from "../../../common/hooks/use-copy-clipboard";
import { useDeleteFeedMutation } from "../../../generated";
import useAppNotifications from "../../../common/hooks/use-app-notifications";
import useEventTracking from "../../../common/hooks/use-tracking-event";
import reactiveVariables from "../../../common/graphql/global-reactive-variables";

interface FeedsListProps {
  feeds: CustomFeed[] | null;
}

const useStyles = makeStyles()(({ colors, palette, spacing, breakpoints }) => ({
  rowGrey: {
    background: palette.gray[100],
  },
  rowLight: {
    background: colors.background,
  },
  limitedCell: {
    maxWidth: 240,
    paddingRight: spacing(38),

    [breakpoints.down("md")]: {
      maxWidth: "initial",
      paddingRight: 0,
    },
  },
  iconButton: {
    height: 0,
    paddingLeft: 0,
  },
}));

export default function FeedsList({ feeds }: FeedsListProps) {
  const { classes } = useStyles();
  const sendDuplicateButtonAnalyticsEvent = useEventTracking({ eventName: "recos-onsite-duplicate" });
  const sendCopyButtonAnalyticsEvent = useEventTracking({ eventName: "recos-onsite-copy-url" });
  const sendCopyIdButtonAnalyticsEvent = useEventTracking({ eventName: "recos-onsite-copy-id" });

  const { addSuccessNotification, addErrorNotification } = useAppNotifications();
  const { copyToClipboard } = useCopyClipboard();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);
  const { region } = useParams();

  const [deleteFeed] = useDeleteFeedMutation({
    updateQueries: {
      GetFeeds: (prev, { mutationResult }) =>
        produce(prev, draft => {
          draft.customFeeds.data = draft.customFeeds.data.filter(
            (feed: CustomFeed) => feed.id !== mutationResult.data?.deleteCustomFeeds?.id,
          );
          draft.customFeeds.total -= 1;
        }),
    },
  });
  const navigate = useNavigate();

  const handleCopyToClipboard = useCallback(async (text: string, value: string) => {
    const response = await copyToClipboard(value);
    if (response) {
      addSuccessNotification(`Copied ${text} to clipboard`, value);
    }
  }, []);

  const handleDuplicate = (feedId: string) => {
    navigate(`/${region}/recommendations/feed/duplicate/${feedId}`);
    sendDuplicateButtonAnalyticsEvent();
  };

  async function handleDeleteFeed(id: string) {
    try {
      await deleteFeed({
        refetchQueries: ["GetFeeds"],
        variables: {
          params: {
            id,
            projectId: selectedEnvironmentProjectId,
          },
        },
      });

      addSuccessNotification("Feed was deleted successfully");
    } catch (error) {
      const message = extractGraphqlError(error as ApolloError);
      addErrorNotification("You do not have access to delete this feed", message);
    }
  }

  const createStatusTag = (feed: CustomFeed) =>
    feed.state === "Active" ? (
      <SuccessStatusTag label={CustomFeedStateTranslations[feed.state]} />
    ) : (
      <GrayStatusTag label={CustomFeedStateTranslations[feed.state]} />
    );

  return (
    <TableBody className="tableBody">
      {feeds?.map((feed, index) => (
        <TableRow key={feed.id} className={index % 2 === 0 ? classes.rowGrey : classes.rowLight}>
          <TitleSubtitleCell
            title={<Link to={`/${region}/recommendations/feed/edit/${feed.id}`}>{feed.name}</Link>}
            subtitle={
              <Tooltip
                triggerElement={
                  <IconButton
                    onClick={() => {
                      handleCopyToClipboard("Customfeed Id: ", feed.id);
                      sendCopyIdButtonAnalyticsEvent();
                    }}
                    color="gray"
                    icon={<span>{feed.id}</span>}
                    variant="link"
                    className={classes.iconButton}
                  />
                }
                variant="dark"
              >
                Click to copy
              </Tooltip>
            }
          />
          <TitleSubtitleCell
            title={feed.updated_by}
            subtitle={feed.updated_at && date.format(date.fromSecondsSinceEpoch(feed.updated_at))}
          />
          <TableCell>
            <div>
              <span>{createStatusTag(feed)}</span>
            </div>
          </TableCell>

          <ActionCell
            copyToClipboard={() => {
              handleCopyToClipboard("Url: ", feed.url || "");
              sendCopyButtonAnalyticsEvent();
            }}
            deleteFeed={() => handleDeleteFeed(feed.id)}
            onEdit={() => navigate(`/${region}/recommendations/feed/edit/${feed.id}`)}
            onDuplicate={() => handleDuplicate(feed.id)}
          />
        </TableRow>
      ))}
    </TableBody>
  );
}
