import { useEffect, useState } from "react";
import { PaginatedCampaigns } from "@coral/typings";

import { useReactiveVar } from "@apollo/client";
import { useGetCampaignsLazyQuery } from "../../generated";
import pagination from "../../common/constants/pagination";
import reactiveVariables from "../../common/graphql/global-reactive-variables";

export default function useGetCampaigns() {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(+pagination.LIMITS[0]);
  const [searchInput, setSearchInput] = useState("");
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const [getCampaigns, { data, previousData, loading }] = useGetCampaignsLazyQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      filter: {
        projectId: selectedEnvironmentProjectId,
        search: searchInput,
      },
      pagination: {
        offset,
        limit,
      },
    },
  });
  const campaigns = (data ?? previousData)?.campaigns as PaginatedCampaigns;

  useEffect(() => {
    const timerId = setTimeout(() => {
      getCampaigns({
        variables: {
          filter: {
            projectId: selectedEnvironmentProjectId,
            search: searchInput,
          },
          pagination: {
            offset,
            limit,
          },
        },
      });
    }, 1);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchInput, selectedEnvironmentProjectId, offset, limit]);

  return {
    campaigns,
    searchInput,
    setSearchInput,
    loading,
    offset,
    setOffset,
    limit,
    setLimit,
  };
}
