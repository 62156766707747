import { TableBody } from "@gfg/ui-v2/components/table";
import { SearchConfigurationShort } from "@coral/typings";

import Row from "./row";

interface SearchConfigListProps {
  searchConfigurations: SearchConfigurationShort[];
}

export default function SearchConfigList({ searchConfigurations }: SearchConfigListProps) {
  return (
    <TableBody className="tableBody">
      {searchConfigurations?.map((searchConfig, index) => (
        <Row key={searchConfig.id} searchConfig={searchConfig} index={index} />
      ))}
    </TableBody>
  );
}
