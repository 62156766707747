import { makeStyles } from "@gfg/ui-v2/theming";
import AdminTable from "./components/admin-table";
import Title from "../common/components/title";

const useStyles = makeStyles()(({ spacing }) => ({
  container: {
    margin: `0 ${spacing(16)}`,
  },
  title: {
    margin: spacing(30, 0),
  },
}));

export default function AdminPage() {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <Title text="Admin" className={classes.title} />
      <AdminTable />
    </div>
  );
}
