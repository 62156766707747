import { ResourceName } from "@coral/typings";
import { useGetChartGroupsQuery } from "~/generated";

interface UseChartGroupsProps {
  projectId: string;
  section: ResourceName;
}

export default function useChartGroups({ projectId, section }: UseChartGroupsProps) {
  return useGetChartGroupsQuery({
    variables: {
      params: {
        projectId,
        section,
      },
    },
  });
}
