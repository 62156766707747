import { TextButtonProps } from "@gfg/ui-v2/components/button";
import CircularProgress from "@gfg/ui-v2/components/circular-progress";
import { useCallback, useEffect, useState } from "react";
import { LoginFailureReason, useAuth } from "../auth-provider";
import useGoogleClientId from "../hooks/use-google-client-id";

export interface LoginWithGoogleProps extends TextButtonProps {
  onSuccess(): void;
  onFailure(error: LoginFailureReason): void;
}

export default function LoginWithGoogleButton({ onSuccess, onFailure }: LoginWithGoogleProps) {
  const clientId = useGoogleClientId();
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();

  const handleSignIn = useCallback(async (token: string) => {
    setLoading(true);

    try {
      const result = await login(token);
      if (result == null) {
        onSuccess?.();
      } else {
        onFailure?.(result);
      }
      onFailure?.("unknown_error");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (clientId) {
      const { google } = window as any;

      google.accounts.id.initialize({
        client_id: clientId,
        callback: async ({ credential }: any) => handleSignIn(credential),
        ux_mode: "popup",
      });

      google.accounts.id.prompt();
      google.accounts.id.renderButton(
        /** @type{!HTMLElement} */ document.getElementById("google-sign-in-button"),
        /** @type{!GsiButtonConfiguration} */ {},
      );
    }
  }, [clientId]);

  if (loading) return <CircularProgress />;

  return <div id="google-sign-in-button" />;
}
