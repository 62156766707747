import { TableRowAction, TableRowActionProps } from "@gfg/ui-v2/components/table";
import { Column, DataTable, useTableState } from "@gfg/ui-v2/components/data-table";
import { User } from "@coral/typings";
import { useCallback, useState } from "react";
import { CopyIcon, Edit3Icon, Trash2Icon } from "@gfg/ui-v2/icons";
import { makeStyles } from "@gfg/ui-v2/theming";
import DeleteModal from "../../../common/components/delete-modal";
import { useDeleteUserMutation } from "../../../generated";
import { createUserModalOpenStatusVar, selectedUserVar } from "../../../common/graphql/cache";
import useColumns from "./use-columns";
import useAppNotifications from "../../../common/hooks/use-app-notifications";
import { useAuth } from "../../../auth/auth-provider";

export const useStyles = makeStyles()(theme => ({
  wrapper: {
    display: "grid",
    gridTemplateColumns: "min-content auto",
    gap: theme.size(16),
  },
  name: {
    fontWeight: theme.typography.fontWeights.medium,
  },
  email: {
    color: theme.colors.gray,
  },
}));

function useTableRowActions(onAction: (action: "copy" | "edit" | "delete", row: User) => void) {
  const { viewer } = useAuth();

  const isProd = process.env.NODE_ENV === "production";

  return useCallback(
    (data: User[], row: User): React.ReactElement<TableRowActionProps>[] | null => [
      <TableRowAction key={`${row.id}-copy`} icon={CopyIcon} value="copy" onClick={() => onAction("copy", row)}>
        Copy User
      </TableRowAction>,
      <TableRowAction
        key={`${row.id}-edit`}
        disabled={isProd && viewer.id === row.id}
        icon={Edit3Icon}
        value="edit"
        onClick={() => onAction("edit", row)}
      >
        Edit User
      </TableRowAction>,
      <TableRowAction
        key={`${row.id}-delete`}
        disabled={isProd && viewer.id === row.id}
        icon={Trash2Icon}
        value="delete"
        onClick={() => onAction("delete", row)}
      >
        Delete User
      </TableRowAction>,
    ],
    [],
  );
}

type ActionRow = "copy" | "edit" | "delete";

function useDeleteUser() {
  const { addSuccessNotification, addErrorNotification } = useAppNotifications();
  const [doDeleteUser] = useDeleteUserMutation({
    refetchQueries: ["GetUsers"],
  });
  return useCallback(async (id: string) => {
    try {
      await doDeleteUser({ variables: { id } });
      addSuccessNotification("User was deleted successfully");
    } catch {
      addErrorNotification("You do not have access to delete this user");
    }
  }, []);
}

export default function AdminDataTable({ loading, users }: { loading: boolean; users: User[] }) {
  const columns: Column<User>[] = useColumns();

  const [toDelete, setToDelete] = useState<User | null>(null);
  const handleAction = useCallback((action: ActionRow, row: User) => {
    switch (action) {
      case "delete":
        setToDelete(row);
        break;
      case "edit":
        selectedUserVar({ ...row, operation: "edit" });
        createUserModalOpenStatusVar(true);
        break;
      case "copy":
        selectedUserVar({ ...row, operation: "copy" });
        createUserModalOpenStatusVar(true);
        break;
      default:
    }
  }, []);

  const [state] = useTableState(columns, u => u.id);
  const actions = useTableRowActions(handleAction);

  const deleteUser = useDeleteUser();

  return (
    <>
      <DeleteModal
        open={!!toDelete}
        title="Delete User"
        text="Are you sure you want to delete this user?"
        onToggle={() => setToDelete(null)}
        onDelete={() => deleteUser(toDelete.id)}
      />
      <DataTable actions={actions} selectable={false} columns={columns} state={state} data={users} loading={loading} />
    </>
  );
}
