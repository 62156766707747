import { makeStyles } from "@gfg/ui-v2/theming";
import LoginSection from "../components/login-section";
import LoginCover from "../components/login-cover";

const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    flexDirection: "column-reverse",

    [theme.breakpoints.up("xl")]: {
      flexDirection: "row",
    },
  },
}));

export default function LoginPage() {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <LoginSection />
      <LoginCover />
    </div>
  );
}
