import { createContext, PropsWithChildren, useCallback, useEffect, useReducer, useContext } from "react";
import { useApolloClient } from "@apollo/client";
import { LoginFailureReason as BackendLoginFailureReason, ViewerQuery } from "@coral/typings";
import CircularProgress from "@gfg/ui-v2/components/circular-progress";
import useViewer from "./hooks/use-viewer";
import useLogin from "./hooks/use-login";
import { renewToken, setToken } from "./token-service";

export type LoginFailureReason = BackendLoginFailureReason | "unknown_error";
interface AuthContextProps {
  viewer: ViewerQuery["viewer"];

  login(token: string): Promise<LoginFailureReason | null>;
  logout(): Promise<void>;
}

const AuthContext = createContext<AuthContextProps>({
  viewer: null,
  login: async () => "unknown_error",
  logout: async () => {},
});

export default function AuthProvider(props: PropsWithChildren<{}>) {
  const { loading, viewer } = useViewer();
  const [initialised, initialise] = useReducer(() => true, false);
  const client = useApolloClient();
  const doLogin = useLogin();

  useEffect(() => {
    if (!loading) {
      initialise();
    }
  }, [loading]);

  const login = useCallback(async (token: string) => {
    const result = await doLogin(token);

    if (result?.__typename === "SuccessfulLoginResponse") {
      const token = await renewToken(result.token);

      if (token) {
        await client.resetStore();
      }
      return null;
    }

    return result?.__typename === "FailedLoginResponse" ? result.reason : "unknown_error";
  }, []);

  const logout = useCallback(async () => {
    await fetch("/token", {
      method: "DELETE",
      credentials: "include",
    });
    setToken("");
    await client.resetStore();
  }, []);

  return initialised ? <AuthContext.Provider value={{ viewer, login, logout }} {...props} /> : <CircularProgress />;
}

export function useAuth() {
  const ctx = useContext(AuthContext);

  if (!ctx) {
    throw new Error("Cannot be used outside AuthContext");
  }

  return ctx;
}
