import { useState } from "react";
import { Edit3Icon, LinkIcon, Trash2Icon } from "@gfg/ui-v2/icons";
import { IconButton } from "@gfg/ui-v2/components/button";
import Tooltip from "@gfg/ui-v2/components/tooltip";
import { makeStyles } from "@gfg/ui-v2/theming";
import DeleteModal from "../../../../common/components/delete-modal";
import CopyIcon from "../../../../common/icons/copy-icon";
import ActionCellWithIcons from "../../../../common/components/table/cells/action-cell";

const useStyles = makeStyles()(({ palette }) => ({
  icon: {
    background: palette.secondary[100],
  },
}));

interface ActionCellProps {
  deleteNewsletter(): void;
  copyToClipboard(): void;
  onEdit(): void;
  onDuplicate(): void;
}

export default function ActionCell({ deleteNewsletter, copyToClipboard, onEdit, onDuplicate }: ActionCellProps) {
  const { classes } = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <>
      <ActionCellWithIcons>
        <Tooltip
          triggerElement={
            <IconButton onClick={copyToClipboard} icon={LinkIcon} round variant="light" className={classes.icon} />
          }
          variant="dark"
        >
          Click to copy Config URL
        </Tooltip>
        <IconButton onClick={onDuplicate} icon={CopyIcon} round variant="light" className={classes.icon} />
        <IconButton onClick={onEdit} icon={Edit3Icon} round variant="light" className={classes.icon} />
        <IconButton onClick={() => setOpen(true)} icon={Trash2Icon} round variant="light" className={classes.icon} />
      </ActionCellWithIcons>
      <DeleteModal
        open={open}
        title="Delete Newsletter"
        text="Are you sure you want to delete this newsletter?"
        onToggle={setOpen}
        onDelete={deleteNewsletter}
      />
    </>
  );
}
