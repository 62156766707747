import { ListItem } from "@gfg/ui-v2/components/list";
import React, { useCallback, useMemo } from "react";

import { Tag } from "@gfg/ui-v2/components/tag";
import { makeStyles } from "@gfg/ui-v2/theming";
import { ChevronRightIcon } from "@gfg/ui-v2/icons";
import Actions from "./actions";
import useSynonymsEditor, { SynonymInEditMode } from "./use-synonyms-editor";
import useUpdateSynonymsSet from "../../hooks/use-update-synonyms-set";
import InProgressDialogNotification from "../../../common/components/dialogs/in-progress-dialog-notification";
import InputFieldWithTags from "~/unidirectional-synonyms/components/synonyms-set/input-field-with-tags";
import yupValidateWithoutThrow from "~/common/utils/form/yup-validate-without-throw";
import validationSchema from "~/unidirectional-synonyms/components/synonyms-header/validation-schema";

const useStyles = makeStyles()(({ spacing, size, shadow }) => ({
  tag: {
    "width": "fit-content",
    "marginLeft": spacing("sm"),
    "border": "none",

    "&:first-of-type": {
      marginLeft: 0,
    },
  },
  inputSeparator: {
    paddingTop: spacing("sm"),
  },
  separator: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: spacing("xs"),
    paddingRight: spacing("xs"),
  },
  editSynonymsSets: {
    alignItems: "flex-start",
  },
  synonymSets: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  set: {
    display: "inline-flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: spacing("xs", 0),
  },
  synonymItem: {
    margin: spacing(20, 1),
    borderRadius: size(4),
    padding: spacing(16, 16),
    boxShadow: shadow("sm"),
  },
}));

export interface SynonymsSetProps {
  id: string;
  inputs: string[];
  outputs: string[];
  onDelete(): void;
}

export default function SynonymsSet({ id, inputs, outputs, onDelete }: SynonymsSetProps) {
  const { classes, cx } = useStyles();
  const { updateSynonymsSet, inProgress: updateInProgress } = useUpdateSynonymsSet();

  const {
    synonymsInEditMode,
    isEditInProgress,
    unsavedChanges,
    synonymsAfterChanges,
    startEditing,
    stopEditing,
    deleteSynonymFromSet,
    addSynonymsToSet,
  } = useSynonymsEditor();

  const handleEnableEditMode = useCallback(() => {
    startEditing(inputs, outputs);
  }, [startEditing, inputs, outputs]);

  const handleSave = useCallback(async () => {
    if (!unsavedChanges) {
      stopEditing();
      return;
    }

    const { inputs, outputs } = synonymsAfterChanges;

    const successfullySaved = await updateSynonymsSet(id, inputs, outputs);

    if (successfullySaved) {
      stopEditing();
    }
  }, [unsavedChanges, updateSynonymsSet, synonymsAfterChanges, id, stopEditing]);

  const handleAddSynonymsToSet = (newSynonym: string, type: "INPUT" | "OUTPUT") => {
    addSynonymsToSet(newSynonym, type);
  };

  const handleDeleteSynonym = useCallback(
    (synonymInEditMode: SynonymInEditMode, type: "INPUT" | "OUTPUT") => {
      deleteSynonymFromSet(synonymInEditMode, type);
    },
    [deleteSynonymFromSet],
  );

  const isAddMode = useMemo(
    () =>
      Object.values(synonymsInEditMode)
        .flat()
        .some(item => item.newState === "added"),
    [],
  );

  const isSaveButtonDisabled = useMemo(
    () =>
      yupValidateWithoutThrow(validationSchema, {
        inputs: synonymsAfterChanges.inputs.join(", "),
        outputs: synonymsAfterChanges.outputs.join(", "),
      })?.length > 0,
    [synonymsAfterChanges],
  );

  return (
    <>
      <ListItem
        className={classes.synonymItem}
        active={isEditInProgress}
        rightIcon={
          <Actions
            editMode={isEditInProgress}
            unsavedChanges={unsavedChanges}
            onDelete={onDelete}
            onEdit={handleEnableEditMode}
            onSave={handleSave}
            isAddMode={isAddMode}
            isSaveButtonDisabled={isSaveButtonDisabled}
          />
        }
      >
        {!isEditInProgress ? (
          <div className={classes.synonymSets}>
            <div className={classes.set}>
              {inputs.map(synonym => (
                <Tag key={synonym} label={synonym} variant="faded" className={classes.tag} />
              ))}
            </div>

            <div className={classes.separator}>
              <ChevronRightIcon />
            </div>
            <div className={classes.set}>
              {outputs.map(synonym => (
                <Tag key={synonym} label={synonym} variant="faded" className={classes.tag} />
              ))}
            </div>
          </div>
        ) : (
          <div className={cx(classes.synonymSets, classes.editSynonymsSets)}>
            <InputFieldWithTags
              selectedValues={synonymsInEditMode.inputs}
              onRemove={s => handleDeleteSynonym(s, "INPUT")}
              onAdd={s => handleAddSynonymsToSet(s, "INPUT")}
            />
            <div className={cx(classes.separator, classes.inputSeparator)}>
              <ChevronRightIcon />
            </div>
            <InputFieldWithTags
              selectedValues={synonymsInEditMode.outputs}
              onRemove={s => handleDeleteSynonym(s, "OUTPUT")}
              onAdd={s => handleAddSynonymsToSet(s, "OUTPUT")}
            />
          </div>
        )}
      </ListItem>
      <InProgressDialogNotification open={updateInProgress} closable={false} text="Updating synonyms" />
    </>
  );
}
