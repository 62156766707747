import { InputField } from "@gfg/ui-v2/components/input";
import { CampaignPreview, SearchConfigPreview } from "@coral/typings";
import LinearProgress from "@gfg/ui-v2/components/linear-progress";
import { useCallback, useState } from "react";
import { makeStyles } from "@gfg/ui-v2/theming";
import TableFooter from "../../../../../common/components/table/table-footer";
import Spacing from "../../../../../common/components/spacing";
import FlexWrapContainer from "../../../../../common/components/containers/flex-wrap-container";
import SearchPreviewItem from "../search-preview-item";
import makePreviewRows from "~/search-config/pages/search-preview/utils/make-preview-rows";
import ExplanationDialog from "~/search-config/pages/search-preview/components/explanation-dialog";

const useStyles = makeStyles()(({ spacing, colors, palette }) => ({
  inputContainer: {
    display: "inline-grid",
    gridTemplateColumns: "1fr 1fr",
    gap: spacing(80),
    width: "100%",
  },
  configSummaryContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridTemplateRows: "1fr 1fr",
    width: "100%",
    background: palette.gray[100],
    color: colors.darkgray,
    padding: spacing(20),
    borderRadius: spacing(10),
  },
  configSummaryHeadline: {
    fontWeight: "bolder",
  },
  resultHint: {
    marginTop: spacing(-15),
    color: colors.gray,
  },
}));

interface SearchPreviewBodyProps {
  query: string;
  onQueryChange?(query: string): void;

  uuid: string;
  onUuidChange?(uuid: string): void;

  searchPreviewItems: SearchConfigPreview[] | CampaignPreview[];

  configName: string;
  configId: string;
  versionId: string;
  total: number;
  loading: boolean;
  isCampaign?: boolean;

  offset: number;
  limit: number;
  onLimitChange(limit: number): void;
  onOffsetChange(offset: number): void;
}

export default function SearchPreviewBody({
  configName,
  configId,
  versionId,
  query,
  onUuidChange,
  uuid,
  total,
  isCampaign,
  onQueryChange,
  searchPreviewItems,
  limit,
  offset,
  loading,
  onLimitChange,
  onOffsetChange,
}: SearchPreviewBodyProps) {
  const { classes } = useStyles();

  const [isExplanationDialogOpen, setExplanationDialogOpen] = useState(false);
  const [explanationDialogContent, setExplanationDialogContent] = useState("");

  const handleShowExplanationDialog = useCallback((explanation: string) => {
    setExplanationDialogContent(explanation);
    setExplanationDialogOpen(true);
  }, []);

  return (
    <>
      <ExplanationDialog
        open={isExplanationDialogOpen}
        setOpen={setExplanationDialogOpen}
        content={explanationDialogContent}
      />
      <div className={classes.inputContainer}>
        {onQueryChange && (
          <InputField value={query} onChange={onQueryChange} block placeholder="Enter search query" label="Query" />
        )}
        {onUuidChange && (
          <InputField value={uuid} onChange={onUuidChange} block placeholder="Enter UUID" label="UUID" />
        )}
      </div>
      <p className={classes.resultHint}>
        {total ? (
          <>
            Found <b>{total}</b> result items.
          </>
        ) : (
          <>No results found</>
        )}
      </p>
      <Spacing top={10} />
      <div className={classes.configSummaryContainer}>
        <span className={classes.configSummaryHeadline}>Name</span>
        <span className={classes.configSummaryHeadline}>Config ID</span>
        <span className={classes.configSummaryHeadline}>Version ID</span>
        <span>{configName}</span>
        <span>{configId}</span>
        <span>{versionId}</span>
      </div>
      {loading && <LinearProgress indeterminate />}

      <Spacing top={20} />

      <FlexWrapContainer>
        {searchPreviewItems?.map(item => (
          <SearchPreviewItem
            explanation={(item as SearchConfigPreview).scoreExplanation}
            onShowExplanation={handleShowExplanationDialog}
            key={item.id}
            id={item.id}
            values={makePreviewRows(item, isCampaign)}
            imagePath={item.imagePath}
          />
        ))}
      </FlexWrapContainer>

      <Spacing top={20} />

      <TableFooter
        limit={limit.toString()}
        offset={offset}
        total={total}
        onLimitChange={onLimitChange}
        onOffsetChange={onOffsetChange}
      />
    </>
  );
}
