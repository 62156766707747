import { useReactiveVar } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";

import reactiveVariables from "../../../common/graphql/global-reactive-variables";
import Breadcrumbs from "../../../common/components/breadcrumbs";
import { useGetSearchConfigurationQuery } from "../../../generated";
import SearchConfigForm, { FormData } from "../../components/search-config-form";
import { isSuccessfulResponse } from "../search-config-versions";
import useSubmit from "./use-submit";
import PageContainer from "../../../common/components/containers/page-container";

export default function CreateSearchConfigurationVersionPage() {
  const navigate = useNavigate();
  const { configId, region } = useParams();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const submit = useSubmit();

  const { data } = useGetSearchConfigurationQuery({
    variables: {
      params: {
        id: configId,
        projectId: selectedEnvironmentProjectId,
      },
    },
  });

  const handleSubmit = async (formData: FormData) => {
    await submit(configId, formData);
  };

  return (
    <PageContainer>
      <Breadcrumbs />
      {isSuccessfulResponse(data?.searchConfiguration) && (
        <SearchConfigForm
          onCancel={() => navigate(`/${region}/search/search-config/${configId}/versions`)}
          formTitle="Create New Version"
          onSubmit={handleSubmit}
          fixedSearchConfigName={data?.searchConfiguration.name}
        />
      )}
    </PageContainer>
  );
}
