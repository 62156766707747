import { useNavigate } from "react-router-dom";
import GeneralError from "../general-error";

export default function ErrorUserNotRegistered() {
  const navigate = useNavigate();

  return (
    <GeneralError
      heading="User not registered!"
      subheading="Login error"
      text={
        <>
          Sorry, you are not registered in Datajet by GFG.
          <br />
          Please contact your regional manager for the access.
        </>
      }
      buttonLabel="Take me back"
      onButtonClick={() => navigate("/login", { replace: true })}
      img="/user-not-registered.svg"
    />
  );
}
