import { Button } from "@gfg/ui-v2/components/button";
import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ colors, palette }) => ({
  button: {
    width: "48%",
  },
  cancelBtn: {
    marginRight: "4%",
    color: colors.darkgray,
    border: `1px solid ${palette.tertiary[200]}`,
  },
}));

interface ModalActionsProps {
  submitBtnName: string;
  color: string;
  closeModal: () => void;
  deleteAction: () => void;
}

export default function DeleteModalActions({ submitBtnName, color, closeModal, deleteAction }: ModalActionsProps) {
  const { classes, cx } = useStyles();
  const handleDeleteAction = () => {
    deleteAction();
    closeModal();
  };

  return (
    <>
      <Button onClick={closeModal} className={cx(classes.button, classes.cancelBtn)} color={color} variant="outline">
        Cancel
      </Button>
      <Button onClick={handleDeleteAction} color={color} className={classes.button} variant="filled">
        {submitBtnName}
      </Button>
    </>
  );
}
