import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { makeStyles } from "@gfg/ui-v2/theming";
import useGoBack from "../../common/hooks/use-go-back";
import LoginWithGoogleButton from "./login-with-google-button";
import { LoginFailureReason } from "../auth-provider";

const useStyles = makeStyles()(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    flexGrow: 1,
    width: "100%",
    maxWidth: 380,
  },
  text: {
    marginTop: theme.spacing(10),
    color: theme.colors.gray,
  },
}));

export default function LoginContent() {
  const { classes } = useStyles();
  const [error, setError] = useState<LoginFailureReason | null>(null);
  const doGoBack = useGoBack();
  const navigate = useNavigate();

  useEffect(() => {
    if (error === "unregistered_user") {
      navigate("/user-not-found");
    }
  }, [error]);

  return (
    <div className={classes.container}>
      <h3>Log in</h3>
      <p className={classes.text}>Welcome! Please enter your details.</p>
      <LoginWithGoogleButton onSuccess={doGoBack} onFailure={setError} />
      {error && <span>{error}</span>}
    </div>
  );
}
