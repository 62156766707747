import { Filter, FilterElement, IntFromToRange } from "@coral/typings";
import { ComponentProps } from "../../components/form-filters/dynamic-filter-component";
import { ConditionalFilterFormValue } from "../../components/form-filters/conditional-filters";
import { FeedFilterType, RuleOption } from "../../components/form-filters/conditional-filters/constants";

function parseFeedFilterValue(filterType: keyof FilterElement, filterElement: FilterElement) {
  const value = filterElement[filterType] as any;

  if (value === undefined || value === null) {
    return value;
  }

  switch (filterType) {
    case FeedFilterType.Category: {
      const categories = value as string[][];
      return categories.flat();
    }
    case FeedFilterType.DiscountRange: {
      const range = value as IntFromToRange[];
      const { from, to } = range[0]!;

      const fromPercentage = (from * 100).toString();
      const toPercentage = (to * 100).toString();

      return { from: fromPercentage, to: toPercentage };
    }
    case FeedFilterType.PriceRange: {
      const range = value as IntFromToRange[];
      return { from: range[0]?.from?.toString() as string, to: range[0]?.to?.toString() as string };
    }
    case FeedFilterType.PromotionPriceRange: {
      const range = value as IntFromToRange[];
      return { from: range[0]?.from?.toString() as string, to: range[0]?.to?.toString() as string };
    }
    default: {
      return value as ComponentProps["value"];
    }
  }
}

function deserializeLegacyFeedFilters(serializedFilters: Filter) {
  const feedFilters: ConditionalFilterFormValue[] = [];

  const rulesToIterate: Array<keyof typeof serializedFilters> = [
    RuleOption.Exclude as keyof Filter,
    RuleOption.Include as keyof Filter,
  ];

  for (const rule of rulesToIterate) {
    const filterElement = serializedFilters[rule] as FilterElement;

    if (filterElement) {
      for (const filterType of Object.values(FeedFilterType)) {
        const value = parseFeedFilterValue(filterType as keyof FilterElement, filterElement);

        if (value !== undefined && value !== null) {
          feedFilters.push({ rule, type: filterType, value });
        }
      }
    }
  }

  return feedFilters;
}

function deserializeDynamicFeedFilters(serializedFilters: Filter) {
  const feedFilters: ConditionalFilterFormValue[] = [];

  for (const dynamicFilter of serializedFilters.dynamic || []) {
    if (dynamicFilter.textFilter) {
      feedFilters.push({
        rule: dynamicFilter.rule_name,
        type: dynamicFilter.textFilter.field,
        value: dynamicFilter.textFilter.value,
      });
    } else if (dynamicFilter.textArrFilter) {
      feedFilters.push({
        rule: dynamicFilter.rule_name,
        type: dynamicFilter.textArrFilter.field,
        value: dynamicFilter.textArrFilter.value,
      });
    } else if (dynamicFilter.numericalFilter) {
      feedFilters.push({
        rule: dynamicFilter.rule_name,
        type: dynamicFilter.numericalFilter.field,
        value: dynamicFilter.numericalFilter.value,
      });
    } else if (dynamicFilter.rangeFilter) {
      const { from, to } = dynamicFilter.rangeFilter.value;
      feedFilters.push({
        rule: dynamicFilter.rule_name,
        type: dynamicFilter.rangeFilter.field,
        value: { from: from?.toString(), to: to?.toString() },
      });
    }
  }

  return feedFilters;
}
function deserializeFeedFilters(serializedFilters: Filter) {
  const legacyFeedFilters: ConditionalFilterFormValue[] = deserializeLegacyFeedFilters(serializedFilters);
  const dynamicFeedFilters: ConditionalFilterFormValue[] = deserializeDynamicFeedFilters(serializedFilters);

  return [...legacyFeedFilters, ...dynamicFeedFilters];
}

export default deserializeFeedFilters;
