import Button from "@gfg/ui-v2/components/button";
import { CheckCircleIcon } from "@gfg/ui-v2/icons";
import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ spacing }) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: spacing(32),
  },
  buttonsContainer: {
    "& > button": {
      marginLeft: spacing(12),
    },
  },
}));

interface SearchConfigFormHeaderProps {
  disabledSubmit: boolean;
  disabledActivation: boolean;
  disabledPreview?: boolean;
  title: string;
  onCancel(): void;
  onActivationToggle(): void;
  activationMode?: "ACTIVE" | "DRAFT";
  onPreview?(): void;
}

export default function SearchConfigFormHeader({
  disabledSubmit,
  disabledActivation,
  disabledPreview,
  activationMode,
  title,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onCancel,
  onActivationToggle,
  onPreview,
}: SearchConfigFormHeaderProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <h3>{title}</h3>
      <div className={classes.buttonsContainer}>
        {onPreview && (
          <Button disabled={disabledPreview} variant="outline" onClick={onPreview}>
            Preview
          </Button>
        )}
        {activationMode && (
          <Button
            onClick={e => {
              e.preventDefault();
              onActivationToggle();
            }}
            leftIcon={CheckCircleIcon}
            disabled={disabledActivation}
          >
            {activationMode === "ACTIVE" ? "Deactivate" : "Activate"}
          </Button>
        )}
        {/* <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button> */}
        <Button disabled={disabledSubmit}>Save as draft</Button>
      </div>
    </div>
  );
}
