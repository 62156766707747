import { AvailableProductAttribute } from "@coral/typings";
import Button from "@gfg/ui-v2/components/button";
import { PlusIcon } from "@gfg/ui-v2/icons";
import { useFieldArray, useFormContext } from "react-hook-form";
import AttributeFilterField from "./attribute-filter-field";

export interface AttributeFilterFormValue {
  id?: string;
  type: string;
  weight: number;
}

interface AttributeFilterGroupProps {
  disabled: boolean;
  fieldName: string;
  addAttributeButtonLabel: string;
  attributeOptions: AvailableProductAttribute[];
}

export default function AttributeFilterGroupField({
  disabled,
  fieldName,
  addAttributeButtonLabel,
  attributeOptions,
}: AttributeFilterGroupProps) {
  const { control } = useFormContext();
  const { append, remove, fields } = useFieldArray({ name: fieldName, control });

  const addFilter = () => {
    const fieldData: AttributeFilterFormValue = {
      type: "",
      weight: 1,
    };

    append(fieldData);
  };

  return (
    <div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {fields.map((field, index) => (
          <AttributeFilterField
            key={field.id}
            fieldName={fieldName}
            index={index}
            attributeOptions={attributeOptions}
            onRemoveFilter={remove}
            disabled={disabled}
          />
        ))}
      </div>
      <Button disabled={disabled} type="button" onClick={addFilter} variant="outline" leftIcon={<PlusIcon />}>
        {addAttributeButtonLabel}
      </Button>
    </div>
  );
}
