import { useNavigate, useParams } from "react-router-dom";
import { SearchOptimization, SearchOptimizationState } from "@coral/typings";
import { useCallback, useState } from "react";
import OptimizationsTable from "search-optimizations/components/optimizations-table";
import { makeStyles } from "@gfg/ui-v2/theming";
import { useTableState } from "@gfg/ui-v2/components/data-table";
import TableHeader from "../../../common/components/table/table-header";
import useGetSearchOptimizations from "~/search-optimizations/hooks/use-get-search-optimizations";
import useDeleteSearchOptimization from "~/search-optimizations/hooks/use-delete-search-optimization";
import useSubmit from "./use-submit";
import useColumns, { Column } from "~/search-optimizations/hooks/use-columns";
import ChangeStatusModal from "~/search-optimizations/components/change-status-modal";
import DeleteModal from "~/common/components/delete-modal";

const useStyles = makeStyles()(theme => ({
  tableContainer: {
    "boxShadow": theme.shadow("md"),
    "margin": `${theme.spacing(16)}`,
    "& .tableBody tr": {
      borderTopColor: "transparent",
    },
  },
  headerCell: {
    color: theme.colors.text,
    background: theme.colors.background,
  },
}));

export default function SearchOptimizationsPage() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { region } = useParams();
  const [isStatusChangeModalOpen, setIsStatusChangeModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [stateFilter, setStateFilter] = useState<SearchOptimizationState | undefined>();

  const [currentOptimization, setCurrentOptimization] = useState<SearchOptimization | null>(null);

  const deleteSearchOptimization = useDeleteSearchOptimization();
  const submitStateChange = useSubmit();
  const columns: Column[] = useColumns({
    onFilterState: setStateFilter,
    state: stateFilter,
  });
  const [state, onSort] = useTableState(columns, u => u?.id);

  const { searchOptimizations, loading, limit, setLimit, offset, setOffset, searchInput, setSearchInput } =
    useGetSearchOptimizations({
      stateFilter,
      sort: {
        column: (state.sorting?.column as Column)?.sortField,
        direction: state.sorting?.direction,
      },
    });

  const handleCreateOptimization = useCallback(() => {
    navigate(`/${region}/search/optimizations/create`);
  }, [navigate]);

  const handleEditOptimization = useCallback(
    (optimization: SearchOptimization) => {
      navigate(`/${region}/search/optimizations/edit/${optimization.id}`);
    },
    [navigate],
  );

  const handleDelete = useCallback(async () => {
    await deleteSearchOptimization(currentOptimization?.id, currentOptimization?.query);
    setCurrentOptimization(null);
  }, [deleteSearchOptimization, currentOptimization]);

  const onChangeStatusModalOpen = useCallback((optimization: SearchOptimization) => {
    setIsStatusChangeModalOpen(true);
    setCurrentOptimization(optimization);
  }, []);

  const onDeleteModalOpen = useCallback((optimization: SearchOptimization) => {
    setIsDeleteModalOpen(true);
    setCurrentOptimization(optimization);
  }, []);

  const handleCloseChangeStatusModal = useCallback(() => {
    setCurrentOptimization(null);
    setIsStatusChangeModalOpen(false);
  }, []);

  const handleStateChange = useCallback(
    async (optimization: SearchOptimization, newState: SearchOptimizationState) => {
      await submitStateChange(optimization.id, newState);
      handleCloseChangeStatusModal();
    },
    [submitStateChange],
  );

  return (
    <div>
      <div className={classes.tableContainer}>
        <TableHeader
          total={searchOptimizations?.total}
          onSearchInputChange={setSearchInput}
          searchInput={searchInput}
          subtitle="Search Optimization"
          searchInputPlaceholder="Search"
          actionButtonLabel="Create optimization"
          onActionButtonClick={handleCreateOptimization}
        />
        <OptimizationsTable
          state={state}
          columns={columns}
          onChangeStatus={onChangeStatusModalOpen}
          onSort={onSort}
          loading={loading}
          limit={limit}
          offset={offset}
          onEditOptimization={handleEditOptimization}
          onRemoveOptimization={onDeleteModalOpen}
          total={searchOptimizations?.total}
          onLimitChange={setLimit}
          onOffsetChange={setOffset}
          searchOptimizations={searchOptimizations?.data}
        />
        <ChangeStatusModal
          optimization={currentOptimization}
          text={`You are changing status for optimization "${currentOptimization?.query}"`}
          open={isStatusChangeModalOpen}
          onClose={handleCloseChangeStatusModal}
          onSave={handleStateChange}
        />
        <DeleteModal
          open={isDeleteModalOpen}
          title="Delete Optimization"
          text="Are you sure you want to delete this optimization?"
          onToggle={setIsDeleteModalOpen}
          onDelete={handleDelete}
        />
      </div>
    </div>
  );
}
