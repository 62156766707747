import { ChartGroup } from "@coral/typings";
import FlexWrapContainer from "../../containers/flex-wrap-container";
import ChartBox from "../../charts";

interface ChartGroupProps {
  group: ChartGroup;
}

export default function DynamicChartGroup({ group }: ChartGroupProps) {
  return (
    <FlexWrapContainer gap={0}>
      {group.charts.map(chart => (
        <ChartBox key={chart.id} chart={chart} />
      ))}
    </FlexWrapContainer>
  );
}
