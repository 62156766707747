import { useReactiveVar } from "@apollo/client";
import { SearchConfigAttributeType } from "@coral/typings";
import LinearProgress from "@gfg/ui-v2/components/linear-progress";
import { useCallback, useMemo } from "react";
import AttributeFilterWithRules from "../../../../../common/components/form-filters/attribute-filters-with-rules";
import { FilterAutocompleteMultiselectProps } from "../../../../../common/components/form-filters/dynamic-filter-component/filter-components/autocomplete-multiselect-filter";
import {
  useGetSearchConfigAttributesQuery,
  useGetSearchConfigAttributeValuesLazyQuery,
} from "../../../../../generated";
import reactiveVariables from "../../../../../common/graphql/global-reactive-variables";
import getAttributeName from "~/search-config/utils/get-attribute-name";

interface SearchConfigAttributeWithRulesFilterProps {
  fieldName: string;
  disabled: boolean;
  addAttributeButtonLabel: string;
  type: SearchConfigAttributeType;
  excludeAttributes?: string[];
}

export default function SearchConfigAttributeWithRulesFilter({
  disabled,
  fieldName,
  addAttributeButtonLabel,
  type,
  excludeAttributes = [],
}: SearchConfigAttributeWithRulesFilterProps) {
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const { data, loading } = useGetSearchConfigAttributesQuery({
    variables: { params: { attributeType: type, projectId: selectedEnvironmentProjectId } },
  });

  const [getSearchConfigAttributeValues] = useGetSearchConfigAttributeValuesLazyQuery();

  const getFacetValues: FilterAutocompleteMultiselectProps["getValues"] = useCallback(
    async ({ attributeId, limit, query }) => {
      const valuesResponse = await getSearchConfigAttributeValues({
        variables: {
          params: {
            attributeName: getAttributeName(attributeId),
            projectId: selectedEnvironmentProjectId,
            limit,
            query,
          },
        },
      });

      return { data: valuesResponse?.data?.searchConfigAttributeValues };
    },
    [getSearchConfigAttributeValues, selectedEnvironmentProjectId],
  );

  const attributeOptions = useMemo(
    () => data?.searchConfigAttributes.filter(a => !excludeAttributes.includes(a.value)),
    [data?.searchConfigAttributes],
  );

  return (
    <>
      {loading && <LinearProgress indeterminate />}
      {!loading && (
        <AttributeFilterWithRules
          fieldName={fieldName}
          disabled={disabled}
          addAttributeButtonLabel={addAttributeButtonLabel}
          attributeOptions={attributeOptions}
          getFacetValues={getFacetValues}
        />
      )}
    </>
  );
}
