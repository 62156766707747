import { Tag } from "@gfg/ui-v2/components/tag";
import { DotIcon } from "@gfg/ui-v2/icons";

interface StatusTagProps {
  label: string;
}

export default function GrayStatusTag({ label }: StatusTagProps) {
  return <Tag icon={<DotIcon width={14} height={14} color="gray" />} variant="faded" color="darkgray" label={label} />;
}
