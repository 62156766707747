import { ConditionalWeightFilter } from "@coral/typings";
import { isNumber, isString } from "lodash";
import { AttributeFilterWithRuleFormValue } from "../../components/form-filters/attribute-filters-with-rules";

function getDeserializedValue(attribute: ConditionalWeightFilter) {
  if (Array.isArray(attribute.strArrValue)) {
    return attribute.strArrValue;
  }

  if (isNumber(attribute.numValue)) {
    return attribute.numValue;
  }

  if (isString(attribute.strValue)) {
    return attribute.strValue;
  }

  return ""; // default empty value
}

function deserializeAttributeFilterWithRule(attribute: ConditionalWeightFilter): AttributeFilterWithRuleFormValue {
  return {
    id: attribute.id,
    rule: attribute.rule || "",
    weight: attribute.weight,
    type: attribute.fieldName,
    value: getDeserializedValue(attribute),
  };
}

export default deserializeAttributeFilterWithRule;
