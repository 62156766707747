let accessToken = "";
let iat = 0;

export function getToken() {
  if (!iat || iat < Date.now()) {
    return null;
  }

  return accessToken;
}

function decodeToken(token = accessToken) {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch {
    return null;
  }
}

export function setToken(token: string) {
  const decoded = decodeToken(token);
  if (decoded) {
    accessToken = token;
    iat = decoded.iat;
  } else {
    accessToken = "";
  }
}

export async function renewToken(authToken?: string) {
  const response = await fetch("/token", {
    method: "POST",
    credentials: "same-origin",
    headers: {
      "Content-type": "application/json",
    },
    body: authToken ? JSON.stringify({ authToken }) : undefined,
  });

  if (response.status === 200) {
    const { token } = await response.json();
    setToken(token);

    return getToken();
  }
  return null;
}
