import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApolloError, useReactiveVar } from "@apollo/client";

import { CustomFeedState } from "@coral/typings";
import extractGraphqlError from "common/utils/apollo/extract-graphql-error";
import { useCreateNewsletterMutation } from "../../../generated";
import { FormData } from "../newsletter-form";
import serializeFeedFilters from "../../../common/utils/feed-filters/serialize-feed-filters";
import useAppNotifications from "../../../common/hooks/use-app-notifications";
import reactiveVariables from "../../../common/graphql/global-reactive-variables";

function useSubmit() {
  const { addSuccessNotification, addErrorNotification, addInProgressNotification, removeNotification } =
    useAppNotifications();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);
  const navigate = useNavigate();
  const { region } = useParams();

  const [createNewsletter] = useCreateNewsletterMutation({
    refetchQueries: ["GetNewsletters"],
  });

  return useCallback(
    async (formData: FormData) => {
      let notificationId;

      try {
        notificationId = addInProgressNotification("Creating newsletter");

        const response = await createNewsletter({
          variables: {
            newsletter: {
              orderStrategy: formData.orderStrategy,
              feedStrategy: formData.feedStrategy,
              htmlTileTemplate: formData.htmlTileTemplate,
              landingPageUrl: formData.landingPageUrl,
              newsletterName: formData.newsletterName,
              feedFilters: serializeFeedFilters(formData.feedFilters ?? []),
              projectId: selectedEnvironmentProjectId,
              state: formData.feedStatus as CustomFeedState,
            },
          },
        });

        addSuccessNotification("Newsletter was created successfully");
        if (response.data?.createNewsletter?.id) {
          navigate(`/${region}/recommendations/newsletter/edit/${response.data?.createNewsletter?.id}`);
        }
      } catch (error) {
        const message = extractGraphqlError(error as ApolloError);
        addErrorNotification("Error while creating newsletter", message);
      } finally {
        if (notificationId) {
          removeNotification(notificationId);
        }
      }
    },
    [navigate, createNewsletter],
  );
}

export default useSubmit;
