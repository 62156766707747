import { IconButton } from "@gfg/ui-v2/components/button";
import { InputField } from "@gfg/ui-v2/components/input";
import CreateButton from "common/components/create-button";
import { List, ListItem } from "@gfg/ui-v2/components/list";
import Tag from "@gfg/ui-v2/components/tag";
import { Trash2Icon } from "@gfg/ui-v2/icons";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import _ from "lodash";
import { makeStyles } from "@gfg/ui-v2/theming";

interface HardBoostingInputProps {
  skus: string[];
  onChange(skus: string[]): void;
}

const useStyles = makeStyles()(({ spacing, shadow, palette }) => ({
  tag: {
    width: "fit-content",
    marginLeft: spacing(4),
    marginTop: spacing(10),
    border: "none",
  },
  skuItem: {
    boxShadow: shadow("md"),
    margin: spacing(20, 1),
    borderRadius: "4px",
    padding: spacing(16, 16),
  },
  inputContainer: {
    "display": "flex",
    "flexDirection": "row",
    "gap": spacing(20),
    "& input": {
      width: spacing(512),
    },
    "& textarea": {
      width: spacing(512),
    },
  },
  icon: {
    background: palette.secondary[200],
  },
}));

export default function HardBoostingInput({ skus, onChange }: HardBoostingInputProps) {
  const { classes } = useStyles();
  const [skuInput, setSkuInput] = useState("");
  const { region } = useParams();

  const handleAddSkus = (e: React.MouseEvent) => {
    e.preventDefault();

    const skusToAdd = skuInput
      .split(",")
      .map(sku => sku.trim())
      .filter(s => !!s);

    const skusCombined = [...skus, ...skusToAdd];

    const uniqueSkus = _.uniq(skusCombined);
    onChange(uniqueSkus);
    setSkuInput("");
  };

  const handleRemoveSku = (sku: string) => {
    const skusFiltered = skus.filter(s => s !== sku);
    onChange(skusFiltered);
  };

  const handleRemoveAll = () => {
    onChange([]);
  };

  return (
    <div>
      <div className={classes.inputContainer}>
        <InputField
          rows={2}
          multiline
          placeholder="Type SKUs"
          hint="Enter SKUs comma-separated, e.g. BR319APF44OJF, BR319APF44OXLL"
          value={skuInput}
          onChange={setSkuInput}
        />
        <CreateButton text="Add SKUs" onClick={handleAddSkus} />
      </div>
      {skus.length > 0 && (
        <List>
          <ListItem
            rightIcon={
              <IconButton className={classes.icon} onClick={handleRemoveAll} icon={Trash2Icon} round variant="light" />
            }
            className={classes.skuItem}
          >
            {skus.map(sku => (
              <Tag
                key={sku}
                className={classes.tag}
                closable
                label={
                  <Link
                    target="_blank"
                    style={{ textDecoration: "underline" }}
                    to={{ pathname: `/${region}/product-catalog`, search: `?q=${sku}` }}
                  >
                    {sku}
                  </Link>
                }
                variant="faded"
                size="small"
                onClose={() => handleRemoveSku(sku)}
              />
            ))}
          </ListItem>
        </List>
      )}
    </div>
  );
}
