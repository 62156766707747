import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ spacing, breakpoints, colors }) => ({
  container: {
    margin: spacing(16),
  },
  generalSettings: {
    marginTop: spacing(20),
  },
  inputField: {
    "display": "flex",

    "& div small": {
      color: colors.error,
      fontSize: 12,
    },
  },
  darkHint: {
    "& div small": {
      color: colors.gray,
      fontSize: 12,
    },
  },
  textField: {
    "& input": {
      width: spacing(512),
    },

    [breakpoints.down("xs")]: {
      "flexWrap": "wrap",

      "& input": {
        width: "100%",
      },
    },
  },
  selectField: {
    "& input": {
      width: spacing(480),
    },

    [breakpoints.down("xs")]: {
      "flexWrap": "wrap",

      "& input": {
        width: spacing(220),
      },
    },
  },
  orderStrategy: {
    "& label": {
      marginBottom: spacing(6),
    },
  },
  verticallyMiddle: {
    "alignItems": "center",
    "margin": spacing(20, 0),
    "& div": {
      margin: 0,
    },
  },
  optionContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  optionName: {
    color: colors.text,
  },
  icon: {
    paddingLeft: "auto",
  },
}));

export default useStyles;
