import { InputField } from "@gfg/ui-v2/components/input";

export interface FilterNumberProps {
  value: string | number;
  onChange(value: string | number): void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;

  disabled?: boolean;
  required?: boolean;
  label?: React.ReactNode;
  invalidHint?: string;
  valid?: boolean;
}

function parseIfNotNaN(value: string) {
  const parsedValue = Number.parseFloat(value);
  if (Number.isNaN(parsedValue)) {
    return value;
  }

  return parsedValue;
}

export default function FilterNumber({
  onChange,
  onBlur,
  value,
  label,
  disabled = false,
  required = true,
  invalidHint,
  valid,
}: FilterNumberProps) {
  return (
    <InputField
      disabled={disabled}
      onChange={(value: string) => onChange(parseIfNotNaN(value))}
      onBlur={onBlur}
      value={value}
      placeholder="Choose the values"
      variant="outline"
      type="number"
      required={required}
      label={label}
      invalidHint={invalidHint}
      valid={valid}
      step={0.01}
    />
  );
}
