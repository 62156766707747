import { AutocompleteProps, AutocompleteField } from "@gfg/ui-v2/components/autocomplete";
import { IconButton } from "@gfg/ui-v2/components/button";
import { CrossIcon } from "@gfg/ui-v2/icons";
import Tag from "@gfg/ui-v2/components/tag";
import { useState, KeyboardEvent, useMemo } from "react";
// eslint-disable-next-line import/no-extraneous-dependencies

import useStyles from "./use-styles";
import { addOrRemove } from "./utils";

interface AutocompleteMultiselectProps {
  values: string | string[];
  onSelectedValuesChange: (values: string[]) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  autocompleteFn?: AutocompleteProps["autocompleteFn"];

  placeholder?: string;
  disabled?: boolean;
  label?: string;
  className?: string;
  invalidHint?: string;
  valid?: boolean;
}

const defaultAutocompleteFn: AutocompleteProps["autocompleteFn"] = async (term: string) => [
  { items: [{ id: term, value: term }] },
];

export default function AutocompleteMultiselect({
  autocompleteFn = defaultAutocompleteFn,
  onSelectedValuesChange,
  onBlur,
  values,
  placeholder,
  disabled = false,
  label,
  className,
  invalidHint,
  valid,
}: AutocompleteMultiselectProps) {
  const { classes, cx } = useStyles();
  const [value, setValue] = useState("");

  const selectedValues = useMemo(() => {
    if (values === "") {
      return [];
    }

    return typeof values === "string" ? [values] : values;
  }, [values]);

  const handleToggleSuggestion = (value: string) => {
    const normalizedValue = value.trim();

    if (normalizedValue.length > 0) {
      onSelectedValuesChange(addOrRemove(selectedValues, normalizedValue));
      setValue("");
    }
  };

  return (
    <AutocompleteField
      append={
        selectedValues?.length > 0 ? (
          <div className={classes.clear}>
            <IconButton
              color="gray"
              icon={CrossIcon}
              round
              size="small"
              variant="link"
              onClick={() => onSelectedValuesChange([])}
            />
          </div>
        ) : undefined
      }
      label={label}
      prepend={
        selectedValues?.length > 0 ? (
          <div className={classes.tags}>
            {selectedValues?.map(selectedValue => (
              <Tag
                key={selectedValue}
                closable
                label={selectedValue}
                size="small"
                variant="faded"
                onClose={e => {
                  e.preventDefault();
                  handleToggleSuggestion(selectedValue);
                }}
              />
            ))}
          </div>
        ) : undefined
      }
      onBlur={onBlur}
      autocompleteFn={autocompleteFn}
      block
      className={cx(classes.autocomplete, className)}
      closeOnSelection={false}
      itemProps={item => ({ active: selectedValues?.includes(item.value as string) })}
      minLength={1}
      value={value}
      onChange={setValue}
      onSelectSuggestion={({ value }) => handleToggleSuggestion(value as string)}
      placeholder={placeholder}
      onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
          handleToggleSuggestion(value);
        }
      }}
      disabled={disabled}
      invalidHint={invalidHint}
      valid={valid}
      autocompleteTimeout={100}
    />
  );
}
