import { useMemo } from "react";
import Dropdown, { DropdownItem } from "@gfg/ui-v2/components/dropdown";
import Button from "@gfg/ui-v2/components/button";
import { ChevronDownIcon } from "@gfg/ui-v2/icons";
import CircularProgress from "@gfg/ui-v2/components/circular-progress";
import { useReactiveVar } from "@apollo/client";
import { makeStyles } from "@gfg/ui-v2/theming";
import reactiveVariables from "../graphql/global-reactive-variables";
import { useGetEnvironmentsQuery } from "../../generated";
import useAllowedEnvironments from "../hooks/use-allowed-environments";
import useSynchronizeSelectedEnv from "../hooks/use-synchronize-selected-env";

const useStyles = makeStyles()(({ spacing }) => ({
  container: {
    position: "relative",
    alignSelf: "center",
  },
  triggerContainer: {
    "& > span": {
      display: "flex",
      alignItems: "center",
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    margin: `0 ${spacing(5)}`,
  },
}));

export default function EnvironmentsDropdown() {
  useSynchronizeSelectedEnv();

  const { classes } = useStyles();
  const { data, loading } = useGetEnvironmentsQuery();
  const environments = useAllowedEnvironments();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const currentEnvironmentName = useMemo(() => {
    if (data) {
      return data.configuration.environments.find(environment => environment.id === selectedEnvironmentProjectId)?.name;
    }
  }, [selectedEnvironmentProjectId, data]);

  if (loading || !data || !environments) {
    return <CircularProgress />;
  }

  const handleEnvironmentSelect = (projectId: string) => {
    reactiveVariables.selectedEnvironmentIdVar(projectId);
    window.location.href = "/";
  };

  return (
    <Dropdown
      onSelect={handleEnvironmentSelect}
      className={classes.container}
      strategy="align-center"
      triggerElement={
        <Button variant="link" className={classes.triggerContainer}>
          {currentEnvironmentName}
          <div className={classes.icon}>
            <ChevronDownIcon />
          </div>
        </Button>
      }
    >
      {environments.map(environment => (
        <DropdownItem value={environment.id} key={environment.id}>
          {environment.name}
        </DropdownItem>
      ))}
    </Dropdown>
  );
}
