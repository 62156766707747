import { Children, cloneElement, isValidElement, ReactElement, ReactNode, useState } from "react";
import { type Swiper as SwiperType } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import CustomNavigationButton from "./swiper-navigation";

const breakpoints = {
  640: {
    slidesPerView: 1.3,
    spaceBetween: 20,
  },
  940: {
    slidesPerView: 1.7,
    spaceBetween: 20,
  },
  1240: {
    slidesPerView: 2,
    spaceBetween: 40,
  },
  1500: {
    slidesPerView: 2.3,
    spaceBetween: 40,
  },
  1700: {
    slidesPerView: 2.5,
    spaceBetween: 40,
  },
  1920: {
    slidesPerView: 3.2,
    spaceBetween: 50,
  },
};

interface RequiredType {
  children: ReactNode | ReactElement;
}

export default function CustomSwiper({ children }: RequiredType) {
  const [swipe, setSwipe] = useState<SwiperType>();

  const arrayChildren = Children.toArray(children);

  return (
    <Swiper
      pagination={{ clickable: true }}
      breakpoints={breakpoints}
      onSwiper={swiper => setSwipe(swiper)}
      slideToClickedSlide
      className="mySwiper"
    >
      {Children.map(arrayChildren, child => {
        if (isValidElement(child)) {
          return <SwiperSlide>{cloneElement(child)}</SwiperSlide>;
        }
        return child;
      })}
      {arrayChildren.length > 1 && <CustomNavigationButton swiper={swipe} />}
    </Swiper>
  );
}
