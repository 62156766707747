import { useViewerQuery } from "../../generated";

export default function useViewer() {
  const { loading, data, refetch } = useViewerQuery({ fetchPolicy: "network-only" });

  return {
    loading,
    viewer: data?.viewer || null,
    refetch,
  };
}
