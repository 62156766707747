import { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApolloError } from "@apollo/client";

import extractGraphqlError from "common/utils/apollo/extract-graphql-error";
import { FormData } from "../../components/optimizations-form";
import useAppNotifications from "../../../common/hooks/use-app-notifications";
import useUpdateSearchOptimization from "~/search-optimizations/hooks/use-update-search-optimization";

function useSubmit() {
  const { addSuccessNotification, addErrorNotification, removeNotification, addInProgressNotification } =
    useAppNotifications();
  const navigate = useNavigate();
  const { region } = useParams();

  const updateSearchOptimization = useUpdateSearchOptimization();

  return useCallback(
    async (id: string, formData: FormData) => {
      let notificationId;

      try {
        notificationId = addInProgressNotification("Updating Search Optimization");

        const { data } = await updateSearchOptimization(id, formData);

        if (data?.updateSearchOptimization) {
          addSuccessNotification("Search Optimization was updated successfully");
          navigate(`/${region}/search/optimizations`);
        }
      } catch (error) {
        const message = extractGraphqlError(error as ApolloError);
        addErrorNotification("Error while updating Search Optimization", message);
      } finally {
        if (notificationId) {
          removeNotification(notificationId);
        }
      }
    },
    [navigate, updateSearchOptimization],
  );
}

export default useSubmit;
