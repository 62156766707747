import { WeightFilter } from "@coral/typings";
import { AttributeFilterFormValue } from "../../components/form-filters/attribute-filters";

function deserializeAttributeFilter(attribute: WeightFilter): AttributeFilterFormValue {
  return {
    id: attribute.id,
    weight: attribute.weight,
    type: attribute.fieldName,
  };
}

export default deserializeAttributeFilter;
