import { useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { SynonymsSet } from "@coral/typings";
import { useReactiveVar } from "@apollo/client";
import { useGetSynonymsLazyQuery } from "../../generated";
import reactiveVariables from "../../common/graphql/global-reactive-variables";

export default function useGetSynonyms() {
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(300);
  const [offset, setOffset] = useState(0);
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const [getSynonyms, { data, loading }] = useGetSynonymsLazyQuery({
    variables: { params: { limit, offset, projectId: selectedEnvironmentProjectId } },
    fetchPolicy: "cache-first",
  });

  const synonyms = (data?.synonymsSets as SynonymsSet[]) ?? null;

  const fetchSynonyms = useCallback(
    debounce((limit: number, offset: number, projectId: string) => {
      getSynonyms({ variables: { params: { offset, limit, projectId } } });
    }, 1),
    [search, limit, offset, selectedEnvironmentProjectId, getSynonyms],
  );

  useEffect(() => {
    fetchSynonyms(limit, offset, selectedEnvironmentProjectId);
  }, [limit, offset, fetchSynonyms, selectedEnvironmentProjectId]);

  return {
    synonyms,
    loading,
    search,
    setSearch,
    limit,
    offset,
    setLimit,
    setOffset,
  };
}
