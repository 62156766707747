import { useReducer } from "react";
import { Outlet } from "react-router-dom";
import { makeStyles } from "@gfg/ui-v2/theming";
import Header from "./header";
import Sidebar from "./sidebar";
import useScreenWidth from "../hooks/use-screen-width";

interface LayoutStyles {
  open: boolean;
}

const useStyles = makeStyles<LayoutStyles>()(({ breakpoints, colors, size, spacing }, { open }) => ({
  wrapper: {
    display: "grid",
    gridTemplateColumns: `
      [sidebar-start] ${size(open ? 312 : 53)}
      [sidebar-end content-start] 1fr [content-end]`,
    gridTemplateRows: `
      [content-start] 1fr
      [footer-start] 0fr [footer-end content-end]`,
    minHeight: "calc(100vh - 56px)",
    backgroundColor: colors.background,
    overflow: "hidden",

    [breakpoints.down("xs")]: {
      gridTemplateColumns: "[sidebar-start content-start] 1fr [sidebar-end content-end]",
    },
  },
  body: {
    gridColumn: "content-start / content-end",
    gridRow: "content-start / content-end",
    minWidth: 0,
    padding: spacing("md"),
    paddingTop: 0,
    background: "#FCFCFD",

    [breakpoints.down("xs")]: {
      gridColumn: "sidebar-start / content-end",
      margin: 0,
      padding: 0,
      position: open ? "fixed" : "static",
    },
  },
}));

export default function Layout() {
  const { isMobile } = useScreenWidth();
  const [open, toggleSidebar] = useReducer((x: boolean, p: boolean | undefined) => p ?? !x, !isMobile);
  const { classes } = useStyles({ open });

  const toggle = () => {
    toggleSidebar(!open);
  };

  return (
    <>
      <Header onToggle={toggle} />
      <div className={classes.wrapper}>
        <Sidebar open={open} onToggle={toggle} />
        <div className={classes.body}>
          <Outlet />
        </div>
      </div>
    </>
  );
}
