/* eslint-disable unicorn/no-thenable */
import * as yup from "yup";

const attributeFilterObjectShape = yup.object().shape({
  type: yup.string().required("Attribute value is required"),
  weight: yup.number().required("Attribute weight is required").min(0.01, "Min weight is 0.01"),
  rule: yup.string().nullable().optional(),
  value: yup.mixed().when("rule", {
    is: (rule: string) => !!rule,
    then: yup
      .mixed()
      .required("Value is required when rule is present")
      .test("value-type-check", "Required", value => {
        if (typeof value === "string" && value !== "") {
          return true;
        }

        if (typeof value === "number" && !Number.isNaN(value)) {
          return true;
        }
        if (Array.isArray(value) && value.length > 0) {
          return value.every(item => typeof item === "string");
        }

        return false;
      }),
    otherwise: yup.mixed().notRequired(),
  }),
});

export default attributeFilterObjectShape;
