import React from "react";
import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ spacing }) => ({
  datajetLogo: {
    width: spacing(95),
    textAlign: "center",
    marginTop: spacing(4),
  },
}));

interface ProductLogoProps {
  className?: string;
}

export default function ProductLogo({ className = "" }: ProductLogoProps) {
  const { classes, cx } = useStyles();

  return <img className={cx(classes.datajetLogo, className)} src="/datajet-logo.svg" alt="Datajet logo" />;
}
