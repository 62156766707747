import { useCallback } from "react";
import { CampaignShort } from "@coral/typings";
import { useReactiveVar } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { useDeleteCampaignMutation } from "~/generated";
import reactiveVariables from "~/common/graphql/global-reactive-variables";

export default function useHandleCampaigns() {
  const { region } = useParams();
  const navigate = useNavigate();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const [deleteCampaign] = useDeleteCampaignMutation({ refetchQueries: ["GetCampaigns"] });

  const handleEdit = useCallback((campaign: CampaignShort) => {
    navigate(`/${region}/merchandising/edit/${campaign.id}`);
  }, []);

  const handleRemove = useCallback(async (campaign: CampaignShort) => {
    await deleteCampaign({
      variables: { input: { campaignId: campaign.id, projectId: selectedEnvironmentProjectId } },
    });
  }, []);

  const handleDuplicate = useCallback((campaign: CampaignShort) => {
    navigate(`/${region}/merchandising/duplicate/${campaign.id}`);
  }, []);

  const handleCreateCampaign = useCallback(() => navigate(`/${region}/merchandising/create`), []);

  return {
    onCreateCampaign: handleCreateCampaign,
    onDuplicateCampaign: handleDuplicate,
    onRemoveCampaign: handleRemove,
    onEditCampaign: handleEdit,
  };
}
