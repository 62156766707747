import { useMemo } from "react";
import { CustomFeed } from "@coral/typings";
import CircularProgress from "@gfg/ui-v2/components/circular-progress";
import { useParams } from "react-router-dom";
import { useReactiveVar } from "@apollo/client";

import convertCustomFeedToFormData from "../newsletter-form/utils/convert-custom-feed-to-form-data";
import Breadcrumbs from "../../../common/components/breadcrumbs";
import { useGetNewsletterQuery } from "../../../generated";
import NewsletterForm from "../newsletter-form";
import useSubmit from "./use-submit";
import reactiveVariables from "../../../common/graphql/global-reactive-variables";
import PageContainer from "../../../common/components/containers/page-container";

export default function DuplicateNewsletterPage() {
  const onSubmit = useSubmit();
  const { newsletterId } = useParams();
  const selectedEnvironmentProjectId = useReactiveVar(reactiveVariables.selectedEnvironmentIdVar);

  const { loading, data } = useGetNewsletterQuery({
    fetchPolicy: "network-only",
    variables: {
      params: {
        id: newsletterId as string,
        projectId: selectedEnvironmentProjectId,
      },
    },
  });

  const defaultValues = useMemo(() => {
    if (data?.customFeed !== undefined) {
      return convertCustomFeedToFormData(data?.customFeed as CustomFeed);
    }
  }, [data]);

  return (
    <PageContainer>
      <Breadcrumbs />
      {loading && <CircularProgress />}
      {!loading && (
        <NewsletterForm
          newsletterId={newsletterId}
          allowImmediateSubmit
          defaultValues={defaultValues}
          formTitle="Duplicate Newsletter"
          onSubmit={onSubmit}
        />
      )}
    </PageContainer>
  );
}
