import { makeStyles } from "@gfg/ui-v2/theming";
import ProductSectionTitle from "./product-section-title";

const useStyles = makeStyles()(({ spacing, palette }) => ({
  sectionWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: spacing(20),
  },
  container: {
    width: "100%",
    border: `1px solid ${palette.tertiary[200]}`,
    marginTop: spacing(32),
    paddingBottom: spacing(32),
  },
  contentContainer: {
    "display": "flex",
    "justifyContent": "space-between",
    "padding": spacing(24),
    "& > div": {
      marginLeft: 0,
    },
  },
}));

export interface ProductCatalogDescriptionProps {
  description: string;
}

export default function ProductCatalogDescription({ description }: ProductCatalogDescriptionProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.sectionWrapper}>
      <div className={classes.container}>
        <ProductSectionTitle title="Product Description" />
        <div className={classes.contentContainer}>{description}</div>
      </div>
    </div>
  );
}
