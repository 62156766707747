import { CustomFeed } from "@coral/typings";
import deserializeFeedFilters from "../../../../common/utils/feed-filters/deserialize-feed-fiters";
import { FormData } from "..";

function ConvertCustomFeedToFormData(customFeed: CustomFeed): FormData {
  return {
    feedFilters: customFeed.recipe?.filter ? deserializeFeedFilters(customFeed.recipe.filter) : [],
    feedStrategy: customFeed.recipe?.feed?.map(feed => feed?.name!) ?? [],
    htmlTileTemplate: customFeed.product_tile_html_template || "",
    landingPageUrl: customFeed.landing_page || "",
    newsletterName: customFeed.name,
    orderStrategy: customFeed.recipe?.strategy || "",
    feedStatus: customFeed.state,
  };
}

export default ConvertCustomFeedToFormData;
