import { useCallback } from "react";
import { useLocation } from "react-router-dom";

interface UseEventTrackingProps {
  eventName: string;

  description?: string;
  category?: string;
  label?: string;
}

function useEventTracking({ eventName, category, description, label }: UseEventTrackingProps) {
  const location = useLocation();

  return useCallback(() => {
    if (window.gtag) {
      window.gtag("event", eventName, {
        event_category: category,
        description,
        event_label: label,
        page_path: location.pathname + location.search,
      });
    }
  }, [window.gtag, eventName, category, description, label]);
}

export default useEventTracking;
