import React from "react";
import { DefaultCloseButton, DialogHeader, DialogHeaderProps } from "@gfg/ui-v2/components/dialog";
import withTooltip from "~/common/components/with-tooltip";

const CloseButton = withTooltip("Close", DefaultCloseButton);

export const DialogHeaderWithCloseTooltip = React.forwardRef<HTMLDivElement, DialogHeaderProps>((props, ref) => {
  return <DialogHeader ref={ref} closeButton={CloseButton} {...props} />;
});

export default DialogHeaderWithCloseTooltip;
