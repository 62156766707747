import Dialog, { DialogBody, DialogHeader } from "@gfg/ui-v2/components/dialog";
import { makeStyles } from "@gfg/ui-v2/theming";
import DeleteModalActions from "./delete-modal-actions";

const useStyles = makeStyles()(({ spacing, typography, colors }) => ({
  dialogHeader: {
    "fontWeight": typography.fontWeights.medium,
    "& button": {
      color: colors.text,
    },
  },
  title: {
    margin: 0,
  },
  dialogBody: {
    textAlign: "center",
  },
  dialogButtons: {
    marginTop: spacing(20),
  },
  closeButton: {
    color: colors.text,
  },
}));

interface DeleteModalProps {
  title: string;
  text: string;
  open: boolean;
  onToggle: (value: boolean) => void;
  onDelete: () => void;
}

export default function DeleteModal({ title, text, open, onToggle, onDelete: deleteAction }: DeleteModalProps) {
  const { classes } = useStyles();

  return (
    <Dialog open={open} size="small" onClose={() => onToggle(false)}>
      <DialogHeader className={classes.dialogHeader}>
        <h4 className={classes.title}>{title}</h4>
      </DialogHeader>
      <DialogBody className={classes.dialogBody}>
        <small>{text}</small>
        <div className={classes.dialogButtons}>
          <DeleteModalActions
            submitBtnName="Delete"
            color="error"
            deleteAction={deleteAction}
            closeModal={() => {
              onToggle(false);
            }}
          />
        </div>
      </DialogBody>
    </Dialog>
  );
}
