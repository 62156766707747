import React from "react";
import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles()(({ spacing, colors, palette }) => ({
  container: {
    margin: spacing(30, 0),
    paddingBottom: spacing(10),
    borderBottom: `1px solid ${palette.tertiary[200]}`,
  },
  title: {
    margin: 0,
  },
  description: {
    color: colors.darkgray,
  },
}));

interface SectionTitleProps {
  title: string;
  description: string | React.ReactNode;
}

export default function SectionTitle({ title, description }: SectionTitleProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <h5 className={classes.title}>{title}</h5>
      <p className={classes.description}>{description}</p>
    </div>
  );
}
