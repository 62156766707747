import { makeStyles } from "@gfg/ui-v2/theming";

const useStyles = makeStyles<StylingProps>()(({ spacing }, { gap }) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: gap ? spacing(gap) : spacing(16),
  },
}));

interface FlexWrapProps extends StylingProps {
  children: React.ReactNode;
  className?: string;
}

interface StylingProps {
  gap?: number;
}

export default function FlexWrapContainer({ children, className, ...stylingProps }: FlexWrapProps) {
  const { classes, cx } = useStyles(stylingProps);

  return <div className={cx(classes.container, className)}>{children}</div>;
}
